import { Box, Container, Grid, Button, Typography, useMediaQuery, useTheme, TextField } from '@mui/material';
import React from 'react';
import Navbar from '../Sections/Navbar';
import Footer from '../Sections/Footer';
import CourseVideo from "../Vault/DigMar_video.mp4";
import EventNoteIcon from '@mui/icons-material/EventNote';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DownloadIcon from '@mui/icons-material/Download';
import DialogTitle from '@mui/material/DialogTitle';
import DoneIcon from '@mui/icons-material/Done';
import { postData } from '../../ApiServices/ServerServices';
import Swal from 'sweetalert2';
//socket
import { io } from 'socket.io-client'
export default function Courses() {

    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));
    const ipad = useMediaQuery(theme.breakpoints.down(1200));
    const [name, setName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [number, setNumber] = React.useState("");
    const [socket,setSocket]=React.useState()

        React.useEffect(() => {
                const socket = io("https://api4.buzz2web.in/");
                setSocket(socket);
              
                socket.on("connect", () => {
                  console.log(socket.id);
                  socket.emit("backend",'admin');
                });
              
        
              }, []);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClick=async()=>{
        var formData=new FormData()
        formData.append('name',name)
        formData.append('email',email)
        formData.append('number',number)
        
      
        
        setOpen(false);
          var result=await postData('registration/addregistration',formData,true)
          console.log(result)
          if(result.status)
          {  socket.emit("newEntry",{Enquiry:'New Enquiry',room:'admin'})
                       
              Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Registration Has Been Send.We will contact You Soon',
              showConfirmButton: false,
              timer: 1500
            })}
          else
          {
              Swal.fire({
                  position: 'center',
                  icon: 'error',
                  title: 'Fail to Register',
                  showConfirmButton: false,
                  timer: 1500
                })
      
          }
      
    }

    const registerForm = () => {
        return (
            <Box sx={{ background: "#ffffff" }}>
                <Dialog
                    open={open}
                    keepMounted
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle sx={{fontFamily:"Montserrat",fontWeight:700}}>{"Registeration Form"}</DialogTitle>
                    <DialogContent>
                        <Grid sx={{padding:"10px 0px"}} container spacing={2}>
                            <Grid item xs={12}>
                                <TextField value={name} onChange={(e)=>setName(e.target.value)} fullWidth label="Full Name" />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField value={number} onChange={(e)=>setNumber(e.target.value)} fullWidth label="Phone Number" />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField value={email} onChange={(e)=>setEmail(e.target.value)} fullWidth label="Email" />
                            </Grid>
                            <Grid item xs={12}>
                                <Button fullWidth sx={{
                                    backgroundColor: "#FFDE00",fontFamily:"Montserrat",fontWeight:600, boxShadow: "0px 9px 14px 3px lightgrey", padding: "8px 95px", fontSize: 18, mr: 2, color: "#ffffff", fontWeight: "bold", "&:hover": {
                                        background: "#053F76",
                                        color: "#FFDE00"
                                    }
                                }} onClick={()=>handleClick()}>Register</Button>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button sx={{fontFamily:"Montserrat",fontWeight:700}} onClick={handleClose}>Close</Button>
                    </DialogActions>
                </Dialog>

            </Box>
        )
    }

    return (
        <Box sx={{overflowX:"hidden"}}>
            <Navbar />
            <br />
            <br />
            <br />
            {ipad ? <></> : <>
                <br />
                <br />
                <br />
                <br />
            </>}
            <Container maxWidth={"xl"}>
                <Box sx={{ padding:mobile?"20px 0px":"10px 50px" }}>
                    <br />
                    <Grid container spacing={2}>
                        <Grid sx={{ml:mobile?-2:0}} item xs={12} md={6} >
                        <Typography sx={{fontFamily:"Montserrat", display:mobile?"block":"none",fontSize: 26, fontWeight: 900, color: "#053F76", textAlign: "left",ml:2.5 }}>
                                Master Digital Marketing in 3 Months
                            </Typography>
                            {mobile?<><br /></>:<></>}
                            <video
                                src={CourseVideo}
                                controls
                                autoPlay
                                loop
                                muted
                                width={mobile?"400":"100%"}
                                height={mobile?"250":"400"}
                                style={{ borderRadius: 10 }}
                            />
                        </Grid>
                        <Grid sx={{ display: "flex", justifyContent: "flex-start", alignItems: "start", textAlign: "left", flexDirection: "column" }} item xs={12} md={6}>
                            <Typography sx={{fontFamily:"Montserrat",display:mobile?"none":"block", fontSize: 36, fontWeight: 900, color: "#053F76", textAlign: "left", ml: 5 }}>
                                Master Digital Marketing in 3 Months
                            </Typography>
                            {mobile?<></>:<><br /><br /></>}
                            <Box sx={{ ml:mobile?2.5: 5, display: "flex", justifyContent: "flex-start", alignItems: "center", textAlign: "left", flexDirection: "row" }}>
                                <EventNoteIcon sx={{ fontSize: mobile?22:28, color: "#053F76", mr: 2 }} />
                                <Typography sx={{ fontFamily:"Montserrat",fontSize: mobile?16:20, fontWeight: 500, color: "#9cbccb" }}>Duration : 3 months</Typography>
                            </Box>
                            <br />
                            <Box sx={{ ml:mobile?2.5: 5, display: "flex", justifyContent: "flex-start", alignItems: "center", textAlign: "left", flexDirection: "row" }}>
                                <WorkspacePremiumIcon sx={{ fontSize: mobile?22:24, color: "#053F76", mr: 2 }} />
                                <Typography sx={{fontFamily:"Montserrat", fontSize: mobile?16:20, fontWeight: 500, color: "#9cbccb" }}>Certificate of Course Completion</Typography>
                            </Box>
                            <br />
                            <Box sx={{ ml:mobile?2.5: 5, display: "flex", justifyContent: "flex-start", alignItems: "center", textAlign: "left", flexDirection: "row" }}>
                                <AssignmentTurnedInIcon sx={{ fontSize: mobile?22:28, color: "#053F76", mr: 2 }} />
                                <Typography sx={{ fontFamily:"Montserrat",fontSize: mobile?16:20, fontWeight: 500, color: "#9cbccb" }}>Projects</Typography>
                            </Box>
                            {mobile?<><br /><br /></>:<><br /><br /><br /></>}
                            <Box sx={{ ml: mobile?2.5:5, display: "flex", justifyContent: "flex-start", alignItems: "center", textAlign: "left", flexDirection: "row" }}>
                                <Button sx={{
                                    backgroundColor: "#FFDE00", fontFamily:"Montserrat", boxShadow: "0px 9px 14px 3px lightgrey", padding: mobile?"8px 45px":"8px 85px", fontSize:mobile?14:18, mr: mobile?1:2, color: "#000000", fontWeight: "bold", "&:hover": {
                                        background: "#053F76",
                                        color: "#FFDE00"
                                    }
                                }} ><DownloadIcon/>&nbsp;PDF</Button>
                                <Button onClick={handleClickOpen} sx={{
                                    backgroundColor: "#9cbccb", fontFamily:"Montserrat", boxShadow: "0px 9px 14px 3px lightgrey", padding: mobile?"8px 40px":"8px 65px", fontSize: mobile?14:18, marginRight:10, color: "#053F76", fontWeight: "bold", "&:hover": {
                                        background: "#053F76",
                                        color: "#9cbccb"
                                    }
                                }} >Register</Button>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <br /><br />
                            <Typography sx={{ fontFamily:"Montserrat", ml:mobile?2.5:0,fontSize: mobile?19:24, fontWeight: 700, color: "#053F76" }}>Description : </Typography>
                            <br />
                            <Typography sx={{  fontFamily:"Montserrat",ml:mobile?2.5:0,mr:mobile?2.5:0,fontSize: mobile?16:18, fontWeight: 400, color: "grey" ,textAlign:mobile?"justify":"left" }}>
                            India Buzz Digital Marketing Course is designed to provide comprehensive knowledge and hands-on experience in all aspects of digital marketing. The course is conducted over a period of 3 months and includes more than 10 projects to help students gain practical experience and build a portfolio of work. The curriculum covers topics such as SEO, SEM, social media marketing, email marketing, and content marketing.
                                Our expert instructors guide students through the course, providing personalized attention and support throughout the learning process. At the end of the course, students will receive a course completion certificate, recognizing their achievement and expertise in digital marketing. This certificate can be added to their resume and will demonstrate their skills to potential employers. Our goal is to empower students with the skills and knowledge they need to succeed in the rapidly evolving digital marketing industry.
                            </Typography>
                        </Grid>
                        <Grid id="Syllabus" item xs={12}>
                            <br /><br />
                            <Typography sx={{ fontFamily:"Montserrat", ml:mobile?2.5:0,mr:mobile?2.5:0, fontSize: 34, fontWeight: 700, color: "#053F76" }}>What you will learn ?  </Typography>
                            <br />
                            <br />
                            <Typography sx={{ fontFamily:"Montserrat", ml:mobile?2.5:0,mr:mobile?2.5:0,display:"flex",flexDirection:"row", fontSize: mobile?18:20, fontWeight: 400, color: "grey" }}>
                                <DoneIcon sx={{ fontSize:mobile?24:30, color: "#33cc33",mr:2,mb:1 }} />
                                Search Engine Optimization
                            </Typography>
                            <Typography sx={{ fontFamily:"Montserrat", ml:mobile?2.5:0,mr:mobile?2.5:0,display:"flex",flexDirection:"row", fontSize: mobile?18:20, fontWeight: 400, color: "grey" }}>
                                <DoneIcon sx={{ fontSize:mobile?24:30, color: "#33cc33",mr:2,mb:1 }} />
                                Social Media Optimization
                            </Typography>
                            <Typography sx={{ fontFamily:"Montserrat", ml:mobile?2.5:0,mr:mobile?2.5:0,display:"flex",flexDirection:"row", fontSize: mobile?18:20, fontWeight: 400, color: "grey" }}>
                                <DoneIcon sx={{ fontSize:mobile?24:30, color: "#33cc33",mr:2,mb:1 }} />
                                Email Marketing
                            </Typography>
                            <Typography sx={{ fontFamily:"Montserrat", ml:mobile?2.5:0,mr:mobile?2.5:0,display:"flex",flexDirection:"row", fontSize: mobile?18:20, fontWeight: 400, color: "grey" }}>
                                <DoneIcon sx={{ fontSize:mobile?24:30, color: "#33cc33",mr:2,mb:1 }} />
                                Content Marketing
                            </Typography>
                            <Typography sx={{ fontFamily:"Montserrat", ml:mobile?2.5:0,mr:mobile?2.5:0,display:"flex",flexDirection:"row", fontSize: mobile?18:20, fontWeight: 400, color: "grey" }}>
                                <DoneIcon sx={{ fontSize:mobile?24:30, color: "#33cc33",mr:2,mb:1 }} />
                                Social Media Marketing
                            </Typography>
                            <Typography sx={{ fontFamily:"Montserrat", ml:mobile?2.5:0,mr:mobile?2.5:0,display:"flex",flexDirection:"row", fontSize: mobile?18:20, fontWeight: 400, color: "grey" }}>
                                <DoneIcon sx={{ fontSize:mobile?24:30, color: "#33cc33",mr:2,mb:1 }} />
                                Google Ads
                            </Typography>
                            <Typography sx={{  fontFamily:"Montserrat",ml:mobile?2.5:0,mr:mobile?2.5:0,display:"flex",flexDirection:"row", fontSize: mobile?18:20, fontWeight: 400, color: "grey" }}>
                                <DoneIcon sx={{ fontSize:mobile?24:30, color: "#33cc33",mr:2,mb:1 }} />
                                Branding 
                            </Typography>
                            <Typography sx={{ fontFamily:"Montserrat", ml:mobile?2.5:0,mr:mobile?2.5:0,display:"flex",flexDirection:"row", fontSize: mobile?18:20, fontWeight: 400, color: "grey" }}>
                                <DoneIcon sx={{ fontSize:mobile?24:30, color: "#33cc33",mr:2,mb:1 }} />
                                Moz Tools
                            </Typography>
                            <Typography sx={{ fontFamily:"Montserrat", ml:mobile?2.5:0,mr:mobile?2.5:0,display:"flex",flexDirection:"row", fontSize: mobile?18:20, fontWeight: 400, color: "grey" }}>
                                <DoneIcon sx={{ fontSize:mobile?24:30, color: "#33cc33",mr:2,mb:1 }} />
                                Introduction and uses of AI tools
                            </Typography>
                            <Typography sx={{ fontFamily:"Montserrat",ml:mobile?2.5:0,mr:mobile?2.5:0,display:"flex",flexDirection:"row", fontSize: mobile?18:20, fontWeight: 400, color: "grey" }}>
                                <DoneIcon sx={{ fontSize:mobile?24:30, color: "#33cc33",mr:2,mb:1 }} />
                                Case Study on Different things and trends
                            </Typography>
                            <Typography sx={{ fontFamily:"Montserrat",ml:mobile?2.5:0,mr:mobile?2.5:0,display:"flex",flexDirection:"row", fontSize: mobile?18:20, fontWeight: 400, color: "grey" }}>
                                <DoneIcon sx={{ fontSize:mobile?24:30, color: "#33cc33",mr:2,mb:1 }} />
                                And Many More...
                            </Typography>
                            <br /><br />
                        </Grid>
                    </Grid>
                </Box>
            </Container>
            <Footer />
            {registerForm()}
        </Box>
    )
}
