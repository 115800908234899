import React from "react";
import { Box, Grid, Typography, Container, useTheme, useMediaQuery } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { useNavigate } from "react-router-dom";

export default function Footer() {

        const navigate = useNavigate();
        const theme = useTheme();
        const mobile = useMediaQuery(theme.breakpoints.down("sm"));
        return (
                <Box sx={{ background: "#053F76", color: "#ffffff" }}>
                        <Container sx={{ pt: 8 }} maxWidth={"xl"}>
                                <Grid container spacing={2} sx={{ ml: mobile ? 1 : 5, mb: 5 }}>
                                        <Grid sx={{ display: "flex", flexDirection: "column" }} item xs={12} md={6} lg={4}>
                                                <Typography sx={{ fontSize: 30, fontWeight: "bold", fontFamily: "Montserrat", mb: 2 }}>Contact Us</Typography>
                                                <Box sx={{ display: "flex", justifyContent: "start", alignItems: "center", flexDirection: "row", mt: 2 }}>
                                                        <Typography sx={{ fontSize: 16, fontFamily: "Montserrat", fontWeight: 500, mr: 2 }}>Phone Number : </Typography>
                                                        <Typography sx={{ fontFamily: "Montserrat" }}>
                                                                +91-8889430333
                                                        </Typography>
                                                </Box>
                                                <Box sx={{ display: "flex", justifyContent: "start", alignItems: "center", flexDirection: "row", mt: 2 }}>
                                                        <Typography sx={{ fontSize: 16, fontWeight: 500, mr: 2, fontFamily: "Montserrat" }}>Email : </Typography>
                                                        <Typography sx={{ fontFamily: "Montserrat" }}>
                                                                <a href="mailto:ibuzz@indiabuzz.co.in?subject=Me&body=Hello! I Want To Take Knowledge About Your Services" style={{ color: '#ffffff' }}>ibuzz@indiabuzz.co.in</a>
                                                        </Typography>
                                                </Box>
                                                <Box sx={{ fontFamily: "Montserrat", display: "flex", justifyContent: "start", alignItems: "center", flexDirection: "row", mt: mobile ? -3 : 0 }}>

                                                        <Typography sx={{ fontFamily: "Montserrat", fontSize: 16, fontWeight: 500, mr: 2 }}>Address : </Typography>
                                                        {mobile ? <Box sx={{ fontFamily: "Montserrat", display: "flex", justifyContent: "start", alignItems: "start", textAlign: "left", flexDirection: "column", mt: 6 }}>
                                                                <Typography sx={{ fontFamily: "Montserrat", textAlign: "left" }}> Jinsi Rd Number 2,</Typography>
                                                                <Typography sx={{ fontFamily: "Montserrat", textAlign: "left" }}> Lohiya Bazaar Gwalior, </Typography>
                                                                <Typography sx={{ fontFamily: "Montserrat", textAlign: "left" }}>Madhya Pradesh 474009</Typography>
                                                        </Box> : <Box sx={{ display: "flex", justifyContent: "start", alignItems: "center", flexDirection: "column", mt: 3 }}>
                                                                <Typography sx={{ fontFamily: "Montserrat" }}> Jinsi Rd Number 2, Lohiya Bazaar</Typography>
                                                                <Typography sx={{ fontFamily: "Montserrat" }}>Gwalior, Madhya Pradesh 474009</Typography>
                                                        </Box>}
                                                </Box>
                                                <br />
                                                <br />
                                                <br />
                                                <br />
                                                <Box>
                                                        <a href="https://www.facebook.com/Ibuzz247?mibextid=ZbWKwL" target="_blank">
                                                                <FacebookIcon sx={{ color: "#ffffff", fontSize: 40, mr: 2 }} />
                                                        </a>
                                                        <a href="https://instagram.com/ibuzztech?igshid=YmMyMTA2M2Y=" target="_blank">
                                                                <InstagramIcon sx={{ color: "#ffffff", fontSize: 40, mr: 2 }} />
                                                        </a>
                                                        <a href="https://twitter.com/indiabuzz247" target="_blank">
                                                                <TwitterIcon sx={{ color: "#ffffff", fontSize: 40, mr: 2 }} />
                                                        </a>
                                                        <a href="https://goo.gl/maps/VurxKFRojURe5FrG6" target="_blank">
                                                                <LocationOnIcon sx={{ color: "#ffffff", fontSize: 40, mr: 2 }} />
                                                        </a>
                                                </Box>
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={4}>
                                                <Typography sx={{ fontFamily: "Montserrat", fontSize: 30, fontWeight: "bold", mb: 3 }}>About Us</Typography>
                                                <Typography sx={{ fontFamily: "Montserrat", width: "70%", textAlign: "justify", fontSize: mobile ? 10 : 14,wordSpacing:'0.5px' }}>India Buzz offers services and products
                                                        for business growth, including digital
                                                        marketing, website development, branding
                                                        and strategic planning. Their expert
                                                        team provides support and tools for
                                                        companies of all sizes to thrive in
                                                        today's competitive market and
                                                        achieve their goals.</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={4}>
                                                <Typography sx={{ fontFamily: "Montserrat", fontSize: 30, fontWeight: "bold", mb: 3 }}>Services We Offer</Typography>
                                                <Box onClick={() => { navigate("/seoservice") }}  sx={{ cursor: "pointer", display: "flex", justifyContent: "start", alignItems: "center", flexDirection: "row", mt: 1 }}>
                                                        <RadioButtonCheckedIcon sx={{ color: "#ffffff", fontSize: 18, mr: 1 }} />
                                                        <Typography sx={{ fontFamily: "Montserrat", fontSize: 16 }}>SEO</Typography>
                                                </Box>
                                                <Box onClick={() => { navigate("/smoservice") }} sx={{ cursor: "pointer", display: "flex", justifyContent: "start", alignItems: "center", flexDirection: "row", mt: 1 }}>
                                                        <RadioButtonCheckedIcon sx={{ color: "#ffffff", fontSize: 18, mr: 1 }} />
                                                        <Typography sx={{ fontFamily: "Montserrat", fontSize: 16 }}>SMO</Typography>
                                                </Box>
                                                <Box onClick={() => { navigate("/googleads") }} sx={{ cursor: "pointer", display: "flex", justifyContent: "start", alignItems: "center", flexDirection: "row", mt: 1 }}>
                                                        <RadioButtonCheckedIcon sx={{ color: "#ffffff", fontSize: 18, mr: 1 }} />
                                                        <Typography sx={{ fontFamily: "Montserrat", fontSize: 16 }}>Google Ads</Typography>
                                                </Box>
                                                <Box onClick={() => { navigate("/branding") }} sx={{ cursor: "pointer", display: "flex", justifyContent: "start", alignItems: "center", flexDirection: "row", mt: 1 }}>
                                                        <RadioButtonCheckedIcon sx={{ color: "#ffffff", fontSize: 18, mr: 1 }} />
                                                        <Typography sx={{ fontFamily: "Montserrat", fontSize: 16 }}>Branding</Typography>
                                                </Box>
                                                <Box onClick={() => { navigate("/contentmarketing") }} sx={{ cursor: "pointer", display: "flex", justifyContent: "start", alignItems: "center", flexDirection: "row", mt: 1 }}>
                                                        <RadioButtonCheckedIcon sx={{ color: "#ffffff", fontSize: 18, mr: 1 }} />
                                                        <Typography sx={{ fontFamily: "Montserrat", fontSize: 16 }}>Content Marketing</Typography>
                                                </Box>
                                                <Box onClick={() => { navigate("/webdevelopmentservices") }} sx={{ cursor: "pointer", display: "flex", justifyContent: "start", alignItems: "center", flexDirection: "row", mt: 1 }}>
                                                        <RadioButtonCheckedIcon sx={{ color: "#ffffff", fontSize: 18, mr: 1 }} />
                                                        <Typography sx={{ fontFamily: "Montserrat", fontSize: 16 }}>Website Development</Typography>
                                                </Box>
                                                <Box onClick={() => { navigate("/appdevelopment") }} sx={{ cursor: "pointer", display: "flex", justifyContent: "start", alignItems: "center", flexDirection: "row", mt: 1 }}>
                                                        <RadioButtonCheckedIcon sx={{ color: "#ffffff", fontSize: 18, mr: 1 }} />
                                                        <Typography sx={{ fontFamily: "Montserrat", fontSize: 16 }}>Application Development</Typography>
                                                </Box>
                                                <Box onClick={() => { navigate("/customsoftwaredevelopment") }} sx={{ cursor: "pointer", display: "flex", justifyContent: "start", alignItems: "center", flexDirection: "row", mt: 1 }}>
                                                        <RadioButtonCheckedIcon sx={{ color: "#ffffff", fontSize: 18, mr: 1 }} />
                                                        <Typography sx={{ fontFamily: "Montserrat", fontSize: 16 }}>Custom Software Development</Typography>
                                                </Box>
                                        </Grid>
                                </Grid>
                                <hr style={{ marginLeft: mobile ? 12 : 40, width: "94%" }} />
                                <Box sx={{ padding: "20px 0px", ml: mobile ? 0 : 5 }}>
                                        <Typography
                                                sx={{ fontFamily: "Montserrat", fontSize: mobile ? 15 : 18, fontWeight: 400, textAlign: "center", color: "#ffffff" }}
                                        >
                                                Copyrights © 2022 &nbsp;
                                                <a href="/home1" style={{ color: "#ffffff" }}>IBuzz Tech Solution</a>. All rights reserved
                                        </Typography>
                                </Box>
                        </Container>
                </Box>
        );
}









































































































































































































































































































































































































































































































































































