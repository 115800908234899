import React, { useEffect } from "react";
import { useLocation } from "react-router";
import { css } from "@emotion/react";
import Swal from "sweetalert2";
import IBUZZ from "../../AComponents/Vault/ibuzz.png";
import { postData } from "../../ApiServices/ServerServices";
import { useNavigate } from "react-router";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const PaymentGateway = () => {
  const location = useLocation();
  const navigate = useNavigate()
  const { fullname,mobilenumber,emailid } = location.state;
  const total = 116.82;

  // const handleOrderDetails =async(response)=>{
 
  //   var body={fullname:fullname,mobilenumber:mobilenumber,emailid:emailid,paymentId:response.razorpay_payment_id}
  //   var result = await postData('order/bookedOrders',body)
  //   if (result.status==true) {
  //       Swal.fire({
  //           icon: "success",
  //           title: "Done",
  //           title: "Payment Succesfull",
  //       });
  //       var body1={mobile:mobileNumber}
  //       var result = await postData(`cart/cartsdelete`,body1)
  //       navigate('/')
        
  //   } else {
  //       Swal.fire({
  //           icon: "error",
  //           title: "Oops...",
  //           text: "Something went wrong!",
  //       });
  //       navigate('/')
  //   }
  // }

  const options = {
    key: "rzp_test_GQ6XaPC6gMPNwH",
    amount: total * 100, //  = INR 1
    name: "IBUZZ Tech Solutions",
    image: IBUZZ,
    // handler: async function (response) {
      
    //   handleBooking(response)
    
    // },
    prefill: {
      fullname,
      contact: mobilenumber,
    },
    notes: {
      address: "some address",
    },
    theme: {
      color: "#FFde00",
      hide_topbar: false,
    },
  };

  useEffect(() => {
    const rzp = new window.Razorpay(options);

    rzp.on("payment.failed", function (response) {
      console.log(response.error);
    });

    rzp.open();

    return () => {
      rzp.close();
    };
  }, []);

  return null;
};

export default PaymentGateway