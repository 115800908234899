import React from "react";
import {
    Box,
    Typography,
    Grid,
    Container,
    useTheme,
    useMediaQuery,
} from "@mui/material";
import bg from "../Vault/bg2.jpg";
import smBG from "../Vault/mobile_bg.jpg";
import Devices from "../Vault/devices_logo.png";
import Commitment from "../Vault/commitment_logo.png";
import Awsome from "../Vault/Awsome_logo.png";
import Customer from "../Vault/customer_logo.png";

export default function WhyChooseIBUZZ() {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));
    const ipad = useMediaQuery(theme.breakpoints.down(1200));

    return (
        <Box
            sx={{
                overflowX: "hidden",
                background:"#9cbccb",
                display: "flex",
                flexDirection: "column",
            }}
        >
            <Container maxWidth={"xl"}>
                <Typography
                    sx={{
                        color: "#053F76",
                        fontWeight: 900,
                        fontSize: mobile?26:ipad ? 40 : 52,
                        textAlign: "center",
                        mt: mobile?-5:ipad ? 4 : 10,
                        mb: mobile?1:ipad ? 4 : 12,
                        padding: mobile?"70px 10px":10,
                        fontFamily:"Montserrat"
                    }}
                >
                    Why Choose India Buzz ?
                </Typography>
                {mobile ?
                    <Grid
                        sx={{
                            mt:-8,
                            mb: 12,
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                            paddingLeft:2
                        }}
                        container
                        spacing={2}
                    >
                        <Grid
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                            items
                            xs={12}
                        >
                            <Box
                                sx={{
                                    borderRadius: 2,
                                    height: 350,
                                    width: 300,
                                    m: 2,
                                    background: "#ffffff",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <img
                                    src={Devices}
                                    style={{ width: "60%", marginBottom: "12px" }}
                                />
                                <Typography
                                    sx={{ fontSize: 16, fontWeight: 800, color: "#053F76" }}
                                >
                                    Unparalleled Ethics
                                </Typography>
                                <Typography sx={{ textAlign:"center",fontSize: 12, color: "grey",margin:"10px 20px 20px" }}>
                                    Our designs are visually stunning, expertly
                                    executed and tailored to your business and
                                    personal style. We bring out the best of you
                                    and showcase your unique identity.
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                            items
                            xs={12}
                        >
                            <Box
                                sx={{
                                    borderRadius: 2,
                                    height: 320,
                                    width: 300,
                                    m: 2,
                                    background: "#ffffff",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <img
                                    src={Commitment}
                                    style={{ width: "60%", marginBottom: "12px" }}
                                />
                                <Typography
                                    sx={{ fontSize: 16, textAlign:"center", fontWeight: 800, color: "#053F76" }}
                                >
                                    We value our commitments
                                </Typography>
                                <Typography sx={{textAlign:"center", fontSize: 12, color: "grey",margin:"10px 20px 20px" }}>
                                    At India Buzz, we value your time and take our{" "}
                                    commitments very seriously. We uphold our{" "}
                                    promises and deliver on time, without{" "}
                                    compromising on the quality of our work.
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                            items
                            xs={12}
                        >
                            <Box
                                sx={{
                                    borderRadius: 2,
                                    height: 320,
                                    width: 300,
                                    m: 2,
                                    background: "#ffffff",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <img
                                    src={Awsome}
                                    style={{ width: "60%", marginBottom: "12px" }}
                                />
                                <Typography
                                    sx={{ fontSize: 16,textAlign:"center", fontWeight: 800, color: "#053F76" }}
                                >
                                    Incredible at every dimension
                                </Typography>
                                <Typography sx={{ textAlign:"center",fontSize: 12, color: "grey",margin:"10px 20px 20px"}}>
                                India Buzz provides responsive websites and
                                    applications that seamlessly adapt to{" "}
                                    different screen sizes and aspect ratios,{" "}
                                    ensuring that your business looks great on{" "}
                                    all devices.
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                            items
                            xs={12}
                        >
                            <Box
                                sx={{
                                    borderRadius: 2,
                                    height: 320,
                                    width: 300,
                                    m: 2,
                                    background: "#ffffff",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <img
                                    src={Customer}
                                    style={{ width: "60%", marginBottom: "12px" }}
                                />
                                <Typography
                                    sx={{ fontSize: 16, fontWeight: 800, color: "#053F76" }}
                                >
                                    Our priority is you
                                </Typography>
                                <Typography sx={{textAlign:"center", fontSize: 12, color: "grey",margin:"10px 20px 20px" }}>
                                    We are always available to answer any{" "}
                                    questions you may have, anytime. We{" "}
                                    provide comprehensive tech and after-sales
                                    support to ensure that you receive the{" "}
                                    assistance you need, whenever you need it.
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    :
                    ipad ?
                        <Grid
                            sx={{
                                mb: 12,
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-evenly",
                                alignItems: "center",
                            }}
                            container
                            spacing={2}
                        >
                            <Grid
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                                items
                                xs={6}
                            >
                                <Box
                                    sx={{
                                        borderRadius: 2,
                                        height: 350,
                                        width: 270,
                                        m: 2,
                                        background: "#ffffff",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <img
                                        src={Devices}
                                        style={{ width: "60%", marginBottom: "12px" }}
                                    />
                                    <Typography
                                        sx={{ fontSize: 18, fontWeight: 800, color: "#053F76" }}
                                    >
                                        Unparalleled Ethics
                                    </Typography>
                                    <Typography sx={{ fontSize: 12, color: "grey" }}>
                                        Our designs are visually stunning, expertly
                                    </Typography>
                                    <Typography sx={{ fontSize: 12, color: "grey" }}>
                                        executed and tailored to your business and{" "}
                                    </Typography>
                                    <Typography sx={{ fontSize: 12, color: "grey" }}>
                                        personal style. We bring out the best of you
                                    </Typography>
                                    <Typography sx={{ fontSize: 12, color: "grey" }}>
                                        and showcase your unique identity.
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                                items
                                xs={6}
                            >
                                <Box
                                    sx={{
                                        borderRadius: 2,
                                        height: 350,
                                        width: 270,
                                        m: 2,
                                        background: "#ffffff",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <img
                                        src={Commitment}
                                        style={{ width: "60%", marginBottom: "12px" }}
                                    />
                                    <Typography
                                        sx={{ fontSize: 18, fontWeight: 800, color: "#053F76" }}
                                    >
                                        We value our commitments
                                    </Typography>
                                    <Typography sx={{ fontSize: 12, color: "grey" }}>
                                        At India Buzz, we value your time and take our{" "}
                                    </Typography>
                                    <Typography sx={{ fontSize: 12, color: "grey" }}>
                                        commitments very seriously. We uphold our
                                    </Typography>
                                    <Typography sx={{ fontSize: 12, color: "grey" }}>
                                        promises and deliver on time, without{" "}
                                    </Typography>
                                    <Typography sx={{ fontSize: 12, color: "grey" }}>
                                        compromising on the quality of our work.
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                                items
                                xs={6}
                            >
                                <Box
                                    sx={{
                                        borderRadius: 2,
                                        height: 350,
                                        width: 270,
                                        m: 2,
                                        background: "#ffffff",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <img
                                        src={Awsome}
                                        style={{ width: "60%", marginBottom: "12px" }}
                                    />
                                    <Typography
                                        sx={{ fontSize: 18, fontWeight: 800, color: "#053F76" }}
                                    >
                                        Incredible at every dimension
                                    </Typography>
                                    <Typography sx={{ fontSize: 12, color: "grey" }}>
                                    India Buzz provides responsive websites and
                                    </Typography>
                                    <Typography sx={{ fontSize: 12, color: "grey" }}>
                                        applications that seamlessly adapt to{" "}
                                    </Typography>
                                    <Typography sx={{ fontSize: 12, color: "grey" }}>
                                        different screen sizes and aspect ratios,{" "}
                                    </Typography>
                                    <Typography sx={{ fontSize: 12, color: "grey" }}>
                                        ensuring that your business looks great on{" "}
                                    </Typography>
                                    <Typography sx={{ fontSize: 12, color: "grey" }}>
                                        all devices.
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                                items
                                xs={6}
                            >
                                <Box
                                    sx={{
                                        borderRadius: 2,
                                        height: 350,
                                        width: 270,
                                        m: 2,
                                        background: "#ffffff",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <img
                                        src={Customer}
                                        style={{ width: "60%", marginBottom: "12px" }}
                                    />
                                    <Typography
                                        sx={{ fontSize: 18, fontWeight: 800, color: "#053F76" }}
                                    >
                                        Our priority is you
                                    </Typography>
                                    <Typography sx={{ fontSize: 12, color: "grey" }}>
                                        We are always available to answer any{" "}
                                    </Typography>
                                    <Typography sx={{ fontSize: 12, color: "grey" }}>
                                        questions you may have, anytime. We{" "}
                                    </Typography>
                                    <Typography sx={{ fontSize: 12, color: "grey" }}>
                                        provide comprehensive tech and after-sales
                                    </Typography>
                                    <Typography sx={{ fontSize: 12, color: "grey" }}>
                                        support to ensure that you receive the{" "}
                                    </Typography>
                                    <Typography sx={{ fontSize: 12, color: "grey" }}>
                                        assistance you need, whenever you need it.
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        : <Grid
                            sx={{
                                mb: 12,
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-evenly",
                                alignItems: "center",
                            }}
                            container
                            spacing={2}
                        >
                            <Grid
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                                items
                                xs={12}
                                md={6}
                                lg={3}
                            >
                                <Box
                                    sx={{
                                        borderRadius: 2,
                                        height: 350,
                                        width: 270,
                                        m: 2,
                                        background: "#ffffff",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <img
                                        src={Devices}
                                        style={{ width: "60%", marginBottom: "12px" }}
                                    />
                                    <Typography
                                        sx={{ fontSize: 18, fontWeight: 800, color: "#053F76",mb:1 }}
                                    >
                                        Unparalleled Ethics
                                    </Typography>
                                    <Typography sx={{ fontSize: 12, color: "grey",textAlign:"justify",fontFamily:"Montserrat",ml:1.9,mr:1.9 }}>
                                    Our design experts create visually stunning and personalized designs that bring out the best in your unique identity.
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                                items
                                xs={12}
                                md={6}
                                lg={3}
                            >
                                <Box
                                    sx={{
                                        borderRadius: 2,
                                        height: 350,
                                        width: 270,
                                        m: 2,
                                        background: "#ffffff",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <img
                                        src={Commitment}
                                        style={{ width: "60%", marginBottom: "12px" }}
                                    />
                                    <Typography
                                        sx={{ fontSize: 18, fontWeight: 800, color: "#053F76",fontFamily:"Montserrat" ,mb:1}}
                                    >
                                        Honor our commitments
                                    </Typography>
                                    <Typography sx={{ fontSize: 12, color: "grey",textAlign:"justify",fontFamily:"Montserrat",ml:1.9,mr:1.9 }}>
                                    At India Buzz, we respect your time and take our commitments seriously. We deliver quality work on time, every time.
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                                items
                                xs={12}
                                md={6}
                                lg={3}
                            >
                                <Box
                                    sx={{
                                        borderRadius: 2,
                                        height: 350,
                                        width: 270,
                                        m: 2,
                                        background: "#ffffff",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <img
                                        src={Awsome}
                                        style={{ width: "60%", marginBottom: "12px" }}
                                    />
                                    <Typography
                                        sx={{ fontSize: 18, fontWeight: 800, color: "#053F76",mb:1,fontFamily:"Montserrat" }}
                                    >
                                       Excelling in all areas
                                    </Typography>
                                    <Typography sx={{ml:1.9,mr:1.9, fontSize: 11, color: "grey",textAlign:"justify",fontFamily:"Montserrat" }}>
                                    India Buzz crafts visually stunning websites and apps that effortlessly adapt to any device, showcasing your business beautifully.
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                                items
                                xs={12}
                                md={6}
                                lg={3}
                            >
                                <Box
                                    sx={{
                                        borderRadius: 2,
                                        height: 350,
                                        width: 270,
                                        m: 2,
                                        background: "#ffffff",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}
                                >
                                    <img
                                        src={Customer}
                                        style={{ width: "60%", marginBottom: "12px" }}
                                    />
                                    <Typography
                                        sx={{ mb:1,fontSize: 18, fontWeight: 800, color: "#053F76",fontFamily:"Montserrat" }}
                                    >
                                        Our priority is you
                                    </Typography>
                                    <Typography sx={{ml:1.9,mr:1.9, fontSize: 11, color: "grey",fontFamily:"Montserrat",textAlign:"justify" }}>
                                    Our dedicated support team is available 24/7 to answer your questions and provide comprehensive tech assistance, whenever you need it.
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>}
            </Container>
        </Box>
    )
}
