import React from 'react';
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

export default function FAQ() {

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));
    const ipad = useMediaQuery(theme.breakpoints.down(1200));

    const [openAccordion, setOpenAccordion] = React.useState(null);

    const toggleAccordion = (index) => {
        if (openAccordion === index) {
            setOpenAccordion(null);
        } else {
            setOpenAccordion(index);
        }
    };

    const data = [
        { title: "What does India Buzz actually do ?", content: "India Buzz is the industry leading software development and digital marketing company that works with your business to provide software and digital marketing related solutions that can help your business to achieve its goals and objectives." },
        { title: "Do my business really need Web Design and Development ?", content: "Your website is the first thing that your prospective clients or customers see. With a well-put-together and aesthetically pleasing website, you increase your chances of winning over that crucial client and selling that product. It also benefits your customers user experience,which in turn encourages them to work with you." },
        { title: "Why is India Buzz the best Software Development and Digital Marketing Firm ?", content: "With best-in-class service and experienced staff, India Buzz is equipped to handle every type of project with expert execution, which means you always get the best business solutions that are bespoke to your business needs." },
        { title: "How can a beginner start Web Development", content: "With best-in-class service and experienced staff,India Buzz is equipped to handle every type of project with expert execution, which means you always get the best business solutions that are bespoke to your business needs." }
    ]

    const faqComponent = () => {
        return data.map((item, index) => {
            return (
                <Box sx={{ width: ipad?"70%":"80%", background: "red", borderRadius: 1, m: 1,ml:-6 }} key={index}>
                    <Box sx={{ boxShadow: "0px 9px 14px 3px lightgrey", width: "100%", background: "#053F76", padding: mobile?"15px 25px":"15px 30px", display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer' }} onClick={() => toggleAccordion(index)}>
                        <Typography sx={{fontFamily:"Montserrat", fontSize: mobile?12:ipad?16:20, color: "#ffffff", fontWeight: 600, textAlign: "left" }}>{item.title}</Typography>
                        {openAccordion === index ?
                            <RemoveIcon sx={{ color: "#ffffff", fontSize:mobile?20:ipad?30: 40, fontWeight: "bold" }} /> :
                            <AddIcon sx={{ color: "#ffffff", fontSize: mobile?20:ipad?30:40, fontWeight: "bold" }} />
                        }
                    </Box>
                    {openAccordion === index && (
                        <Box sx={{ background: "#9CBCCB", width: "100%", padding:mobile?"15px 25px": "15px 30px" }}>
                            <Typography sx={{fontFamily:"Montserrat", color: "#053F76", fontSize: mobile?12:ipad?14:16,fontWeight:600 }}>{item.content}</Typography>
                        </Box>
                    )}
                </Box>
            );
        })
    }

    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            pt: 10
        }}>
            <Typography sx={{fontFamily:"Montserrat", color: "#053F76", fontSize: mobile?30:ipad?40:56, fontWeight: 900, mb: 5 }}>FAQs</Typography>
            
                {faqComponent()}
        </Box>
    );
}