import React, { useState, useEffect }  from 'react'
import Hero from '../Template/Hero'
import About from '../Template/About'
import Products from '../Template/Products'
import Payment from '../Template/Payment'
import bgimage from './assets/bg.jpg'
import ico from './chandlogo.ico'
import {GayatriJewellersContent} from './GayatriJewellersContent'
import '../../App.css'
import { Helmet } from 'react-helmet';
import Review from '../Template/Review'
import OurButton from '../Template/OurButton'

const GayatriJewellers = () => {
    const { hero,about,product,ourButton,review } = GayatriJewellersContent;
    const [visitCount, setVisitCount] = useState(0);
  useEffect(() => {
    const count = Number(localStorage.getItem('GayatriVisitCount')) || 0;
    setVisitCount(count + 1);
    localStorage.setItem('GayatriVisitCount', count + 1);
  }, []);
    return (
        <div style={{ backgroundImage:`url(${bgimage})`,
       backgroundBlendMode:'exclusion',
    
                
                 backgroundSize:'contain'
                 }} className="font-link2">
                     <div className="visit-count">
      {visitCount}
    </div>
                   <Helmet>
                   <meta charSet="utf-8" />
 
            <title>Gayatri Jewellers</title>
         
            <meta property="og:title" content="Gayatri Jewellers" />

<meta property="og:description" content="Gayatri Jewellers" />
    
          </Helmet>
         
          <Hero data={hero}/>
          <About data={about}/>
          <Products data={product}/>
          <Review data={review}/>
          <OurButton data={ourButton}/>
              </div>
      )
}

export default GayatriJewellers
