import headerbg from "../../assets/headerbg (7).png";
import logo from "./assets/gehnalogo.jpg";
import contactCs from "../../Digital cards/GayatriJewellers/assets/GayatriJewellers.vcf";
import QR from "../../assets/download.webp";
import bgImage from "../../assets/back.webp";
import silverbhichye1 from './assets/silverbhichiye1.png'
import silverbhichye2 from './assets/silverbhichiye2.png'
import silverbhichye3 from './assets/silverbhichiye3.png'
import silverbhichye4 from './assets/silverbhichiye4.png'
import silverpayal1 from "./assets/payal1.png"
import silverpayal2 from "./assets/payal2.png"
import silverpayal3 from "./assets/payal3.png"
import silverpayal4 from "./assets/payal4.png"
import dimaondnecklace1 from "./assets/diamondnecklace1.png"
import dimaondnecklace2 from "./assets/diamondnecklace2.png"
import dimaondnecklace3 from "./assets/diamondnecklace3.png"
import dimaondnecklace4 from "./assets/diamondnecklace4.png"
import goldnecklace1 from "./assets/goldnecklace1.png"
import goldnecklace2 from "./assets/goldnecklace2.png"
import goldnecklace3 from "./assets/goldnecklace3.png"
import goldnecklace4 from "./assets/goldnecklace4.png"
import goldbangles1 from "./assets/goldbangles1.png"
import goldbangles2 from "./assets/goldbangles2.png"
import goldbangles3 from "./assets/goldbangles3.png"
import goldbangles4 from "./assets/goldbangles4.png"
import diamondring1 from "./assets/diamondrings1.png"
import diamondring2 from "./assets/diamondrings2.png"
import diamondring3 from "./assets/diamondrings3.png"
import diamondring4 from "./assets/diamondrings4.png"
import goldring1 from "./assets/goldring1.png"
import goldring2 from "./assets/goldring2.png"
import goldring3 from "./assets/goldring3.png"
import goldring4 from "./assets/goldring4.png"
import diamondearring1 from "./assets/diamondearrings1.png"
import diamondearring2 from "./assets/diamondearrings2.png"
import diamondearring3 from "./assets/diamondearrings3.png"
import diamondearring4 from "./assets/diamondearrings4.png"
import goldearring1 from "./assets/goldearrings1.png"
import goldearring2 from "./assets/goldearrings2.png"
import goldearring3 from "./assets/goldearrings3.png"
import goldearring4 from "./assets/goldearrings4.png"
import silverring1 from './assets/ring1.png'
import silverring2 from './assets/ring2.png'
import silverring3 from './assets/ring3.png'
import silverring4 from './assets/ring4.png'

import Gehna from './assets/gehna.mp4'
import Gehna2 from './assets/gehna2.mp4'
export const GayatriJewellersContent = {
  hero: {
    headerImage: headerbg,
    logoImage: logo,
    name: "Gayatri Jewellers",
    phoneNumber: "+917247460604",
    phoneNumber1: "",
    phoneNumber2: "",
    phoneNumber3: "",
    whatsappLink:
      "https://api.whatsapp.com/send/?phone=917247460604&text=Message+from+Your+Digital+Card&type=phone_number&app_absent=0",
    mailId: "info@gayatrijewellers.com",
    googleMapLink: "https://goo.gl/maps/jdEc7jNW6WPLU7D56",
    pageHref: "https://gayatri-jwellers.business.site/",
    fbLink:
      "https://www.facebook.com/sharer/sharer.php?u=https://indiabuzz.co.in/gayatrijewellers",
    twitterLink:
      "https://twitter.com/intent/tweet/?url=https://indiabuzz.co.in/gayatrijewellers",
    linkdnLink:
      "https://www.linkedin.com/shareArticle/?mini=true&url=https://indiabuzz.co.in/gayatrijewellers",
    instaLink:
      "https://www.instagram.com/share?url=https://indiabuzz.co.in/gayatrijewellers",
    pinterestLink:
      "https://www.pinterest.com/pin/create/button/?url=https://indiabuzz.co.in/gayatrijewellers",
    telegramLink:
      "https://t.me/share/url?url=https://indiabuzz.co.in/gayatrijewellers",
    smsBody: "https://indiabuzz.co.in/gayatrijewellers",
    title: "Gehna Jeweller",
    titleLink: "https://indiabuzz.co.in/gayatrijewellers",
    customWhatsappText: "https://indiabuzz.co.in/gayatrijewellers",
    address1: " Madhav Ganj,",
    address2: "  Gwalior 474001",
    address3: "  Madhya Pradesh",
    contactFile: contactCs,
    textColor:'white',

    Product1: [
      {
        id: 5,
        image:Gehna,
        image1:Gehna2,

      },
    
      
    ],
    buttonColor: "#EEE8AA",

    buttonContentColor: "#800000",
  },
  about: {
    aboutContent:
      " As All We Know That Buying Jewellery Is Not A Routine Work. We Often Purchase Gold & Silver & Jewellery On Some Special Occasion Like Marriage, Engagement Or Festival. If Talking About Your Wedding Ring Then It Is One Of The Unique Pieces Of Jewellery That You Purchase. So, It Would Be A Good Decision To Purchase It From Your Trusted Store. Which Is Providing Good Quality Gold Jewellery & Silver Jewellery At Reasonable Price. If You Are Planning To Purchase Jewellery.",
    quality1: " Diamond Jewellery",
    quality2: "Gold Jewellery",
    quality3: "Silver Ornaments",
    quality4: " Gems Stone",
    mondayOpeningHours: "11:00 AM - 08:30 PM",
    tuesdayOpeningHours: "Closed",
    wednesdayOpeningHours: "11:00 AM - 08:30 PM",
    thursdayOpeningHours: "11:00 AM - 08:30 PM",
    fridayOpeningHours: "11:00 AM - 08:30 PM",
    saturdayOpeningHours: "11:00 AM - 08:30 PM",
    sundayOpeningHours: "11:00 AM - 08:30 PM",
    buttonColor: "#EEE8AA",
    buttonContentColor: "#000",
    textColor:'white',
  },
  product: {
    buttonColor: "#800000",
    buttonContentColor: "white",
    category1:'',
    category2:'Diamond Necklace',
    category3:'Diamond Rings',
    category4:'Diamond Ear-Rings',
    category5:'Gold Necklace',
    category6:'Gold Bangles',
    category7:'Gold Ear-Rings',
    category8:'Gold Rings',
    category9:'Silver Bichiye',
    category10:'Silver Payal',
    category11:'Silver Rings',
    Product2: [
      {
        id: 1,
        name: "Diamond Necklace",
        status: "In Stock",
        image:dimaondnecklace1,
        whatsappLink:"https://api.whatsapp.com/send/?phone=917247460604&text=Query+about+Your+Diamond Necklace&type=phone_number&app_absent=0",
      },
      {
        id: 2,
        name: "Diamond Necklace",
        status: "In Stock",
        image:dimaondnecklace2,
        whatsappLink:"https://api.whatsapp.com/send/?phone=917247460604&text=Query+about+Your+Diamond Necklace&type=phone_number&app_absent=0",
      },
      {
        id: 3,
        name: "Diamond Necklace",
        status: "In Stock",
        image:dimaondnecklace3,
        whatsappLink:"https://api.whatsapp.com/send/?phone=917247460604&text=Query+about+Your+Diamond Necklace&type=phone_number&app_absent=0",
      },
      {
        id: 4,
        name: "Diamond Necklace",
        status: "In Stock",
        image:dimaondnecklace4,
        whatsappLink:"https://api.whatsapp.com/send/?phone=917247460604&text=Query+about+Your+Diamond Necklace&type=phone_number&app_absent=0",
      },

    ],
    Product3: [
    
      {
        id: 1,
        name: "Diamond Ring",
        status: "In Stock",
        image:diamondring1,
        whatsappLink:"https://api.whatsapp.com/send/?phone=917247460604&text=Query+about+Your+Diamond RING&type=phone_number&app_absent=0",
      },
      {
        id: 2,
        name: "Diamond Ring",
        status: "In Stock",
        image:diamondring2,
        whatsappLink:"https://api.whatsapp.com/send/?phone=917247460604&text=Query+about+Your+Diamond RING&type=phone_number&app_absent=0",

      },
      {
        id: 3,
        name: "Diamond Ring",
        status: "In Stock",
        image:diamondring3,
        whatsappLink:"https://api.whatsapp.com/send/?phone=917247460604&text=Query+about+Your+Diamond RING&type=phone_number&app_absent=0",
      },
      {
        id: 4,
        name: "Diamond Ring",
        status: "In Stock",
        image:diamondring4,
        whatsappLink:"https://api.whatsapp.com/send/?phone=917247460604&text=Query+about+Your+Diamond RING&type=phone_number&app_absent=0",
      },

    ],
    Product4: [
      
      {
        id: 1,
        name: "Diamond EAR RING",
        status: "In Stock",
        image:diamondearring1,
        whatsappLink:"https://api.whatsapp.com/send/?phone=917247460604&text=Query+about+Your+Diamond EAR RING&type=phone_number&app_absent=0",
      },
      {
        id: 2,
        name: "Diamond EAR RING",
        status: "In Stock",
        image:diamondearring2,
        whatsappLink:"https://api.whatsapp.com/send/?phone=917247460604&text=Query+about+Your+Diamond EAR RING&type=phone_number&app_absent=0",
      },
      {
        id: 3,
        name: "Diamond EAR RING",
        status: "In Stock",
        image:diamondearring3,
        whatsappLink:"https://api.whatsapp.com/send/?phone=917247460604&text=Query+about+Your+Diamond EAR RING&type=phone_number&app_absent=0",
      },
      {
        id: 4,
        name: "Diamond EAR RING",
        status: "In Stock",
        image:diamondearring4,
        whatsappLink:"https://api.whatsapp.com/send/?phone=917247460604&text=Query+about+Your+Diamond EAR RING&type=phone_number&app_absent=0",
      },
    ],

    Product1: [],
    Product5: [
      {
        id: 1,
        name: "Gold Necklace",
        status: "In Stock",
        image:goldnecklace1,
        whatsappLink:"https://api.whatsapp.com/send/?phone=917247460604&text=Query+about+Your+Gold Necklace&type=phone_number&app_absent=0",
      },
      {
        id: 2,
        name: "Gold Necklace",
        status: "In Stock",
        image:goldnecklace2,
        whatsappLink:"https://api.whatsapp.com/send/?phone=917247460604&text=Query+about+Your+Gold Necklace&type=phone_number&app_absent=0",
      },
      {
        id: 3,
        name: "Gold Necklace",
        status: "In Stock",
        image:goldnecklace3,
        whatsappLink:"https://api.whatsapp.com/send/?phone=917247460604&text=Query+about+Your+Gold Necklace&type=phone_number&app_absent=0",
      },
      {
        id: 4,
        name: "Gold Necklace",
        status: "In Stock",
        image:goldnecklace4, 
        whatsappLink:"https://api.whatsapp.com/send/?phone=917247460604&text=Query+about+Your+Gold Necklace&type=phone_number&app_absent=0",
         },
   
    ],
    Product6: [
      {
        id: 1,
        name: "Gold Bangles",
        status: "In Stock",
        image:goldbangles1,
        whatsappLink:"https://api.whatsapp.com/send/?phone=917247460604&text=Query+about+Your+Gold Bangles&type=phone_number&app_absent=0",      },
      {
        id: 2,
        name: "Gold BANGLES",
        status: "In Stock",
        image:goldbangles2,
        whatsappLink:"https://api.whatsapp.com/send/?phone=917247460604&text=Query+about+Your+Gold Bangles&type=phone_number&app_absent=0",
      },
      {
        id: 3,
        name: "Gold BANGLES",
        status: "In Stock",
        image:goldbangles3,
        whatsappLink:"https://api.whatsapp.com/send/?phone=917247460604&text=Query+about+Your+Gold Bangles&type=phone_number&app_absent=0",
      },
      {
        id: 4,
        name: "Gold BANGLES",
        status: "In Stock",
        image:goldbangles4,
        whatsappLink:"https://api.whatsapp.com/send/?phone=917247460604&text=Query+about+Your+Gold Bangles&type=phone_number&app_absent=0",
      },
    ],
    Product7: [
     
      {
        id: 1,
        name: "Gold EAR RING",
        status: "In Stock",
        image:goldearring1,
        whatsappLink:"https://api.whatsapp.com/send/?phone=917247460604&text=Query+about+Your+Gold Ear Ring&type=phone_number&app_absent=0",
      },
      {
        id: 2,
        name: "Gold EAR RING",
        status: "In Stock",
        image:goldearring2,
        whatsappLink:"https://api.whatsapp.com/send/?phone=917247460604&text=Query+about+Your+Gold Ear Ring&type=phone_number&app_absent=0",
      },
      {
        id: 3,
        name: "Gold EAR RING",
        status: "In Stock",
        image:goldearring3,
        whatsappLink:"https://api.whatsapp.com/send/?phone=917247460604&text=Query+about+Your+Gold Ear Ring&type=phone_number&app_absent=0",
      },
      {
        id: 4,
        name: "Gold EAR RING",
        status: "In Stock",
        image:goldearring4,
        whatsappLink:"https://api.whatsapp.com/send/?phone=917247460604&text=Query+about+Your+Gold Ear Ring&type=phone_number&app_absent=0",
      },
    ],
    Product8: [
      {
        id: 1,
        name: "Gold Ring",
        status: "In Stock",
        image:goldring1,
        whatsappLink:"https://api.whatsapp.com/send/?phone=917247460604&text=Query+about+Your+Gold Ring&type=phone_number&app_absent=0",
      },
      {
        id: 2,
        name: "Gold Ring",
        status: "In Stock",
        image:goldring2,
        whatsappLink:"https://api.whatsapp.com/send/?phone=917247460604&text=Query+about+Your+Gold Ring&type=phone_number&app_absent=0",
      },
      {
        id: 3,
        name: "Gold Ring",
        status: "In Stock",
        image:goldring3,
        whatsappLink:"https://api.whatsapp.com/send/?phone=917247460604&text=Query+about+Your+Gold Ring&type=phone_number&app_absent=0",
      },
      {
        id: 4,
        name: "Gold Ring",
        status: "In Stock",
        image:goldring4,
        whatsappLink:"https://api.whatsapp.com/send/?phone=917247460604&text=Query+about+Your+Gold Ring&type=phone_number&app_absent=0",
      },
    ],
    Product9: [
      {
        id: 1,
        name: "Silver Bichiye",
        status: "In Stock",
        image:silverbhichye1,
        whatsappLink:"https://api.whatsapp.com/send/?phone=917247460604&text=Query+about+Your+Silver Bhichiye&type=phone_number&app_absent=0",
      },
      {
        id: 2,
        name: "Silver Bichiye",
        status: "In Stock",
        image:silverbhichye2,
        whatsappLink:"https://api.whatsapp.com/send/?phone=917247460604&text=Query+about+Your+Silver Bhichiye&type=phone_number&app_absent=0",
      },

      {
        id: 3,
        name: "Silver Bichiye",
        status: "In Stock",
        image:silverbhichye3,
        whatsappLink:"https://api.whatsapp.com/send/?phone=917247460604&text=Query+about+Your+Silver Bhichiye&type=phone_number&app_absent=0",
      },
      {
        id: 4,
        name: "Silver Bichiye",
        status: "In Stock",
        image:silverbhichye4,
        whatsappLink:"https://api.whatsapp.com/send/?phone=917247460604&text=Query+about+Your+Silver Bhichiye&type=phone_number&app_absent=0",
      },

      
    ],
    Product10: [
      {
        id: 1,
        name: "Silver Payal",
        status: "In Stock",
        image:silverpayal1,
        whatsappLink:"https://api.whatsapp.com/send/?phone=917247460604&text=Query+about+Your+Silver Payal&type=phone_number&app_absent=0",

      },
      {
        id: 2,
        name: "Silver Payal",
        status: "In Stock",
        image:silverpayal2,
        whatsappLink:"https://api.whatsapp.com/send/?phone=917247460604&text=Query+about+Your+Silver Payal&type=phone_number&app_absent=0",
      },

      {
        id: 3,
        name: "Silver Payal",
        status: "In Stock",
        image:silverpayal3,
        
        whatsappLink:"https://api.whatsapp.com/send/?phone=917247460604&text=Query+about+Your+Silver Payal&type=phone_number&app_absent=0",
      },
      {
        id: 4,
        name: "Silver Payal",
        status: "In Stock",
        image:silverpayal4,
        whatsappLink:"https://api.whatsapp.com/send/?phone=917247460604&text=Query+about+Your+Silver Payal&type=phone_number&app_absent=0",
      },
    ],
    Product11: [
      {
        id: 1,
        name: "Silver Ring",
        status: "In Stock",
        image:silverring1,
        whatsappLink:"https://api.whatsapp.com/send/?phone=917247460604&text=Query+about+Your+SilverRings&type=phone_number&app_absent=0",
      },
      {
        id: 2,
        name: "Silver Ring",
        status: "In Stock",
        image:silverring2,
        whatsappLink:"https://api.whatsapp.com/send/?phone=917247460604&text=Query+about+Your+SilverRings&type=phone_number&app_absent=0",
      },

      {
        id: 3,
        name: "Silver Ring",
        status: "In Stock",
        image:silverring3,
        whatsappLink:"https://api.whatsapp.com/send/?phone=917247460604&text=Query+about+Your+SilverRings&type=phone_number&app_absent=0",
      },
      {
        id: 4,
        name: "Silver Ring",
        status: "In Stock",
        image:silverring4,
        whatsappLink:"https://api.whatsapp.com/send/?phone=917247460604&text=Query+about+Your+SilverRings&type=phone_number&app_absent=0",
      },
    ],

    categories: [
      {
        id: 1,
        name: "All Types",
      },
      {
        id: 2,
        name: "Gems & Stone",
      },
      {
        id: 3,
        name: "Diamond Necklace",
      },
      {
        id: 4,
        name: "Diamond Rings",
      },
      {
        id: 5,
        name: "Diamond Ear-Rings",
      },
      {
        id: 6,
        name: "Gold Necklace",
      },
      {
        id: 7,
        name: "Gold Bangles",
      },
      {
        id: 8,
        name: "Gold Ear-Rings",
      },
      {
        id: 9,
        name: "Gold Rings",
      },
      {
        id: 10,
        name: "Silver Bichiye",
      },
      {
        id: 11,
        name: "Silver Payal",
      },
      {
        id: 12,
        name: "Silver Rings",
      },
    ],
    whatsappLink:
      "https://api.whatsapp.com/send/?phone=919425310099&text=Message+from+Your+Digital+Card&type=phone_number&app_absent=0",
    bgImage: "",
    textColor:'white',
  },
  payment: {
   
  },
  ourButton:{
    textColor:'white',
  },
  review:{
    textColor:'white',
  }
};
