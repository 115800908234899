import React, { useRef } from 'react';
import { Box, Typography, Grid, useTheme, useMediaQuery } from "@mui/material";
import image1 from "../Vault/1.png";
import image2 from "../Vault/2.png";
import image3 from "../Vault/3.png";
import image4 from "../Vault/4.png";
import image5 from "../Vault/5.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

export default function OurWorks() {

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));
    const ipad = useMediaQuery(theme.breakpoints.down(1200));

    var REF = useRef();
    let settings = {
        arrows: true,
        infinite: true,
        autoplay: true,
        speed: 700,
        slidesToShow: mobile ? 1 : ipad ? 3 : 4,
        slidesToScroll: 1,
    };

    const data = [
        { url: image1 },
        { url: image2 },
        { url: image3 },
        { url: image4 },
        { url: image5 }
    ]

    const showImages = () => {
        return data.map((item) => {
            return (
                <Grid style={{ marginLeft: 10 }}>
                    <div>
                        <img src={item.url} style={{ width: '100%' }} />
                    </div>
                </Grid>
            );
        });
    };

    return (
        <Box sx={{ mt: mobile ? 7 : 10, display: "flex", flexDirection: "column", mb: mobile ? 7 : 10 }}>
            <Typography sx={{fontFamily:"Montserrat", color: "#053F76", fontSize: mobile ? 21 : ipad ? 30 : 40, fontWeight: 900, textAlign: "center" }}>
                Grow Your Business With Our Agency
            </Typography>
            {mobile ? <>
                <Typography sx={{fontFamily:"Montserrat", color: "#808080", fontSize:12, fontWeight: 600, textAlign: "center", mt: 2 }}>
                India Buzz is your all-in-one solution for all your software development and digital
                    marketing needs. Our expertise lies in designing, developing, and implementing top-
                    quality websites and mobile software solutions tailored to your specific requirements.
                </Typography>
            </> : <><Typography sx={{fontFamily:"Montserrat", color: "#808080", fontSize: ipad ? 10 : 16, fontWeight: 500, textAlign: "center", mt: 3 }}>
            India Buzz is your all-in-one solution for all your software development and digital
            </Typography>
                <Typography sx={{fontFamily:"Montserrat", color: "#808080", fontSize: ipad ? 10 : 16, fontWeight: 500, textAlign: "center" }}>
                    marketing needs. Our expertise lies in designing, developing, and implementing top-
                </Typography>
                <Typography sx={{fontFamily:"Montserrat", color: "#808080", fontSize: ipad ? 10 : 16, fontWeight: 500, textAlign: "center" }}>
                    quality websites and mobile software solutions tailored to your specific requirements.
                </Typography></>}
            <Typography sx={{fontFamily:"Montserrat", color: "#053F76", fontSize: mobile ? 20 : ipad ? 28 : 38, fontWeight: 900, textAlign: "center", mt: mobile ? 3 : 6 }}>
                Some of Our Featured Works
            </Typography>
            <Box>
                <Slider {...settings} ref={REF} >
                    {showImages()}
                </Slider>
            </Box>
        </Box >
    )
}
