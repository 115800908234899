import { Box, Grid, Typography, TextField, Button, Divider } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function Payment() {

    const navigate = useNavigate()
    const location = useLocation()
    const { fullname,mobilenumber,emailid } = location.state;

    const handleSubmit = async()=>{
        navigate("/paymentgateway",{ state : { fullname : fullname, mobilenumber : mobilenumber, emailid : emailid } } ) 
}

    const Heading = () => {
        return (
            <Box sx={{ p: "30px 12px" }}>
                <Typography
                    sx={{
                        textAlign: "left",
                        fontSize: "22px",
                        color: "#FFFFFF",
                        fontWeight: 700,
                        fontFamily: "Poppins",
                    }}
                >
                    Congrats! You are just one step away from becoming a
                </Typography>
                <Typography
                    sx={{
                        textAlign: "left",
                        fontSize: "22px",
                        color: "#FFDE00",
                        fontWeight: 700,
                        fontFamily: "Poppins",
                    }}
                >
                    Google Ads Expert
                </Typography>
            </Box>
        );
    };
    const Heading1 = () => {
        return (
            <Box sx={{ p: "30px 12px" }}>
                <Typography
                    sx={{
                        textAlign: "left",
                        fontSize: "22px",
                        color: "#000000",
                        fontWeight: 700,
                        fontFamily: "Poppins",
                    }}
                >
                    Price For You : ₹99
                </Typography>
                <Typography
                    sx={{
                        textAlign: "left",
                        fontSize: "16px",
                        color: "#000000",
                        fontWeight: 500,
                        fontFamily: "Poppins",
                    }}
                >
                    Normal Price <s>₹2000</s>
                </Typography>
            </Box>
        );
    };

    const Payment = () => {
        return (
            <Box sx={{ p: "30px 12px" }}>
                <Box sx={{ background: "#ffffff", p: 2 }}>
                    <br />
                    <Grid container spacing={2}>
                        <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between", flexDirection: "row" }}>
                            <Typography
                                sx={{
                                    textAlign: "left",
                                    fontSize: "19px",
                                    color: "#000000",
                                    fontWeight: 600,
                                    fontFamily: "Poppins",
                                }}
                            >
                                Product
                            </Typography>
                            <Typography
                                sx={{
                                    textAlign: "left",
                                    fontSize: "19px",
                                    color: "#000000",
                                    fontWeight: 600,
                                    fontFamily: "Poppins",
                                }}
                            >
                                Subtotal
                            </Typography>
                        </Grid>
                        <Divider sx={{ color: "lightgrey", background: "lightgrey", width: "95%", ml: 2, mt: 1 }} />
                        <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between", flexDirection: "row", p: "0px 2px" }}>
                            <Typography
                                sx={{
                                    textAlign: "left",
                                    fontSize: "16px",
                                    color: "#000000",
                                    fontWeight: 400,
                                    fontFamily: "Poppins",
                                }}
                            >
                                Google Ads LIVE Workshop
                            </Typography>
                            <Typography
                                sx={{
                                    textAlign: "left",
                                    fontSize: "16px",
                                    color: "#000000",
                                    fontWeight: 400,
                                    fontFamily: "Poppins",
                                }}
                            >
                                ₹99
                            </Typography>
                        </Grid>
                        <Divider sx={{ color: "lightgrey", background: "lightgrey", width: "95%", ml: 2, mt: 1 }} />
                        <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between", flexDirection: "row", p: "0px 2px" }}>
                            <Typography
                                sx={{
                                    textAlign: "left",
                                    fontSize: "16px",
                                    color: "#000000",
                                    fontWeight: 400,
                                    fontFamily: "Poppins",
                                }}
                            >
                                GST 18%
                            </Typography>
                            <Typography
                                sx={{
                                    textAlign: "left",
                                    fontSize: "16px",
                                    color: "#000000",
                                    fontWeight: 400,
                                    fontFamily: "Poppins",
                                }}
                            >
                                ₹17.82
                            </Typography>
                        </Grid>
                        <Divider sx={{ color: "lightgrey", background: "lightgrey", width: "95%", ml: 2, mt: 1 }} />
                        <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between", flexDirection: "row", p: "0px 2px" }}>
                            <Typography
                                sx={{
                                    textAlign: "left",
                                    fontSize: "16px",
                                    color: "#000000",
                                    fontWeight: 600,
                                    fontFamily: "Poppins",
                                }}
                            >
                                Total
                            </Typography>
                            <Typography
                                sx={{
                                    textAlign: "left",
                                    fontSize: "16px",
                                    color: "#000000",
                                    fontWeight: 600,
                                    fontFamily: "Poppins",
                                }}
                            >
                                ₹116.82
                            </Typography>
                        </Grid>
                       <br /><br />
                        <Grid item xs={12}>
                            <Button
                            onClick={handleSubmit}
                                fullWidth
                                sx={{
                                    mt: 6,
                                    display: "flex",
                                    alignItems: "center",
                                    flexDirection: "row",
                                    textAlign: "left",
                                    mt: 1,
                                    p: "10px 20px",
                                    background: "#00b300",
                                    color: "#FFFFFF",
                                    fontSize: "20px",
                                    fontWeight: 700,
                                    fontFamily: "Poppins",
                                    textDecoration: "none",
                                }}
                            >
                                Pay Fees & Register
                            </Button>
                        </Grid>
                    </Grid>
                    <br />
                </Box>
            </Box>
        )
    }

    return (
        <Box sx={{ overflowX: "hidden", }}>
            <Box
                sx={{
                    background: "#053F76",
                    m: 0,
                    p: 0,
                    boxSizing: "border-box"
                }}
            >
                <Heading />
            </Box>
            <Box
                sx={{
                    background: "#9cbccb",
                    m: 0,
                    p: 0,
                    boxSizing: "border-box"
                }}
            >
                <Heading1 />
                <Payment />
                <br />
            </Box>
        </Box>

    );
}
