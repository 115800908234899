import { Box, Container } from '@mui/material'
import React from 'react'
import Footer from '../Sections/Footer'
import Navbar from '../Sections/Navbar'
import TellUsAbout from '../Sections/TellUsAbout'

export default function ContactUs() {
  return (
    <Box sx={{overflowX:"hidden"}}>
        <Navbar/>
        <br /><br /><br />
        <Container maxWidth="xl" sx={{mt:10}}>
            <TellUsAbout/>
        </Container>
        <br /><br /><br />
        <Footer/>
    </Box>
  )
}
