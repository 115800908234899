import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Popper from "@mui/material/Popper";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import IbuzzLogo from "../Vault/ibuzz.png";
import { ClickAwayListener, Divider } from "@mui/material";
import { NestedMenuItem } from "mui-nested-menu";
import MenuList from "@mui/material/MenuList";
import ListItemIcon from "@mui/material/ListItemIcon";
import Paper from "@mui/material/Paper";
import Grow from "@mui/material/Grow";
import StoreIcon from "@mui/icons-material/Store";
import DeveloperModeIcon from "@mui/icons-material/DeveloperMode";
import CodeIcon from "@mui/icons-material/Code";
import WebhookIcon from "@mui/icons-material/Webhook";
import CallIcon from "@mui/icons-material/Call";

export default function Navbar() {
	// THEME VARIABLES
	const theme = useTheme();
	const small = useMediaQuery(theme.breakpoints.down(480)); // MEDIA QUERY FOR MOBILE SCREEN
	const medium = useMediaQuery(theme.breakpoints.up("md")); // MEDIA QUERY FOR TABLET
	const medium1 = useMediaQuery(theme.breakpoints.down(1200)); // MEDIA QUERY FOR TABLET

	// OTHER VARIABLES
	let navigate = useNavigate();
	const [anchorElMainMenu, setAnchorElMainMenu] = React.useState(null);
	const [anchorElServicesMenu, setAnchorElServicesMenu] = React.useState(null);
	const [anchorElDigitalServicesMenu, setAnchorElDigitalServicesMenu] =
		React.useState(null);
	const [anchorElPackagesMenu, setAnchorElPackagesMenu] = React.useState(null);
	const [confirm, setConfirm] = React.useState("flex");
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open1 = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose1 = () => {
		setAnchorEl(null);
	};

	// FUNCTION TO OPEN NAVMENU
	const handleOpenNavMenu = (event) => {
		setAnchorElMainMenu(event.currentTarget);
	};

	// FUNCTION TO CLOSE NAVMENU
	const handleCloseNavMenu = () => {
		setAnchorElMainMenu(null);
	};

	// FUNCTION TO OPEN SERVICES OPTION
	function handleServicesOpen(event) {
		if (anchorElServicesMenu !== event.currentTarget) {
			console.log(event.currentTarget);
			setAnchorElServicesMenu(event.currentTarget);
			setConfirm("flex");
		}
	}

	// FUNCTION TO CLOSE SERVICES OPTION
	function handleServicesClose(event) {
		if (anchorElServicesMenu && !anchorElServicesMenu.contains(event.target)) {
			setAnchorElServicesMenu(null);
			setConfirm("none");
		}
	}

	// FUNCTION TO OPEN DIGITAL SERVICES
	function handleDigitalServicesOpen(event) {
		if (anchorElDigitalServicesMenu !== event.currentTarget) {
			setAnchorElDigitalServicesMenu(event.currentTarget);
		}
	}

	// FUNCTION TO CLOSE DIGITAL SERVICES
	function handleDigitalServicesClose() {
		setAnchorElServicesMenu(null);
	}

	// FUNCTION TO OPEN PACKAGES
	function handlePackagesOpen(event) {
		if (anchorElPackagesMenu !== event.currentTarget) {
			setAnchorElPackagesMenu(event.currentTarget);
		}
	}

	// FUNCTION TO CLOSE PACKAGES
	function handlePackagesClose() {
		setAnchorElPackagesMenu(null);
	}

	const [open, setOpen] = React.useState(false);
	const anchorRef = React.useRef(null);

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}
		setOpen(false);
	};

	function handleListKeyDown(event) {
		if (event.key === "Tab") {
			event.preventDefault();
			setOpen(false);
		} else if (event.key === "Escape") {
			setOpen(false);
		}
	}

	// return focus to the button when we transitioned from !open -> open
	const prevOpen = React.useRef(open);
	React.useEffect(() => {
		if (prevOpen.current === true && open === false) {
			anchorRef.current.focus();
		}

		prevOpen.current = open;
	}, [open]);

	// CODE FOR NAV MENU
	return (
		<AppBar
			position="fixed"
			elevation={3}
			sx={{
				backgroundColor: "#ffffff",
				width: "100%",
			}}
		>
			<Container maxWidth="xl">
				<Toolbar disableGutters>
					<img
						src={IbuzzLogo}
						width={80}
						style={{ display: small ? "none" : medium1 ? "none" : "flex" }}
					/>
					<Box sx={{ display: { xs: "flex", md: "none" } }}>
						<IconButton
							size="large"
							aria-label="account of current user"
							aria-controls="menu-appbar"
							aria-haspopup="true"
							onClick={handleOpenNavMenu}
							color="#053F76"
						>
							<MenuIcon />
						</IconButton>
						<Menu
							id="menu-appbar"
							anchorEl={anchorElMainMenu}
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "left",
							}}
							keepMounted
							transformOrigin={{
								vertical: "top",
								horizontal: "left",
							}}
							open={Boolean(anchorElMainMenu)}
							onClose={handleCloseNavMenu}
							sx={{
								display: { xs: "block", md: "none" },
							}}
						>
							<MenuItem
								onClick={() => {
									navigate("/home1");
								}}
							>
								<Typography
									textAlign="center"
									sx={{ fontFamily: "Montserrat" }}
								>
									Home
								</Typography>
							</MenuItem>
							<MenuItem
								onClick={() => {
									navigate("/aboutuspage");
								}}
							>
								<Typography
									textAlign="center"
									sx={{ fontFamily: "Montserrat" }}
								>
									About Us
								</Typography>
							</MenuItem>
							<MenuItem
								onClick={() => {
									navigate("/courses");
								}}
							>
								<Typography
									textAlign="center"
									sx={{ fontFamily: "Montserrat" }}
								>
									Courses
								</Typography>
							</MenuItem>
							<MenuItem onClick={handleClick}>
								<Typography
									textAlign="center"
									sx={{ fontFamily: "Montserrat" }}
								>
									Services
								</Typography>
							</MenuItem>
							<Menu
								id="basic-menu"
								anchorEl={anchorEl}
								open={open1}
								onClose={handleClose1}
								MenuListProps={{
									"aria-labelledby": "basic-button",
								}}
							>
								<MenuItem
									onClick={() => {
										navigate("/seoservice");
									}}
								>
									SEO Service
								</MenuItem>
								<MenuItem
									onClick={() => {
										navigate("/smoservice");
									}}
								>
									SMO Service
								</MenuItem>
								<MenuItem
									onClick={() => {
										navigate("/googleads");
									}}
								>
									Google Ads
								</MenuItem>
								<MenuItem
									onClick={() => {
										navigate("/branding");
									}}
								>
									Branding
								</MenuItem>
								<MenuItem
									onClick={() => {
										navigate("/contentmarketing");
									}}
								>
									Content Marketing
								</MenuItem>
								<MenuItem
									onClick={() => {
										navigate("/webdevelopmentservices");
									}}
								>
									Web Development
								</MenuItem>
								<MenuItem
									onClick={() => {
										navigate("/appdevelopment");
									}}
								>
									App Development
								</MenuItem>
								<MenuItem
									onClick={() => {
										navigate("/customsoftwaredevelopment");
									}}
								>
									Custom Software Development
								</MenuItem>
							</Menu>
							<MenuItem
								onClick={() => {
									navigate("/portfoliopage");
								}}
							>
								<Typography
									textAlign="center"
									sx={{ fontFamily: "Montserrat" }}
								>
									Portfolio
								</Typography>
							</MenuItem>
							<MenuItem
								onClick={() => {
									navigate("/contact");
								}}
							>
								<Typography
									textAlign="center"
									sx={{ fontFamily: "Montserrat" }}
								>
									Contact Us
								</Typography>
							</MenuItem>
						</Menu>
					</Box>
					<Box
						sx={{ mx: 10, flexGrow: 1, display: { xs: "none", md: "flex" },justifyContent:'center'}}
					>
						<Button
							onClick={() => {
								navigate("/home1");
							}}
							sx={{
								fontSize: 16,
								my: 2,
								color: "#053F76",
								display: "block",
								fontWeight: "bold",
								fontFamily: "Montserrat",
							}}
						>
							Home
						</Button>
						<Button
							onClick={() => {
								navigate("/aboutuspage");
							}}
							sx={{
								fontSize: 16,
								my: 2,
								color: "#053F76",
								display: "block",
								fontWeight: "bold",
								fontFamily: "Montserrat",
							}}
						>
							About Us
						</Button>
						<Button
							sx={{
								fontSize: 16,
								my: 2,
								color: "#053F76",
								display: "block",
								fontWeight: "bold",
								fontFamily: "Montserrat",
							}}
							onClick={() => {
								navigate("/courses");
							}}
						>
							Courses
						</Button>
						<Button
							ref={anchorRef}
							id="composition-button"
							aria-controls={open ? "composition-menu" : undefined}
							aria-expanded={open ? "true" : undefined}
							aria-haspopup="true"
							onMouseEnter={handleToggle}
							onMouseLeave={handleToggle}
							sx={{
								my: 2,
								fontSize: 16,
								color: "#053F76",
								display: "block",
								fontWeight: "bold",
								fontFamily: "Montserrat",
							}}
						>
							Services
							<Popper
								open={open}
								anchorEl={anchorRef.current}
								role={undefined}
								placement="bottom-start"
								transition
								disablePortal
							>
								{({ TransitionProps, placement }) => (
									<Grow
										{...TransitionProps}
										style={{
											fontFamily: "Montserrat",
											transformOrigin:
												placement === "bottom-start"
													? "left top"
													: "left bottom",
										}}
									>
										<Paper>
											<ClickAwayListener onMouseAway={handleClose}>
												<MenuList
													autoFocusItem={open}
													id="composition-menu"
													aria-labelledby="composition-button"
													onKeyDown={handleListKeyDown}
													style={{ fontWeight: "bold", background: "#ffffff" }}
												>
													<NestedMenuItem
														leftIcon={<StoreIcon />}
														label="Digital Marketting"
														parentMenuOpen={true}
														sx={{
															ml: 1.5,
															color: "#053F76",
															fontFamily: "Montserrat",
														}}
													>
														<MenuItem
															onClick={() => {
																navigate("/seoservice");
															}}
															sx={{
																color: "#053F76",
																fontFamily: "Montserrat",
															}}
														>
															SEO Services
														</MenuItem>
														<MenuItem
															onClick={() => {
																navigate("/smoservice");
															}}
															sx={{
																color: "#053F76",
																fontFamily: "Montserrat",
															}}
														>
															SMO Services
														</MenuItem>
														<MenuItem
															onClick={() => {
																navigate("/googleads");
															}}
															sx={{
																color: "#053F76",
																fontFamily: "Montserrat",
															}}
														>
															Google Ads{" "}
														</MenuItem>
														<MenuItem
															onClick={() => {
																navigate("/brandingservice");
															}}
															sx={{
																color: "#053F76",
																fontFamily: "Montserrat",
															}}
														>
															Branding
														</MenuItem>
														<MenuItem
															onClick={() => {
																navigate("/contentmarketing");
															}}
															sx={{
																color: "#053F76",
																fontFamily: "Montserrat",
															}}
														>
															Content Marketting
														</MenuItem>
													</NestedMenuItem>
													<MenuItem
														onClick={() => {
															navigate("/webdevelopmentservices");
														}}
														sx={{ color: "#053F76", fontFamily: "Montserrat" }}
													>
														{" "}
														<ListItemIcon>
															<CodeIcon
																sx={{
																	color: "#053F76",
																	fontFamily: "Montserrat",
																}}
															/>
														</ListItemIcon>
														Web Development
													</MenuItem>
													<MenuItem
														onClick={() => {
															navigate("/appdevelopment");
														}}
														sx={{ color: "#053F76", fontFamily: "Montserrat" }}
													>
														{" "}
														<ListItemIcon>
															<DeveloperModeIcon
																sx={{
																	color: "#053F76",
																	fontFamily: "Montserrat",
																}}
															/>
														</ListItemIcon>
														App Development
													</MenuItem>
													<MenuItem
														onClick={() => {
															navigate("/customsoftwaredevelopment");
														}}
														sx={{ color: "#053F76", fontFamily: "Montserrat" }}
													>
														{" "}
														<ListItemIcon>
															<WebhookIcon
																sx={{
																	color: "#053F76",
																	fontFamily: "Montserrat",
																}}
															/>
														</ListItemIcon>
														Custom Software Development
													</MenuItem>
												</MenuList>
											</ClickAwayListener>
										</Paper>
									</Grow>
								)}
							</Popper>
						</Button>
						<Button
							onClick={() => {
								navigate("/portfoliopage");
							}}
							sx={{
								my: 2,
								fontSize: 16,
								color: "#053F76",
								display: "block",
								fontWeight: "bold",
								fontFamily: "Montserrat",
							}}
						>
							Portfolio
						</Button>
						<Button
							onClick={() => {
								navigate("/contact");
							}}
							sx={{
								my: 2,
								fontSize: 16,
								color: "#053F76",
								display: "block",
								fontWeight: "bold",
								fontFamily: "Montserrat",
							}}
						>
							Contact Us
						</Button>
					</Box>
					<Typography
						sx={{
							display: small ? "none" : "block",
							textAlign: "center",
							color: "#053F76",
							fontWeight: 600,
							fontFamily: "Montserrat",
							backgroundColor: "#9cbccb",
							padding: "1px 4px",
							borderRadius: "100%",
						}}
					>
						<a href="tel:+91-8889430333">
							<CallIcon sx={{ color: "#053F76", mt: 1, ml: 0.5, mr: 0.5 }} />
						</a>
					</Typography>
					<Typography
						sx={{
							display: small ? "block" : "none",
							margin: "auto",
							textAlign: "center",
							color: "#053F76",
							fontWeight: 800,
							fontFamily: "Montserrat",
						}}
					>
						INDIABUZZ
					</Typography>

					<img
						src={IbuzzLogo}
						width="60"
						style={{
							display: small ? "block" : medium ? "none" : "none",
							width: " 56px",
							marginLeft: 20,
						}}
					/>
					<Box sx={{ flexGrow: 0 }}>
						<Menu
							sx={{ mt: "45px" }}
							id="menu-appbar"
							anchorOrigin={{
								vertical: "top",
								horizontal: "right",
							}}
							keepMounted
							transformOrigin={{
								vertical: "top",
								horizontal: "right",
							}}
						>
							<img src={IbuzzLogo} width="100" />
							<MenuItem>
								<Typography
									textAlign="center"
									color="#053F76"
									sx={{
										marginLeft: "89%",
										fontFamily: "Montserrat",
										backgroundColor: "#9cbccb",
										padding: "1px 4px",
										borderRadius: "100%",
									}}
								>
									<a href="tel:+91-8889430333">
										<CallIcon
											sx={{ color: "#053F76", mt: 1, ml: 0.5, mr: 0.5 }}
										/>
									</a>
								</Typography>
							</MenuItem>
						</Menu>
					</Box>
				</Toolbar>
			</Container>
		</AppBar>
	);
}
