import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import HeroImg from "../Vault/3D Designer job .png";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import { useNavigate } from "react-router-dom";

export default function Hero(props) {
  // MEDIA QUERY VARIABLE
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down(480));
  const medium = useMediaQuery(theme.breakpoints.down(1200));
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        justifyContent: { xs: "center", md: "space-between" },
        alignItems: "center",
        mb: { xs: 10, md: 5 },
        padding: { xs: 0, md: 10 },
      }}
    >
      <Box
        sx={{ display: "flex", flexDirection: "column", mt: { xs: 8, md: 0 } }}
      >
        <Typography
          sx={{
            fontSize: { xs: 35, md: 55 },
            fontFamily: "Montserrat",
            fontWeight: 800,
            textAlign: { xs: "center", md: "left" },
          }}
        >
          INDIA BUZZ
        </Typography>
        
        <Typography
          sx={{
            fontSize: { xs: 15, md: 20 },
            fontFamily: "Montserrat",
            color: "grey",
            mt: 2,
            fontWeight: 500,
            textAlign: { xs: "center", md: "left" },
          }}
        >
          Transform your online presence with IBuzz Tech Solutions
        </Typography>
       
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            flexDirection: "row",
            my: 4,
          }}
        >
          <Button
            sx={{
              fontFamily: "Montserrat",
              backgroundColor: "#FFDE00",
              boxShadow: "0px 9px 14px 3px lightgrey",
              padding: "8px 30px",
              fontSize: 18,
              mr: 2,
              color: "#000000",
              fontWeight: "bold",
              "&:hover": {
                background: "#053F76",
                color: "#FFDE00",
              },
            }}
            onClick={() => navigate("/contact")}
          >
            Get Started
          </Button>
          <Button
            sx={{
              fontFamily: "Montserrat",
              backgroundColor: "#9cbccb",
              boxShadow: "0px 9px 14px 3px lightgrey",
              padding: "8px 30px",
              fontSize: 18,
              mr: 2,
              color: "#053F76",
              fontWeight: "bold",
              "&:hover": {
                background: "#053F76",
                color: "#9cbccb",
              },
            }}
            onClick={() => props.onChange("ourwork")}
          >
            Learn More
          </Button>
        </Box>
      </Box>
      <img
        src={HeroImg}
        style={{
          width: small ? "70%" : "40%",
          marginTop: small ? 45 : medium ? 25 : 0,
        }}
      />
    </Box>
  );
}
