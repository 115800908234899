import './App.css';
import Home from './Pages/Home';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Aboutus from './Pages/Aboutus';
import Contactus from './Pages/Contactus';
import Webdevelopmentservices from './Pages/ServicePages/Webdevelopmentservices';
import Appdevelopment from './Pages/ServicePages/Appdevelopment';
import Customsoftwaredevelopment from './Pages/ServicePages/Customsoftwaredevelopment';
import Ppcpackages from './Pages/PackagePages/Ppcpackages';
import Localseopackages from './Pages/PackagePages/Localseopackages';
import Staticwebsitepackages from './Pages/PackagePages/Staticwebsite';
import Dynamicwebsitepackages from './Pages/PackagePages/Dynamicwebsitepackages';
import Seoservices from './Pages/ServicePages/Seoservices';
import Smoservices from './Pages/ServicePages/Smoservices';

import Branding from './Pages/ServicePages/Branding';
import ContentMarketing from './Pages/ServicePages/ContentMarketing';
import GoogleAds from './Pages/ServicePages/GoogleAds';
import PortfolioPage from './Pages/Portfolio';
import ChandanJwellers from './Digital cards/ChandanJwellers/ChandanJwellers';
import VerticalCarousel from './Pages/Carasoul';
import Ratiram from './Digital cards/Ratiram/Ratiram';
import GehnaJwellers from './Digital cards/GehnaJwellers/GehnaJwellers';
import TruFlow from './Digital cards/TruFlow/TruFlow';
import GayatriJewellers from './Digital cards/GayatriJewellers/GayatriJewellers';
import RakshaPipes from './Digital cards/RakshaPipes/RakshaPipes';
import AddressPage from './CJ/AddressPage';
import DeliverDetails from './CJ/DeliverDetails';


// NEW CREATED PAGES
import Home1 from "./AComponents/Pages/Home";
import About from './AComponents/Pages/About';
import Courses from './AComponents/Pages/Courses';
import Portfolio from './AComponents/Pages/Portfolio';
import ContactUs from './AComponents/Pages/ContactUs';
import SEOService from './AComponents/Sections/ServicesPages/SEOService';
import SMOService from './AComponents/Sections/ServicesPages/SMOService';
import BrandingService from './AComponents/Sections/ServicesPages/Branding';
import CustomSoftwareDev from './AComponents/Sections/ServicesPages/CustomSoftwareDev';
import AppDev from './AComponents/Sections/ServicesPages/AppDev';
import GoogleADS from './AComponents/Sections/ServicesPages/GoogleADS';
import ContentMARKETING from './AComponents/Sections/ServicesPages/ContentMARKETING';
import WebDev from './AComponents/Sections/ServicesPages/WebDev';
import AdminLogin from './Administrator/Login/AdminLogin';
import DashBoard from './Administrator/Login/DashBoard';
import GoogleAdsWorkShop from './Single Web Page/GoogleAds Workshop Page/GoogleAdsWorkShop';
import Faq from '../src/Single Web Page/GoogleAds Workshop2/Faq';
import RegisterPage from './Single Web Page/GoogleAds Workshop Page/RegisterPage';
import Payment from './Single Web Page/GoogleAds Workshop Page/Payment';
import PaymentGateway from './Single Web Page/GoogleAds Workshop Page/PaymentGateway';
import { HashRouter } from 'react-router-dom';
import DemoPage from './DemoPage/DemoPage';
import { SessionContext } from './ApiServices/SessionContext';

import { Notifications } from 'react-push-notification';
import Clickpay from './Administrator/Clickpay';
function App() {
	const [session, setSession] = React.useState(false);
 
	return (
		<div>
			
			<SessionContext.Provider value={{ session, setSession }}>
			<Notifications />
			<HashRouter>
				<Routes>
					<Route element={<Home1 />} path="/" />
					<Route element={<Home1 />} path="/home1" />
					<Route element={<About />} path="/aboutuspage" />
					<Route element={<Courses />} path="/courses" />
					<Route element={<Portfolio />} path="/portfoliopage" />
					<Route element={<ContactUs />} path="/contact" />
					<Route element={<SEOService />} path="/seoservice" />
					<Route element={<SMOService />} path="/smoservice" />
					<Route element={<BrandingService />} path="/branding" />
					<Route element={<GoogleADS />} path="/googleads" />
					<Route element={<ContentMARKETING />} path="/contentmarketing" />
					<Route element={<WebDev />} path="/webdevelopmentservices" />
					<Route element={<AppDev />} path="/appdevelopment" />
					<Route element={<CustomSoftwareDev />} path="/customsoftwaredevelopment" />
					<Route element={<GoogleAdsWorkShop />} path="/googleadsworkshop" />
					<Route element={<Faq />} path="/googleadsworkshop2" />
					<Route element={<RegisterPage />} path="/registerpage" />
					<Route element={<Payment />} path="/payment" />
					<Route element={<PaymentGateway />} path="/paymentgateway" />

					<Route element={<AdminLogin />} path="/adminlogin" />
					<Route path="/dashboard/*" element={<DashBoard />} />
					<Route path="/demopage" element={<DemoPage />} />

					{/* Prevoius Pages */}
					<Route element={<Home />} path="/home" />
					<Route element={<DeliverDetails />} path="/deliverydetails" />
					<Route element={<AddressPage />} path="/addresspage" />
					<Route element={<Aboutus />} path="/aboutus" />
					<Route element={<Contactus />} path="/contactus" />
					<Route element={<Webdevelopmentservices />} path="/webdevelopmentservice" />
					<Route element={<Appdevelopment />} path="/appdevelopments" />
					<Route element={<Customsoftwaredevelopment />} path="/customsoftwaredevelopments" />
					<Route element={<Seoservices />} path="/seoservices" />
					<Route element={<Smoservices />} path="/smoservices" />
					<Route element={<Ppcpackages />} path="/ppcpackages" />
					<Route element={<Localseopackages />} path="/localseopackages" />
					<Route element={<Staticwebsitepackages />} path="/staticwebsitepackages" />
					<Route element={<Dynamicwebsitepackages />} path="/dynamicwebsitepackages" />
					<Route element={<DemoPage />} path="/demopage" />
					<Route element={<ContentMarketing />} path="/contentmarketings" />
					<Route element={<Branding />} path="/brandings" />
					<Route element={<GoogleAds />} path="/googlead" />
					<Route element={<PortfolioPage />} path="/portfolio" />
					<Route element={<ChandanJwellers />} path="/chandanjwellers" />
					<Route element={<VerticalCarousel />} path="/carasoul" />
					<Route element={<Ratiram />} path="/ratiramgajakbhandar" />
					<Route element={<GehnaJwellers />} path="/gehnajeweller" />
					<Route element={<TruFlow />} path="/parsavnathtraders" />
					<Route element={<RakshaPipes />} path="/darshittraders" />
					<Route element={<GayatriJewellers />} path="/gayatrijewellers" />
					<Route element={<Clickpay />} path="/clickpay" />
					
				</Routes>
			</HashRouter>
			</SessionContext.Provider>
		</div>
	);
}

export default App;
