import headerbg from '../../assets/headerbg (7).png'
import logo from  '../../assets/chandlogo.webp'
import contactCsv from '../../assets/contact.vcf'
import QR from '../../assets/download.webp'
import bgImage from '../../assets/back.webp'
import ChandanJwellers2 from '../../assets/Chandan Jwellers2.mp4'
import ChandanJwellers from '../../assets/Chandan Jwellers.mp4'

export const ChandanJwellersContent = {
  
  hero: {
    headerImage:headerbg,
    logoImage:logo,
    name: "चन्दन ज्वैलर्स (गोरमी वाले)",
    phoneNumber:"+917415164000",
    phoneNumber1:'+919826761668',
    phoneNumber2:'+919826714791',
    phoneNumber3:'+919826234005',
    whatsappLink:"https://api.whatsapp.com/send/?phone=917415164000&text=Query+about+Your+Digital+Card&type=phone_number&app_absent=0",
    mailId:' chandan.agrawal369@gmail.com',
    googleMapLink:'https://goo.gl/maps/zmZytmq7fd6V1Jte8',
    pageHref:'https://asapdelivery.in/chandanjwellers',
    fbLink:'https://www.facebook.com/sharer/sharer.php?u=https://asapdelivery.in/chandanjwellers',
    twitterLink:'https://twitter.com/intent/tweet/?url=https://asapdelivery.in/chandanjwellers',
    linkdnLink:'https://www.linkedin.com/shareArticle/?mini=true&url=https://asapdelivery.in/chandanjwellers',
    instaLink:'https://www.instagram.com/share?url=https://asapdelivery.in/chandanjwellers',
    pinterestLink:'https://www.pinterest.com/pin/create/button/?url=https://asapdelivery.in/chandanjwellers',
    telegramLink:'https://t.me/share/url?url=https://asapdelivery.in/chandanjwellers',
    smsBody:'https://asapdelivery.in/chandanjwellers',
    title: "Chandan Jwellers",
    titleLink: "https://asapdelivery.in/chandanjwellers",
    customWhatsappText:'https://asapdelivery.in/chandanjwellers',
    address1:' Sarafa Bazar, Lashkar',
    address2:'  Gwalior 474001',
    address3:'  Madhya Pradesh',
    contactFile:contactCsv,
    buttonColor:'#421150',textColor:'#000',
    
    buttonContentColor:'#FFD700',
    
    Product1: [
   
      {
        id: 5,
        image:ChandanJwellers,
        image1:ChandanJwellers2,

      },
    
      
     
      
    
      
    ],
  },
  about:{
    aboutContent:'As All We Know That Buying Jewellery Is Not A Routine Work. We Often Purchase Gold & Silver & Jewellery On Some Special Occasion Like Marriage, Engagement Or Festival. If Talking About Your Wedding Ring Then It Is One Of The Unique Pieces Of Jewellery That You Purchase. So, It Would Be A Good Decision To Purchase It From Your Trusted Store. Which Is Providing Good Quality Gold Jewellery & Silver Jewellery At Reasonable Price. If You Are Planning To Purchase Jewellery.',
    quality1:' Diamond Jewellery',
    quality2:'Gold Jewellery',
    quality3:'Silver Ornaments',
    quality4:' Gems Stone',
    mondayOpeningHours:'10:00 AM - 08:00 PM',
    tuesdayOpeningHours:'Holiday',
    wednesdayOpeningHours:'10:00 AM - 08:00 PM',
    thursdayOpeningHours:'10:00 AM - 08:00 PM',
    fridayOpeningHours:'10:00 AM - 08:00 PM',
    saturdayOpeningHours:'10:00 AM - 08:00 PM',
    sundayOpeningHours:'10:00 AM - 08:00 PM',
    buttonColor:'#421150',
    buttonContentColor:'#FFD700',
    textColor:'#000',
  },
  product:{
    buttonColor:'#421150',
    buttonContentColor:'#FFD700',
    category1:'Gems & Stone',
    category2:'Diamond Necklace',
    category3:'Diamond Rings',
    category4:'Diamond Ear-Rings',
    category5:'Gold Necklace',
    category6:'Gold Bangles',
    category7:'Gold Ear-Rings',
    category8:'Gold Rings',
    category9:'Silver Bichiye',
    category10:'Silver Payal',
    category11:'Silver Rings',textColor:'#000',
    Product2 :[
      {
        id: 1,
        name: "Diamond Necklace",
        status: "In Stock",
        image:
          "https://dizitalcard.in/chandan-jewellers/8ad1c5ee-940e-44e4-8a88-82a4d182ad5e.webp",
          whatsappLink:"https://api.whatsapp.com/send/?phone=917415164000&text=Query+about+Your+Diamond Necklace&type=phone_number&app_absent=0",
      },
      {
        id: 2,
        name: "Diamond Necklace",
        status: "In Stock",
        image:
          "https://dizitalcard.in/chandan-jewellers/b52813eb-6726-4188-8aa7-8ca0ea34f4f0.webp",
          whatsappLink:"https://api.whatsapp.com/send/?phone=917415164000&text=Query+about+Your+Diamond Necklace&type=phone_number&app_absent=0",
      },
      {
        id: 3,
        name: "Diamond Necklace",
        status: "In Stock",
        image:
          "https://dizitalcard.in/chandan-jewellers/537cc0b8-b005-4c07-829e-a7a1271a0cab.webp",
          whatsappLink:"https://api.whatsapp.com/send/?phone=917415164000&text=Query+about+Your+Diamond Necklace&type=phone_number&app_absent=0",
      },
      {
        id: 4,
        name: "Diamond Necklace",
        status: "In Stock",
        image:
          "https://dizitalcard.in/chandan-jewellers/67a512cb-2166-4c37-b64e-6e3c589a5c39.webp",
          whatsappLink:"https://api.whatsapp.com/send/?phone=917415164000&text=Query+about+Your+Diamond Necklace&type=phone_number&app_absent=0",
      },
      {
        id: 5,
        name: "Diamond Necklace",
        status: "In Stock",
        image:
          "https://dizitalcard.in/chandan-jewellers/9535a65a-4b52-4e58-adfa-bcbbff45f224.webp",
          whatsappLink:"https://api.whatsapp.com/send/?phone=917415164000&text=Query+about+Your+Diamond Necklace&type=phone_number&app_absent=0",
      },
      
    ],
    Product3:[
      
      {
        id: 1,
        name: "Diamond Ring",
        status: "In Stock",
        image:
          "https://dizitalcard.in/chandan-jewellers/c5128577-a7ed-4e38-8576-ae7f674c1ebb.webp",
          whatsappLink:"https://api.whatsapp.com/send/?phone=917415164000&text=Query+about+Your+Diamond RING&type=phone_number&app_absent=0",
      },
      {
        id: 2,
        name: "Diamond Ring",
        status: "In Stock",
        image:
          "https://dizitalcard.in/chandan-jewellers/7f84643b-01d5-4f75-a8ee-73e3a1dcbdb9.webp",
          whatsappLink:"https://api.whatsapp.com/send/?phone=917415164000&text=Query+about+Your+Diamond RING&type=phone_number&app_absent=0",
      },
      {
        id: 3,
        name: "Diamond Ring",
        status: "In Stock",
        image:
          "https://dizitalcard.in/chandan-jewellers/3ca16c2c-5a79-4b51-9365-183d6db73aab.webp",
          whatsappLink:"https://api.whatsapp.com/send/?phone=917415164000&text=Query+about+Your+Diamond RING&type=phone_number&app_absent=0",
      },
      {
        id: 4,
        name: "Diamond Ring",
        status: "In Stock",
        image:
          "https://dizitalcard.in/chandan-jewellers/af09edb0-b2ff-4169-b02b-435ef2cf9bc0.webp",
          whatsappLink:"https://api.whatsapp.com/send/?phone=917415164000&text=Query+about+Your+Diamond RING&type=phone_number&app_absent=0",
      },
    ],
    Product4:[
      
      {
        id: 1,
        name: "Diamond EAR RING",
        status: "In Stock",
        image:
          "https://dizitalcard.in/chandan-jewellers/3ca39a12-18ad-4a27-8910-dd6c085aad02.webp",
          whatsappLink:"https://api.whatsapp.com/send/?phone=917415164000&text=Query+about+Your+Diamond EAR RING&type=phone_number&app_absent=0",
      },
      {
        id: 2,
        name: "Diamond EAR RING",
        status: "In Stock",
        image:
          "https://dizitalcard.in/chandan-jewellers/50aa7acd-70b5-4fdf-8bc8-fdb88c61760a.webp",
          whatsappLink:"https://api.whatsapp.com/send/?phone=917415164000&text=Query+about+Your+Diamond EAR RING&type=phone_number&app_absent=0",
      },
      {
        id: 3,
        name: "Diamond EAR RING",
        status: "In Stock",
        image:
          "https://dizitalcard.in/chandan-jewellers/e2986cbc-c963-4679-b9f6-aecad77ea897.webp",
          whatsappLink:"https://api.whatsapp.com/send/?phone=917415164000&text=Query+about+Your+Diamond EAR RING&type=phone_number&app_absent=0",
      },
      {
        id: 4,
        name: "Diamond EAR RING",
        status: "In Stock",
        image:
          "https://dizitalcard.in/chandan-jewellers/af591bed-51c4-4364-a4e6-2c059eedbd51.webp",
          whatsappLink:"https://api.whatsapp.com/send/?phone=917415164000&text=Query+about+Your+Diamond EAR RING&type=phone_number&app_absent=0",
      },
      {
        id: 5,
        name: "Diamond EAR RING",
        status: "In Stock",
        image:
          "https://dizitalcard.in/chandan-jewellers/07068c28-7263-4c03-8d8c-54b7dd5b08ca.webp",
          whatsappLink:"https://api.whatsapp.com/send/?phone=917415164000&text=Query+about+Your+Diamond EAR RING&type=phone_number&app_absent=0",
        },
    ],
    
     Product1: [
      {
        id: 1,
        name: "Pearl (Moti)",
        status: "In Stock",
        image:
          "https://dizitalcard.in/chandan-jewellers/82ced023-3ed2-4f3a-b331-6869d544c54e.webp",
          whatsappLink:"https://api.whatsapp.com/send/?phone=917415164000&text=Query+about+Your+Pearl (Moti)&type=phone_number&app_absent=0",
      
      },
      {
        id: 2,
        name: "Coral (Munga)",
        status: "In Stock",
        image:
          "https://dizitalcard.in/chandan-jewellers/20d8ada0-af62-4322-bad9-4b4212ecb88a.webp",
          whatsappLink:"https://api.whatsapp.com/send/?phone=917415164000&text=Query+about+Your+Coral (Munga)&type=phone_number&app_absent=0",
      },
    
      {
        id: 3,
        name: "RUBY (MANAK)",
        status: "In Stock",
        image:
          "https://dizitalcard.in/chandan-jewellers/6beec0ce-a75a-4786-9757-d5ec5c5f3e6c.webp",
          whatsappLink:"https://api.whatsapp.com/send/?phone=917415164000&text=Query+about+Your+RUBY (MANAK)&type=phone_number&app_absent=0",
      },
      {
        id: 4,
        name: "YELLOW SAPPHIRE",
        status: "In Stock",
        image:
          "https://dizitalcard.in/chandan-jewellers/a464ea70-80f5-415f-aa1b-13f8b7dcca42.webp",
          whatsappLink:"https://api.whatsapp.com/send/?phone=917415164000&text=Query+about+Your+YELLOW SAPPHIRE&type=phone_number&app_absent=0",
      },
      {
        id: 5,
        name: "BLUE SAPPHIRE (NILAM)",
        status: "In Stock",
        image:
          "https://dizitalcard.in/chandan-jewellers/eb51cdee-8975-470f-8de4-39ba665a2404.webp",
          whatsappLink:"https://api.whatsapp.com/send/?phone=917415164000&text=Query+about+Your+BLUE SAPPHIRE (NILAM)&type=phone_number&app_absent=0",
      },
      {
        id: 6,
        name: "HESSONITE",
        status: "In Stock",
        image:
          "https://dizitalcard.in/chandan-jewellers/c7557ef6-dd88-4583-b4c7-b637fd37a5d3.webp",
          whatsappLink:"https://api.whatsapp.com/send/?phone=917415164000&text=Query+about+Your+HESSONITE&type=phone_number&app_absent=0",
      },
      {
        id: 7,
        name: "CAT'S EYE",
        status: "In Stock",
        image:
          "https://dizitalcard.in/chandan-jewellers/41c9ec2f-d658-45c9-a592-8f2b3a784777.webp",
          whatsappLink:"https://api.whatsapp.com/send/?phone=917415164000&text=Query+about+Your+CAT'S EYE&type=phone_number&app_absent=0",
      },
      {
        id: 8,
        name: "EMERALD",
        status: "In Stock",
        image:
          "https://dizitalcard.in/chandan-jewellers/2851d460-5f1e-46dc-944d-70603c869b66.webp",
          whatsappLink:"https://api.whatsapp.com/send/?phone=917415164000&text=Query+about+Your+Emerald&type=phone_number&app_absent=0",
      },
    ],
    Product5:[
      
      {
        id: 1,
        name: "Gold Necklace",
        status: "In Stock",
        image:
          "https://dizitalcard.in/chandan-jewellers/c7615de7-0b71-47d3-9f6b-2c0c51a46372.webp",
          whatsappLink:"https://api.whatsapp.com/send/?phone=917415164000&text=Query+about+Your+Gold Necklace&type=phone_number&app_absent=0",
      },
      {
        id: 2,
        name: "Gold Necklace",
        status: "In Stock",
        image:
          "https://dizitalcard.in/chandan-jewellers/8b01803c-8709-479d-9a26-df3d5af58ebe.webp",
          whatsappLink:"https://api.whatsapp.com/send/?phone=917415164000&text=Query+about+Your+Gold Necklace&type=phone_number&app_absent=0",
      },
      {
        id: 3,
        name: "Gold Necklace",
        status: "In Stock",
        image:
          "https://dizitalcard.in/chandan-jewellers/b9441b63-a870-4886-9dc2-dcdbd48d4301.webp",
          whatsappLink:"https://api.whatsapp.com/send/?phone=917415164000&text=Query+about+Your+Gold Necklace&type=phone_number&app_absent=0",
      },
      {
        id: 4,
        name: "Gold Necklace",
        status: "In Stock",
        image:
          "https://dizitalcard.in/chandan-jewellers/feab086c-68a4-4b90-a1f5-12ca47163edd.webp",
          whatsappLink:"https://api.whatsapp.com/send/?phone=917415164000&text=Query+about+Your+Gold Necklace&type=phone_number&app_absent=0",
      },
      {
        id: 5,
        name: "Gold Necklace",
        status: "In Stock",
        image:
          "https://dizitalcard.in/chandan-jewellers/e0b30ab2-9e4d-4f80-a0e9-0acf68ec397e.webp",
          whatsappLink:"https://api.whatsapp.com/send/?phone=917415164000&text=Query+about+Your+Gold Necklace&type=phone_number&app_absent=0",
      },
      
    ],
    Product6:[
      
      {
        id: 1,
        name: "Gold BANGLES",
        status: "In Stock",
        image:
          "https://dizitalcard.in/chandan-jewellers/47cfb01d-9761-4453-be6d-dee826e8305d.webp",
          whatsappLink:"https://api.whatsapp.com/send/?phone=917415164000&text=Query+about+Your+Gold Bangles&type=phone_number&app_absent=0",
      },
      {
        id: 2,
        name: "Gold Bangles",
        status: "In Stock",
        image:
          "https://dizitalcard.in/chandan-jewellers/bb94d289-6b28-42b9-89d9-1f7f15c30b8e.webp",
          whatsappLink:"https://api.whatsapp.com/send/?phone=917415164000&text=Query+about+Your+Gold Bangles&type=phone_number&app_absent=0",
      },
      {
        id: 3,
        name: "Gold BANGLES",
        status: "In Stock",
        image:
          "https://dizitalcard.in/chandan-jewellers/6be9260f-3fc4-4c4f-93d5-14df681ce9be.webp",
          whatsappLink:"https://api.whatsapp.com/send/?phone=917415164000&text=Query+about+Your+Gold Bangles&type=phone_number&app_absent=0",
        },
      {
        id: 4,
        name: "Gold BANGLES",
        status: "In Stock",
        image:
          "https://dizitalcard.in/chandan-jewellers/aa4211aa-1796-4403-ad39-84f3e4f8e710.webp",
          whatsappLink:"https://api.whatsapp.com/send/?phone=917415164000&text=Query+about+Your+Gold Bangles&type=phone_number&app_absent=0",
      },
      {
        id: 5,
        name: "Gold BANGLES",
        status: "In Stock",
        image:
          "https://dizitalcard.in/chandan-jewellers/708621e0-a0f5-4fb0-acd8-ee05f0c30e31.webp",
          whatsappLink:"https://api.whatsapp.com/send/?phone=917415164000&text=Query+about+Your+Gold Bangles&type=phone_number&app_absent=0",
      },
    ],
    Product7:[
      
      {
        id: 1,
        name: "Gold EAR RING",
        status: "In Stock",
        image:
          "https://dizitalcard.in/chandan-jewellers/4e3a7185-061b-4b4e-86e6-5e240960027b.webp",
          whatsappLink:"https://api.whatsapp.com/send/?phone=917415164000&text=Query+about+Your+Gold Ear Ring&type=phone_number&app_absent=0",
        },
      {
        id: 2,
        name: "Gold EAR RING",
        status: "In Stock",
        image:
          "https://dizitalcard.in/chandan-jewellers/9e6c4e78-6fa1-4f34-9a3b-120ed509cae4.webp",
          whatsappLink:"https://api.whatsapp.com/send/?phone=917415164000&text=Query+about+Your+Gold Ear Ring&type=phone_number&app_absent=0",
        },
      {
        id: 3,
        name: "Gold EAR RING",
        status: "In Stock",
        image:
          "https://dizitalcard.in/chandan-jewellers/69cdb744-fb5b-4850-b709-f1a36351d29f.webp",
          whatsappLink:"https://api.whatsapp.com/send/?phone=917415164000&text=Query+about+Your+Gold Ear Ring&type=phone_number&app_absent=0",
        },
      {
        id: 4,
        name: "Gold EAR RING",
        status: "In Stock",
        image:
          "https://dizitalcard.in/chandan-jewellers/d98df7b8-9e4f-4154-8f43-1c39896f652a.webp",
          whatsappLink:"https://api.whatsapp.com/send/?phone=917415164000&text=Query+about+Your+Gold Ear Ring&type=phone_number&app_absent=0",
        },
      {
        id: 5,
        name: "Gold EAR RING",
        status: "In Stock",
        image:
          "https://dizitalcard.in/chandan-jewellers/4824986f-9a84-4f84-a1a4-9200315136e4.webp",
          whatsappLink:"https://api.whatsapp.com/send/?phone=917415164000&text=Query+about+Your+Gold Ear Ring&type=phone_number&app_absent=0",
      },
    ],
    Product8:[
      
      {
        id: 1,
        name: "Gold Ring",
        status: "In Stock",
        image:
          "https://dizitalcard.in/chandan-jewellers/0f9680f9-7b32-4554-b5d2-d367f160eca9.webp",
          whatsappLink:"https://api.whatsapp.com/send/?phone=917415164000&text=Query+about+Your+Gold Ring&type=phone_number&app_absent=0",
        },
      {
        id: 2,
        name: "Gold Ring",
        status: "In Stock",
        image:
          "https://dizitalcard.in/chandan-jewellers/8520e69f-8375-4fbc-820b-a5024d8ac279.webp",
          whatsappLink:"https://api.whatsapp.com/send/?phone=917415164000&text=Query+about+Your+Gold Ring&type=phone_number&app_absent=0",
        },
      {
        id: 3,
        name: "Gold Ring",
        status: "In Stock",
        image:
          "https://dizitalcard.in/chandan-jewellers/e30a3853-a335-4e11-b642-582259a2d548.webp",
          whatsappLink:"https://api.whatsapp.com/send/?phone=917415164000&text=Query+about+Your+Gold Ring&type=phone_number&app_absent=0",
      },
      {
        id: 4,
        name: "Gold Ring",
        status: "In Stock",
        image:
          "https://dizitalcard.in/chandan-jewellers/e4562e8a-c8f4-4eec-9d9b-dc09df7e5e04.webp",
          whatsappLink:"https://api.whatsapp.com/send/?phone=917415164000&text=Query+about+Your+Gold Ring&type=phone_number&app_absent=0",
        },
      {
        id: 5,
        name: "Gold Ring",
        status: "In Stock",
        image:
          "https://dizitalcard.in/chandan-jewellers/501245bf-5575-441d-a2e0-252ee1931141.webp",
          whatsappLink:"https://api.whatsapp.com/send/?phone=917415164000&text=Query+about+Your+Gold Ring&type=phone_number&app_absent=0",
      },
    ],
    Product9:[
      
      {
        id: 1,
        name: "Silver Bichiye",
        status: "In Stock",
        image:
          "https://dizitalcard.in/chandan-jewellers/f1689fb1-c754-498c-9d18-f0fe4eb7187b.webp",
          whatsappLink:"https://api.whatsapp.com/send/?phone=917415164000&text=Query+about+Your+Silver Bhichiye&type=phone_number&app_absent=0",
      },
      {
        id: 2,
        name: "Silver Bichiye",
        status: "In Stock",
        image:
          "https://dizitalcard.in/chandan-jewellers/484765a6-51e2-4fa2-ba40-53d02ef9a905.webp",
          whatsappLink:"https://api.whatsapp.com/send/?phone=917415164000&text=Query+about+Your+Silver Bhichiye&type=phone_number&app_absent=0",
      },
    
      {
        id: 3,
        name: "Silver Bichiye",
        status: "In Stock",
        image:
          "https://dizitalcard.in/chandan-jewellers/330b8565-107c-48cb-8fcb-19f9f5e2f0d8.webp",
          whatsappLink:"https://api.whatsapp.com/send/?phone=917415164000&text=Query+about+Your+Silver Bhichiye&type=phone_number&app_absent=0",
      },
      {
        id: 4,
        name: "Silver Bichiye",
        status: "In Stock",
        image:
          "https://dizitalcard.in/chandan-jewellers/ac6ad58c-44b4-49cc-a731-2962c81e065a.webp",
          whatsappLink:"https://api.whatsapp.com/send/?phone=917415164000&text=Query+about+Your+Silver Bhichiye&type=phone_number&app_absent=0",
      },
    
      {
        id: 6,
        name: "Silver Bichiye",
        status: "In Stock",
        image:
          "https://dizitalcard.in/chandan-jewellers/48368872-5055-4ec2-b273-b567a784d7fa.webp",
          whatsappLink:"https://api.whatsapp.com/send/?phone=917415164000&text=Query+about+Your+Silver Bhichiye&type=phone_number&app_absent=0",
      },
    ],
    Product10:[
      {
        id: 1,
        name: "Silver Payal",
        status: "In Stock",
        image:
          "https://dizitalcard.in/chandan-jewellers/30a549f7-926d-4436-be9c-1d0675b7fe42.webp",
          whatsappLink:"https://api.whatsapp.com/send/?phone=917415164000&text=Query+about+Your+Silver Payal&type=phone_number&app_absent=0",
      },
      {
        id: 2,
        name: "Silver Payal",
        status: "In Stock",
        image:
          "https://dizitalcard.in/chandan-jewellers/508f786d-e57c-4796-a129-3a62e791592d.webp",
          whatsappLink:"https://api.whatsapp.com/send/?phone=917415164000&text=Query+about+Your+Silver Payal&type=phone_number&app_absent=0",
      },
    
      {
        id: 3,
        name: "Silver Payal",
        status: "In Stock",
        image:
          "https://dizitalcard.in/chandan-jewellers/ee0af60f-6f3a-4c97-8920-340a133aedfd.webp",
          whatsappLink:"https://api.whatsapp.com/send/?phone=917415164000&text=Query+about+Your+Silver Payal&type=phone_number&app_absent=0",
      },
      {
        id: 4,
        name: "Silver Payal",
        status: "In Stock",
        image:
          "https://dizitalcard.in/chandan-jewellers/8b826cd6-77fc-40ec-9ab1-57178eda716c.webp",
          whatsappLink:"https://api.whatsapp.com/send/?phone=917415164000&text=Query+about+Your+Silver Payal&type=phone_number&app_absent=0",
      },
      {
        id: 5,
        name: "Silver Payal",
        status: "In Stock",
        image:
          "https://dizitalcard.in/chandan-jewellers/d39d9b91-d603-4916-b60b-79d613d29b35.webp",
          whatsappLink:"https://api.whatsapp.com/send/?phone=917415164000&text=Query+about+Your+Silver Payal&type=phone_number&app_absent=0",
      },
    ],
    Product11 :[
      {
        id: 1,
        name: "SilverRings",
        status: "In Stock",
        image:
          "https://dizitalcard.in/chandan-jewellers/c8962b0b-5cfa-4eae-9384-e9b54823392b.webp",
          whatsappLink:"https://api.whatsapp.com/send/?phone=917415164000&text=Query+about+Your+SilverRings&type=phone_number&app_absent=0",
      },
      {
        id: 2,
        name: "SilverRings",
        status: "In Stock",
        image:
          "https://dizitalcard.in/chandan-jewellers/1454a8eb-534e-49e8-9d9b-70173e97cf73.webp",
          whatsappLink:"https://api.whatsapp.com/send/?phone=917415164000&text=Query+about+Your+SilverRings&type=phone_number&app_absent=0",
      },
    
      {
        id: 3,
        name: "SilverRings",
        status: "In Stock",
        image:
          "https://dizitalcard.in/chandan-jewellers/a03bfdf9-f573-4e43-89ce-b3af6effe1d5.webp",
          whatsappLink:"https://api.whatsapp.com/send/?phone=917415164000&text=Query+about+Your+SilverRings&type=phone_number&app_absent=0",
      },
      {
        id: 4,
        name: "SilverRings",
        status: "In Stock",
        image:
          "https://dizitalcard.in/chandan-jewellers/c03810b2-4ac9-4008-853e-f877de96c6a4.webp",
          whatsappLink:"https://api.whatsapp.com/send/?phone=917415164000&text=Query+about+Your+SilverRings&type=phone_number&app_absent=0",
      },
      {
        id: 5,
        name: "SilverRings",
        status: "In Stock",
        image:
          "https://dizitalcard.in/chandan-jewellers/1cbb4b09-a72b-46b8-81d4-9bf0318fee54.webp",
          whatsappLink:"https://api.whatsapp.com/send/?phone=917415164000&text=Query+about+Your+SilverRings&type=phone_number&app_absent=0",
      },
    ],
    
    categories: [
      {
        id: 1,
        name: "All Types",
      },
      {
        id: 2,
        name: "Gems & Stone",
      },
      {
        id: 3,
        name: "Diamond Necklace",
      },
      {
        id: 4,
        name: "Diamond Rings",
      },
      {
        id: 5,
        name: "Diamond Ear-Rings",
      },
      {
        id: 6,
        name: "Gold Necklace",
      },
      {
        id: 7,
        name: "Gold Bangles",
      },
      {
        id: 8,
        name: "Gold Ear-Rings",
      },
      {
        id: 9,
        name: "Gold Rings",
      },
      {
        id: 10,
        name: "Silver Bichiye",
      },
      {
        id: 11,
        name: "Silver Payal",
      },
      {
        id: 12,
        name: "Silver Rings",
      },
    ],
    whatsappLink:"https://api.whatsapp.com/send/?phone=917415164000&text=Query+about+Your+Digital+Card&type=phone_number&app_absent=0",
    bgImage:''
  },
  payment:{
    bankName:"PUNJAB NATIONAL BANK",
    name:'CHANDAN JEWELLERS',
    accountNumber:' 0291008700003505',
    ifscCode:'PUNB0029100',
    qrImage:QR
  },
  ourButton:{
    textColor:'#000',
  },
  review:{
    textColor:'#000',
  }
  
};
