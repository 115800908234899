import headerbg from "../../assets/headerbg (7).png";
import logo from "./assets/gehnalogo.jpg";
import contactCs from "./assets/paras.vcf";
import QR from "../../assets/download.webp";
import bgImage from "../../assets/back.webp";
import cpvcsdr from '../TruFlow/assets/2.png'
import cpvcfitting1 from '../TruFlow/assets/4.png'
import cpvcfitting2 from '../TruFlow/assets/5.png'
import cpvcfitting3 from '../TruFlow/assets/6.png'
import cpvcfitting4 from '../TruFlow/assets/7.png'
import cpvcfitting5 from '../TruFlow/assets/8.png'
import cpvcfitting6 from '../TruFlow/assets/9.png'
import cpvcfitting7 from '../TruFlow/assets/10.png'
import cpvcfittingbrass1 from '../TruFlow/assets/12.png'
import cpvcfittingbrass2 from '../TruFlow/assets/14.png'
import cpvcfittingbrass3 from '../TruFlow/assets/13.png'
import cpvcfittingbrass4 from '../TruFlow/assets/15.png'
import solvent from '../TruFlow/assets/17.png'
import upvcpipes from '../TruFlow/assets/19.png'
import upvcfitting1 from '../TruFlow/assets/21.png'
import upvcfitting2 from '../TruFlow/assets/22.png'
import upvcfitting3 from '../TruFlow/assets/23.png'
import upvcfitting4 from '../TruFlow/assets/24.png'
import upvcfitting5 from '../TruFlow/assets/25.png'
import upvcfitting6 from '../TruFlow/assets/26.png'
import upvcfitting7 from '../TruFlow/assets/27.png'
import swr1 from '../TruFlow/assets/29.png'
import swr2 from '../TruFlow/assets/30.png'
import swrFittings1 from '../TruFlow/assets/32.png'
import swrFittings2 from '../TruFlow/assets/33.png'
import swrFittings3 from '../TruFlow/assets/34.png'
import pvcPressure1 from '../TruFlow/assets/36.png'
import pvcPressure2 from '../TruFlow/assets/37.png'
import pvcFitting1 from '../TruFlow/assets/39.png'
import pvcFitting2 from '../TruFlow/assets/40.png'
import pvcFitting3 from '../TruFlow/assets/42.png'
import pvcFitting4 from '../TruFlow/assets/41.png'
import bg1 from '../TruFlow/assets/bg1.png'
import bg2 from '../TruFlow/assets/bg2.png'
import bg3 from '../TruFlow/assets/bg3.png'
import bg4 from '../TruFlow/assets/bg4.png'

import Gehna from './assets/gehna.mp4'
import Gehna2 from './assets/gehna2.mp4'
export const TruFlowContent= {
    hero: {
        headerImage: headerbg,
        logoImage: logo,
        name: "Parsavnath Traders",
        phoneNumber: "+917987408253",
        phoneNumber1:"+918435015227",
        phoneNumber2: "",
        phoneNumber3: "",
        whatsappLink:
          "https://api.whatsapp.com/send/?phone=919425310099&text=Message+from+Your+Digital+Card&type=phone_number&app_absent=0",
        mailId: "PTRADERS23@gmail.com",
        googleMapLink: "https://goo.gl/maps/aeghBtAJ9R9KVJvB8",
        pageHref: "http://www.parsavnathtraders.com/",
        fbLink:
          "https://www.facebook.com/sharer/sharer.php?u=https://indiabuzz.co.in/parsavnathtraders",
        twitterLink:
          "https://twitter.com/intent/tweet/?url=https://indiabuzz.co.in/parsavnathtraders",
        linkdnLink:
          "https://www.linkedin.com/shareArticle/?mini=true&url=https://indiabuzz.co.in/parsavnathtraders",
        instaLink:
          "https://www.instagram.com/share?url=https://indiabuzz.co.in/parsavnathtraders",
        pinterestLink:
          "https://www.pinterest.com/pin/create/button/?url=https://indiabuzz.co.in/parsavnathtraders",
        telegramLink:
          "https://t.me/share/url?url=https://indiabuzz.co.in/parsavnathtraders",
        smsBody: "https://indiabuzz.co.in/parsavnathtraders",
        title: "Gehna Jeweller",
        titleLink: "https://indiabuzz.co.in/parsavnathtraders",
        customWhatsappText: "https://indiabuzz.co.in/parsavnathtraders",
        address1: "Infront of Mahaveer Transport",
        address2: "Parking No.3,Transport Nagar Gwalior 474001",
        address3: "  Madhya Pradesh",
        contactFile: contactCs,
        textColor:'white',
    
        Product1: [
          {
            id: 1,
           
            image:bg1,
          },
         
          {
            id: 2,
            image:bg2,
          },
          {
            id: 3,
            image:bg3,
          },
          {
            id: 4,
            image:bg4,
          },
        
          
        ],
        buttonColor: "#EEE8AA",
    
        buttonContentColor: "#000",
      },
      about: {
        aboutContent:
          " Parshvnaath Traders' commitment to customer satisfaction and quality has earned them a strong reputation in the market, making them a preferred choice for customers looking for reliable and efficient water connection solutions. With a focus on innovation and technology, TruFlow and Hindware continue to develop new products and solutions that meet the evolving needs of their customers, providing Parshvnaath Traders with a continuous supply of cutting-edge products and technologies.",
        quality1: "CPVC",
        quality2: "UPVC",
        quality3: "SWR",
        quality4: "PVC",
        mondayOpeningHours: "11:00 AM - 09:00 PM",
        tuesdayOpeningHours: "11:00 AM - 07:00 PM",
        wednesdayOpeningHours: "11:00 AM - 09:00 PM",
        thursdayOpeningHours: "11:00 AM - 09:00 PM",
        fridayOpeningHours: "11:00 AM - 09:00 PM",
        saturdayOpeningHours: "11:00 AM - 09:00 PM",
        sundayOpeningHours: "11:00 AM - 09:00 PM",
        buttonColor: "#EEE8AA",
        buttonContentColor: "#000",
        textColor:'white',
      },
      product: {
        buttonColor: "lightsky",
        buttonContentColor: "white",
        category1:'CPVC SDR 11 / 13.5 Pipes',
        category2:'CPVC Fittings',
        category3:'CPVC Fittings - Brass',
        category4:'CPVC SOLVENT CEMENT & PRIMER',
        category5:'UPVC PIPES – SCH 40 / SCH 80 PIPES',
        category6:'SCH 80 FITTINGS',
        category7:'SWR SELEFIT PIPES',
        category8:'SWR SELEFIT FITTINGS',
        category9:'PVC Pressure Pipes',
        category10:'PVC FITTINGS',
        Product1: [ {
          id: 1,
          name: "SDR - 11 (3 Mtr. Length)",
          status: "In Stock",
          image:cpvcsdr,
          whatsappLink:
          `https://api.whatsapp.com/send/?phone=917987408253&text=Query+about+SDR - 11 (3 Mtr. Length)&type=phone_number&app_absent=0`,
        },
        {
          id: 2,
          name: "SDR - 11 (5 Mtr. Length)",
          status: "In Stock",
          image:cpvcsdr,
          whatsappLink:
          `https://api.whatsapp.com/send/?phone=917987408253&text=Query+about+SDR - 11 (5 Mtr. Length)&type=phone_number&app_absent=0`,
        },
        {
          id: 3,
          name: "SDR - 13.5 (3 Mtr. Length)",
          status: "In Stock",
          image:cpvcsdr,
          whatsappLink:
          `https://api.whatsapp.com/send/?phone=917987408253&text=Query+about+SDR - 13.5 (3 Mtr. Length)&type=phone_number&app_absent=0`,
        },
        {
          id: 4,
          name: "SDR - 13.5 (5 Mtr. Length)",
          status: "In Stock",
          image:cpvcsdr,
          whatsappLink:
          `https://api.whatsapp.com/send/?phone=917987408253&text=Query+about+SDR - 13.5 (5 Mtr. Length)&type=phone_number&app_absent=0`,
        },],
        Product2: [
          {
            id: 1,
            name: "Coupler Plain",
            status: "In Stock",
            image:cpvcfitting1,
            whatsappLink:
            `https://api.whatsapp.com/send/?phone=917987408253&text=Query+about+Coupler Plain&type=phone_number&app_absent=0`,
          },
          {
            id: 2,
            name: "Bend 45° Plain",
            status: "In Stock",
            image:cpvcfitting2,
            whatsappLink:
            `https://api.whatsapp.com/send/?phone=917987408253&text=Query+about+Bend 45° Plain&type=phone_number&app_absent=0`,
          },
          {
            id: 3,
            name: "Tee 90⁰ Plain",
            status: "In Stock",
            image:cpvcfitting3,
            whatsappLink:
            `https://api.whatsapp.com/send/?phone=917987408253&text=Query+about+Tee 90⁰ Plain&type=phone_number&app_absent=0`,
          },
          {
            id: 4,
            name: "Elbow 90° Plain",
            status: "In Stock",
            image:cpvcfitting4,
            whatsappLink:
            `https://api.whatsapp.com/send/?phone=917987408253&text=Query+about+Elbow 90° Plain&type=phone_number&app_absent=0`,
          },
          {
            id: 5,
            name: "Union",
            status: "In Stock",
            image:cpvcfitting5,
            whatsappLink:
            `https://api.whatsapp.com/send/?phone=917987408253&text=Query+about+Union&type=phone_number&app_absent=0`,
          },
          {
            id: 6,
            name: "Tank Nipple",
            status: "In Stock",
            image:cpvcfitting6,
            whatsappLink:
            `https://api.whatsapp.com/send/?phone=917987408253&text=Query+about+Tank Nipple&type=phone_number&app_absent=0`,
          },
          {
            id: 7,
            name: "Ball Valve",
            status: "In Stock",
            image:cpvcfitting7,
            whatsappLink:
            `https://api.whatsapp.com/send/?phone=917987408253&text=Query+about+Ball Valve&type=phone_number&app_absent=0`,
            
          },
    
        ],
        Product3: [
        
          {
            id: 1,
            name: "Elbow 90°",
            status: "In Stock",
            image:cpvcfittingbrass1,
            whatsappLink:
            `https://api.whatsapp.com/send/?phone=917987408253&text=Query+about+Elbow 90°&type=phone_number&app_absent=0`,
          },
          {
            id: 2,
            name: "Tee",
            status: "In Stock",
            image:cpvcfittingbrass2,
            whatsappLink:
            `https://api.whatsapp.com/send/?phone=917987408253&text=Query+about+Tee&type=phone_number&app_absent=0`,
          },
          {
            id: 3,
            name: "Male Adaptor Brass Threaded (MABT)",
            status: "In Stock",
            image:cpvcfittingbrass3,
            whatsappLink:
            `https://api.whatsapp.com/send/?phone=917987408253&text=Query+about+Male Adaptor Brass Threaded (MABT)&type=phone_number&app_absent=0`,
            
          },
          {
            id: 4,
            name: "Female Adaptor Brass Threaded (FABT)",
            status: "In Stock",
            image:cpvcfittingbrass4,
            whatsappLink:
            `https://api.whatsapp.com/send/?phone=917987408253&text=Query+about+Female Adaptor Brass Threaded (FABT)&type=phone_number&app_absent=0`,
          },
    
        ],
        Product4: [
          
          {
            id: 1,
            name: "Medium Duty 1 Step (Yellow)",
            status: "In Stock",
            image:solvent,
            whatsappLink:
            `https://api.whatsapp.com/send/?phone=917987408253&text=Query+about+Medium Duty 1 Step (Yellow)&type=phone_number&app_absent=0`,
          },
          {
            id: 2,
            name: "Medium Duty 1 Step (Yellow)",
            status: "In Stock",
            image:solvent,
            whatsappLink:
            `https://api.whatsapp.com/send/?phone=917987408253&text=Query+about+Medium Duty 1 Step (Yellow) &type=phone_number&app_absent=0`,
          },
          {
            id: 3,
            name: "Medium Duty 1 Step (Yellow)",
            status: "In Stock",
            image:solvent,
            whatsappLink:
            `https://api.whatsapp.com/send/?phone=917987408253&text=Query+about+Medium Duty 1 Step (Yellow) &type=phone_number&app_absent=0`,
          },
          {
            id: 4,
            name: "Medium Duty 1 Step (Yellow)",
            status: "In Stock",
            image:solvent,
            whatsappLink:
            `https://api.whatsapp.com/send/?phone=917987408253&text=Query+about+Medium Duty 1 Step (Yellow) &type=phone_number&app_absent=0`,
          },
        ],
    
       
        Product5: [
          {
            id: 1,
            name: "SCH-40 (3Mtr. Length) ",
            status: "In Stock",
            image:upvcpipes,
            whatsappLink:
            `https://api.whatsapp.com/send/?phone=917987408253&text=Query+about+SCH-40 (3Mtr. Length) &type=phone_number&app_absent=0`,
          },
          {
            id: 2,
            name: "SCH-40 (6Mtr. Length) ",
            status: "In Stock",
            image:upvcpipes,
            whatsappLink:
            `https://api.whatsapp.com/send/?phone=917987408253&text=Query+about+SCH-40 (6Mtr. Length)&type=phone_number&app_absent=0`,
          },
          {
            id: 3,
            name: "SCH-40 (6Mtr. Length) ",
            status: "In Stock",
            image:upvcpipes,
            whatsappLink:
            `https://api.whatsapp.com/send/?phone=917987408253&text=Query+about+SCH-40 (6Mtr. Length)&type=phone_number&app_absent=0`,
          },
          {
            id: 4,
            name: "SCH-80 (6Mtr. Length)",
            status: "In Stock",
            image:upvcpipes, 
            whatsappLink:
            `https://api.whatsapp.com/send/?phone=917987408253&text=Query+about+SCH-80 (6Mtr. Length)&type=phone_number&app_absent=0`,
             },
       
        ],
        Product6: [
          {
            id: 1,
            name: "Elbow 90° Plain",
            status: "In Stock",
            image:upvcfitting1,
            whatsappLink:
            `https://api.whatsapp.com/send/?phone=917987408253&text=Query+about+Elbow 90° Plain&type=phone_number&app_absent=0`,      },
          {
            id: 2,
            name: "Bend 45° Plain",
            status: "In Stock",
            image:upvcfitting2,
            whatsappLink:
            `https://api.whatsapp.com/send/?phone=917987408253&text=Query+about+Bend 45° Plain&type=phone_number&app_absent=0`,
          },
          {
            id: 3,
            name: "Tee 90° Plain",
            status: "In Stock",
            image:upvcfitting3,
            whatsappLink:
            `https://api.whatsapp.com/send/?phone=917987408253&text=Query+about+Tee 90° Plain&type=phone_number&app_absent=0`,
          },
          {
            id: 4,
            name: "Male Thread Adaptor (Brass) - MABT",
            status: "In Stock",
            image:upvcfitting4,
            whatsappLink:
            `https://api.whatsapp.com/send/?phone=917987408253&text=Query+about+Male Thread Adaptor (Brass) - MABT&type=phone_number&app_absent=0`,
          },
          {
            id: 5,
            name: "Female Thread Adaptor (Brass) - FABT",
            status: "In Stock",
            image:upvcfitting5,
            whatsappLink:
            `https://api.whatsapp.com/send/?phone=917987408253&text=Query+about+Female Thread Adaptor (Brass) - FABT&type=phone_number&app_absent=0`,
          },
          {
            id: 6,
            name: "Union",
            status: "In Stock",
            image:upvcfitting6,
            whatsappLink:
            `https://api.whatsapp.com/send/?phone=917987408253&text=Query+about+Union&type=phone_number&app_absent=0`,
          },
          {
            id: 7,
            name: "Ball Valve",
            status: "In Stock",
            image:upvcfitting7,
            whatsappLink:
            `https://api.whatsapp.com/send/?phone=917987408253&text=Query+about+Ball Valve&type=phone_number&app_absent=0`,
          },
        ],
        Product7: [
         
          {
            id: 1,
            name: "SWR SELEFIT PIPES TYPE A(3 Mtr)",
            status: "In Stock",
            image:swr1,
            whatsappLink:
            `https://api.whatsapp.com/send/?phone=917987408253&text=Query+about+SWR SELEFIT PIPES TYPE A(3 Mtr)&type=phone_number&app_absent=0`,
          },
          {
            id: 2,
            name: "SWR SELEFIT PIPES TYPE A(6 Mtr)",
            status: "In Stock",
            image:swr2,
            whatsappLink:
            `https://api.whatsapp.com/send/?phone=917987408253&text=Query+about+Your+SWR SELEFIT PIPES TYPE A(6 Mtr)&type=phone_number&app_absent=0`,
          },
          {
            id: 1,
            name: "SWR SELEFIT PIPES TYPE A(3 Mtr)",
            status: "In Stock",
            image:swr1,
            whatsappLink:
            `https://api.whatsapp.com/send/?phone=917987408253&text=Query+about+Your+SWR SELEFIT PIPES TYPE A(3 Mtr)&type=phone_number&app_absent=0`,
          },
          {
            id: 2,
            name: "SWR SELEFIT PIPES TYPE A(6 Mtr)",
            status: "In Stock",
            image:swr2,
            whatsappLink:
            `https://api.whatsapp.com/send/?phone=917987408253&text=Query+about+Your+SWR SELEFIT PIPES TYPE A(6 Mtr)&type=phone_number&app_absent=0`,
          },
        ],
        Product8: [
          {
            id: 1,
            name: "Bend 45 Degree",
            status: "In Stock",
            image:swrFittings1,
            whatsappLink:
            `https://api.whatsapp.com/send/?phone=917987408253&text=Query+about+Your+Bend 45 Degree&type=phone_number&app_absent=0`,
          },
          {
            id: 2,
            name: "Bend 87.5 Degree",
            status: "In Stock",
            image:swrFittings2,
            whatsappLink:
            `https://api.whatsapp.com/send/?phone=917987408253&text=Query+about+Your+Bend 87.5 Degree&type=phone_number&app_absent=0`,
          },
          {
            id: 3,
            name: "Coupler",
            status: "In Stock",
            image:swrFittings3,
            whatsappLink:
            `https://api.whatsapp.com/send/?phone=917987408253&text=Query+about+Your+Coupler&type=phone_number&app_absent=0`,
          },
        ],
        Product9: [
          {
            id: 1,
            name: "4 Kgf/cm² Pressure Pipes (6Mtr.Lengh) (10Ft)",
            status: "In Stock",
            image:pvcPressure1,
            whatsappLink:
            `https://api.whatsapp.com/send/?phone=917987408253&text=Query+about+Your+4 Kgf/cm² Pressure Pipes (6Mtr.Lengh) (10Ft)&type=phone_number&app_absent=0`,
          },
          {
            id: 2,
            name: "6 kgf/cm (6 Mtr. Length) 20 feet",
            status: "In Stock",
            image:pvcPressure2,
            whatsappLink:
            `https://api.whatsapp.com/send/?phone=917987408253&text=Query+about+Your+6 kgf/cm (6 Mtr. Length) 20 feet&type=phone_number&app_absent=0`,
          },
          {
            id: 1,
            name: "4 Kgf/cm² Pressure Pipes (6Mtr.Lengh) (10Ft)",
            status: "In Stock",
            image:pvcPressure1,
            whatsappLink:
            `https://api.whatsapp.com/send/?phone=917987408253&text=Query+about+Your+4 Kgf/cm² Pressure Pipes (6Mtr.Lengh) (10Ft)&type=phone_number&app_absent=0`,
          },
          {
            id: 2,
            name: "6 kgf/cm (6 Mtr. Length) 20 feet",
            status: "In Stock",
            image:pvcPressure2,
            whatsappLink:
            `https://api.whatsapp.com/send/?phone=917987408253&text=Query+about+Your+6 kgf/cm (6 Mtr. Length) 20 feet&type=phone_number&app_absent=0`,
          },
    
         
    
          
        ],
        Product10: [
          {
            id: 1,
            name: "End Cap Plain- TRUFLO LW",
            status: "In Stock",
            image:pvcFitting1,
            whatsappLink:
            `https://api.whatsapp.com/send/?phone=917987408253&text=Query+about+Your+End Cap Plain- TRUFLO LW&type=phone_number&app_absent=0`,
          },
          {
            id: 2,
            name: "Elbow 90° Plain - TRUFLO ECO",
            status: "In Stock",
            image:pvcFitting2,
            whatsappLink:
            `https://api.whatsapp.com/send/?phone=917987408253&text=Query+about+Your+Elbow 90° Plain - TRUFLO ECO&type=phone_number&app_absent=0`,
          },
    
          {
            id: 3,
            name: "Reducer - Heavy ",
            status: "In Stock",
            image:pvcFitting3,
            whatsappLink:
            `https://api.whatsapp.com/send/?phone=917987408253&text=Query+about+Your+Reducer- Heavy&type=phone_number&app_absent=0`,
          },
          {
            id: 4,
            name: "Reducer Tee - Heavy ",
            status: "In Stock",
            image:pvcFitting4,
            whatsappLink:
            `https://api.whatsapp.com/send/?phone=917987408253&text=Query+about+Your+Reducer Tee - Heavy&type=phone_number&app_absent=0`,
          },
        ],
        Product11: [
         
        ],
    
        categories: [
          {
            id: 1,
            name: "All Types",
          },
          {
            id: 2,
            name: "Gems & Stone",
          },
          {
            id: 3,
            name: "Diamond Necklace",
          },
          {
            id: 4,
            name: "Diamond Rings",
          },
          {
            id: 5,
            name: "Diamond Ear-Rings",
          },
          {
            id: 6,
            name: "Gold Necklace",
          },
          {
            id: 7,
            name: "Gold Bangles",
          },
          {
            id: 8,
            name: "Gold Ear-Rings",
          },
          {
            id: 9,
            name: "Gold Rings",
          },
          {
            id: 10,
            name: "Silver Bichiye",
          },
          {
            id: 11,
            name: "Silver Payal",
          },
          {
            id: 12,
            name: "Silver Rings",
          },
        ],
        whatsappLink:
          "https://api.whatsapp.com/send/?phone=917987408253&text=Message+from+Your+Digital+Card&type=phone_number&app_absent=0",
        bgImage: "",
        textColor:'white',
      },
      payment: {
       
      },
      ourButton:{
        textColor:'white',
      },
      review:{
        textColor:'white',
      }
};
