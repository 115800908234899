import { Box, Container, Grid, Button, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import Navbar from "../Sections/Navbar";
import Footer from "../Sections/Footer";

const websites = [
	{
		avatar: require("../../assets/template1.png"),
	},
	{
		avatar: require("../../assets/template2.png"),
	},
	{
		avatar: require("../../assets/template3.png"),
	},
	{
		avatar: require("../../assets/template4.png"),
	},
	{
		avatar: require("../../assets/template5.png"),
	},
	{
		avatar: require("../../assets/template6.png"),
	},
	{
		avatar: require("../../assets/template7.png"),
	},
	{
		avatar: require("../../assets/template8.png"),
	},

	{
		avatar: require("../../assets/template10.png"),
	},
];

const logos = [
	{
		avatar: require("../../assets/logotemplate1.png"),
	},
	{
		avatar: require("../../assets/logotemplate2.png"),
	},

	{
		avatar: require("../../assets/logotemplate4.png"),
	},
];

export default function Portfolio() {

	const theme = useTheme();
	const mobile = useMediaQuery(theme.breakpoints.down("sm"));
	const ipad = useMediaQuery(theme.breakpoints.down("md"));

	const WebsiteTemplate = () => {
		return websites.map((u, i) => {
			return (
				<Grid item xs={12} md={6}>
					<img src={u.avatar} width={mobile ? "100%" : "80%"} />
				</Grid>
			);
		});
	};

	const LogoTemplate = () => {
		return logos.map((u, i) => {
			return (
				<Grid item xs={12} md={6}>
					<img src={u.avatar} width={mobile ? "100%" : "80%"} />
				</Grid>
			);
		});
	};

	return (
		<Box sx={{ overflowX: "hidden", background: "#ffffff" }}>
			<Navbar />
			<br />
			<br />
			<br />
			<br />
			<br />
			<Container maxWidth="xl">
				<Grid>
					<Grid container spacing={2}>
						<Grid item xs={12} md={12}>
							<Navbar />
						</Grid>
					</Grid>
					<Grid
						style={{
							display: "flex",
							fontSize: 29,
							color: "#ffffff",
							fontWeight: "bold",
							width: "100%",
						}}
					>
						<Grid
							sx={{
								display: "flex",
								justifyContent: "center",
								textAlign: "center",
								alignItems: "center",
								color:"#053F76",
								width: "100%",
								fontFamily: "Montserrat",
								fontWeight: 900,
								fontSize: 40,
								mt: mobile ? 1 : ipad ? 2 : 5
							}}
						>
							Portfolio Grid
						</Grid>{" "}
					</Grid>
					<hr style={{ marginLeft: mobile ? 12 : ipad ? 21 : 40, width: mobile ? "93%" : "94%" }} />
					<Grid container spacing={2} sx={{ padding: mobile ? "20px 10px" : "20px 50px", mt: mobile ? 4 : ipad ? 6 : 10, display: 'flex', justifyContent: "center", alignItems: "start", ml: mobile ? -2 : ipad ? -1.6 : 4, mr: mobile ? 0 : 4 }}>
						<WebsiteTemplate />
						<LogoTemplate />
					</Grid>
				</Grid>
			</Container>
			<br />
			<hr style={{ marginLeft: mobile ? 12 : ipad ? 21 : 40, width: mobile ? "93%" : "94%" }} />
			<br /><br />
			<br />
			<Footer />
		</Box>
	);
}
