import {
    Box,
    Container,
    Grid,
    TextField,
    Button,
    Typography,
    useMediaQuery,
    useTheme,
    Divider,
} from "@mui/material";
import React from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import ServicesBar from "./ServicesBar";

// Laptop View Image
import bgImg from "../../Vault/smo_bg.jpg";

// Tablet View Image
import TabletBG from "../../Vault/smo_tablet_bg.jpg";

// MOBILE View Image
import MobileBG from "../../Vault/smo+mobile_bg.jpg";

import TopNotch from "../../Vault/Top-Notch Social Media Marketing.gif";
import Distinctive from "../../Vault/Distinctive Social Media Services.gif";
import Expert from "../../Vault/Expert Heading.gif";
// import TopRank from "../../Vault/Top Rankings SEO.gif";
import { postData } from "../../../ApiServices/ServerServices";
import Swal from "sweetalert2";
//  ACHIVE SECTION IMAGES
import Img1 from "../../Vault/Fair Price.png";
import Img2 from "../../Vault/Real-Time Data.png";
import Img3 from "../../Vault/Latest Tools.png";
import Img4 from "../../Vault/SEO Incorporated.png";
import Img5 from "../../Vault/Social Integration.png";
import Img6 from "../../Vault/Team Of Experts.png";
import Img7 from "../../Vault/Retina Graphics.png";

//  TOP RANKING SECTION IMAGES
import IMAGE1 from "../../Vault/Market Research Functions.png";
import IMAGE2 from "../../Vault/Panel Of Experts.png";
import IMAGE3 from "../../Vault/Constant Social Media Audit.png";
import IMAGE4 from "../../Vault/Fair Price.png";
import IMAGE5 from "../../Vault/Twenty.png";
import IMAGE6 from "../../Vault/Better Strategries.png";
//socket
import { io } from 'socket.io-client'
export default function SMOService() {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));
    const iPad = useMediaQuery(theme.breakpoints.down(1200));
    const [service, setService] = React.useState("");
    const [name, setName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [number, setNumber] = React.useState("");
    const [companyName, setCompanyName] = React.useState("");
    const [socket,setSocket]=React.useState()

        React.useEffect(() => {
                const socket = io("https://api4.buzz2web.in/");
                setSocket(socket);
              
                socket.on("connect", () => {
                  console.log(socket.id);
                  socket.emit("backend",'admin');
                });
              
        
              }, []);
   
        const handleClick = async () => {
                var formData = new FormData()


                formData.append('service', service)
                formData.append('name', name)
                formData.append('email', email)
                formData.append('number', number)
                formData.append('companyName', companyName)




                var result = await postData('enquiries/addenquiry', formData, true)
                console.log(result)
                if (result.status) {
                        socket.emit("newEntry",{Enquiry:'New Enquiry',room:'admin'})
        
        Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Enquiry Has Been Send.We will contact You Soon',
        showConfirmButton: false,
        timer: 1500
      })}
    else
    {
        Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Fail to Send the Enquiry',
            showConfirmButton: false,
            timer: 1500
          })

    }



  }
    return (
        <div>
            <Box sx={{ overflowX: "hidden", background: "#F8F9FA" }}>
                <Navbar />
                <br />
                <br />
                <br />
                <br />
                <ServicesBar />
                {mobile ? (
                    <></>
                ) : (
                    <>
                        <br />
                        <br />
                    </>
                )}
                <Container maxWidth="xl">
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <Grid
                                sx={{
                                    width: mobile ? "109%" : "102%",
                                    padding: mobile ? 0 : "50px 30px",
                                    background: mobile
                                        ? `url('${MobileBG}')`
                                        : iPad
                                            ? `url('${TabletBG}')`
                                            : `url('${bgImg}')`,
                                    backgroundSize: "100% 100%",
                                    backgroundRepeat: "no-repeat",
                                    height: mobile ? 700 : iPad ? 1000 : "auto",
                                }}
                                container
                                spacing={2}
                            >
                                <Grid
                                    sx={{
                                        ml: mobile ? 0 : 4.8,
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                    item
                                    xs={12}
                                    lg={6}
                                >
                                    {mobile ? (
                                        <></>
                                    ) : (
                                        <>
                                            <br />
                                            <br />
                                        </>
                                    )}
                                    <Typography
                                        sx={{
                                            fontSize: mobile ? "24px" : iPad ? "36px" : "40px",
                                            fontWeight: 900,
                                            fontFamily: "Montserrat",
                                            color: "#053F76",
                                        }}
                                    >
                                        Social Media Optimization
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: mobile ? "12px" : iPad ? "16px" : "19px",
                                            fontFamily: "Montserrat",
                                            fontWeight: 500,
                                            marginTop: "15px",
                                            color: "#053F76",
                                        }}
                                    >
                                        {mobile
                                            ? "Boost your brand's online presence with our expert social media strategy. We excel in Facebook, Google Ads, influencers, and Instagram campaigns, driving results and positioning you for digital success. Maximize your impact now!"
                                            : "Supercharge your brand's online presence with our expert social media marketing strategy. Our team excels at leveraging the power of Facebook and Google Ads, influencer partnerships, and compelling Instagram campaigns. By boosting your brand recognition, we'll drive tangible results and position you for digital success. Let us enhance your image across various channels and maximize your impact in the digital market."}
                                    </Typography>
                                    <Button
                                        sx={{
                                            mt: 3,
                                            backgroundColor: "#053F76",
                                            fontFamily: "Montserrat",
                                            width: mobile ? "50%" : iPad ? "60%" : "40%",
                                            padding: iPad ? "5px 5px" : "8px 10px",
                                            fontSize: mobile ? 10 : iPad ? 16 : 18,
                                            color: "#ffffff",
                                            fontWeight: 600,
                                            "&:hover": {
                                                background: "#053F76",
                                                color: "#9cbccb",
                                            },
                                        }}
                                        href="https://wa.link/clvo86"
                                        target="_blank"
                                    >
                                        GET CONSULTATION
                                    </Button>
                                </Grid>
                                <Grid item xs={12} md={8} lg={4}>
                  <Grid
                    sx={{
                      width: mobile ? "96%" : iPad ? "80%" : "100%",
                      background: "#ffffff",
                      borderRadius: 4,
                      ml: mobile ? 0 : iPad ? "32px" : 10,
                      padding: "3px 10px 3px 7px",
                      paddingRight: 2,
                    }}
                    container
                    spacing={1}
                  >
                    <Grid
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      item
                      xs={12}
                    >
                      <Typography
                        sx={{
                          fontSize: iPad ? "20px" : "30px",
                          fontWeight: 600,
                          fontFamily: "Montserrat",
                          color: "#053F76",
                        }}
                      >
                        Enquiry Form
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField value={name} onChange={(e)=>setName(e.target.value)} fullWidth label="Enter Your Full Name" />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField fullWidth value={companyName} onChange={(e)=>setCompanyName(e.target.value)} label="Enter Your Company Name" />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField fullWidth value={email} onChange={(e)=>setEmail(e.target.value)}  label="Email" />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField fullWidth value={number} onChange={(e)=>setNumber(e.target.value)}  label="Phone Number" />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth sx={{ fontFamily: "Montserrat" }}>
                        <InputLabel id="demo-simple-select-label">
                          What Service do you want ?
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="What Service do you want ?"
                          value={service}
                          onChange={(e)=>setService(e.target.value)}
                        >
                          <MenuItem value={"MVP Development"}>
                            MVP Development
                          </MenuItem>
                          <MenuItem value={"Static Website Development"}>
                            Static Website Development
                          </MenuItem>
                          <MenuItem value={"Dynamic Website Development"}>
                            Dynamic Website Development
                          </MenuItem>
                          <MenuItem value={"Website Design"}>
                            Website Design
                          </MenuItem>
                          <MenuItem value={"Digital Marketing"}>
                            Digital Marketing
                          </MenuItem>
                          <MenuItem value={"App Development"}>
                            App Development
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      item
                      xs={12}
                    >
                      <Button
                        sx={{
                          marginTop: "16px",
                          marginBottom: "22px",
                          backgroundColor: "#9cbccb",
                          fontFamily: "Montserrat",
                          padding: mobile ? "8px 40px" : "8px 40px",
                          fontSize: mobile ? 14 : 18,
                          mr: 2,
                          color: "#053F76",
                          fontWeight: "bold",
                          "&:hover": {
                            background: "#053F76",
                            color: "#9cbccb",
                          },
                        }}
                        onClick={()=>handleClick()}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            sx={{
                                display: "flex",
                                flexDirection: iPad ? "column" : "row",
                                margin: mobile ? "0px" : "15px 50px 5px",
                                mt: mobile ? 0 : 8,
                            }}
                            item
                            xs={12}
                        >
                            <img
                                src={TopNotch}
                                width={450}
                                style={{
                                    margin: "0px 10px",
                                    display: mobile ? "none" : iPad ? "none" : "block",
                                }}
                            />
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    mt: 10,
                                    ml: iPad ? 0 : 5,
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontFamily: "Montserrat",
                                        fontWeight: 900,
                                        fontSize: mobile ? 24 : iPad ? 36 : 40,
                                        color: "#053F76",
                                        mb: 1,
                                    }}
                                >
                                    Top-Notch Social Media Marketing
                                </Typography>
                                <Divider
                                    sx={{ width: "100%", backgroundColor: "#9cbccb", height: 2 }}
                                />
                                <br />
                                <img
                                    src={TopNotch}
                                    width={mobile ? 250 : 450}
                                    style={{
                                        margin: "auto",
                                        display: mobile ? "block" : iPad ? "block" : "none",
                                    }}
                                />
                                <Typography
                                    sx={{
                                        fontFamily: "Montserrat",
                                        fontWeight: 500,
                                        fontSize: mobile ? 12 : 17,
                                        color: "grey",
                                        textAlign: "justify",
                                        mt: 2,
                                    }}
                                >
                                    Discover IBuzz Tech Solution, a leading social media marketing
                                    agency in Gwalior. Our expert team fuels your business's
                                    growth on social platforms, driving traffic and boosting
                                    conversions. We target the right audience, captivating and
                                    converting them into loyal customers. Amplify sales, expand
                                    your brand's reach, and build a strong online presence with
                                    our effective strategies. Trust us for a proven and reliable
                                    social media marketing solution.
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid
                            sx={{
                                display: "flex",
                                flexDirection: iPad ? "column" : "row",
                                margin: mobile ? "0px" : "15px 50px 10px",
                            }}
                            item
                            xs={12}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    mt: 10,
                                    ml: iPad ? 0 : 5,
                                    width: iPad ? "100%" : "70%",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontFamily: "Montserrat",
                                        fontWeight: 900,
                                        fontSize: mobile ? 24 : iPad ? 36 : 40,
                                        color: "#053F76",
                                        mb: 1,
                                    }}
                                >
                                    Distinctive Social Media Services
                                </Typography>
                                <Divider
                                    sx={{ width: "100%", backgroundColor: "#9cbccb", height: 2 }}
                                />
                                <br />
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        mt: 4,
                                        alignItems: "center",
                                    }}
                                >
                                    <img src={Img1} width={mobile ? 25 : 45} />
                                    <Typography
                                        sx={{
                                            fontFamily: "Montserrat",
                                            fontWeight: 700,
                                            ml: 2,
                                            textAlign: "center",
                                            fontSize: mobile ? 16 : 22,
                                            color: "grey",
                                            textAlign: "justify",
                                            mt: 0,
                                        }}
                                    >
                                        Fair Price
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        mt: 2,
                                        alignItems: "center",
                                    }}
                                >
                                    <img src={Img2} width={mobile ? 25 : 45} />
                                    <Typography
                                        sx={{
                                            fontFamily: "Montserrat",
                                            fontWeight: 700,
                                            ml: 2,
                                            textAlign: "center",
                                            fontSize: mobile ? 16 : 22,
                                            color: "grey",
                                            textAlign: "justify",
                                            mt: 0,
                                        }}
                                    >
                                        Real-Time Data
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        mt: 2,
                                        alignItems: "center",
                                    }}
                                >
                                    <img src={Img3} width={mobile ? 25 : 45} />
                                    <Typography
                                        sx={{
                                            fontFamily: "Montserrat",
                                            fontWeight: 700,
                                            ml: 2,
                                            textAlign: "center",
                                            fontSize: mobile ? 16 : 22,
                                            color: "grey",
                                            textAlign: "justify",
                                            mt: 0,
                                        }}
                                    >
                                        Latest Tools
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        mt: 2,
                                        alignItems: "center",
                                    }}
                                >
                                    <img src={Img4} width={mobile ? 25 : 45} />
                                    <Typography
                                        sx={{
                                            fontFamily: "Montserrat",
                                            fontWeight: 700,
                                            ml: 2,
                                            textAlign: "center",
                                            fontSize: mobile ? 16 : 22,
                                            color: "grey",
                                            textAlign: "justify",
                                            mt: 0,
                                        }}
                                    >
                                        SEO Incorporated
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        mt: 2,
                                        alignItems: "center",
                                    }}
                                >
                                    <img src={Img5} width={mobile ? 25 : 45} />
                                    <Typography
                                        sx={{
                                            fontFamily: "Montserrat",
                                            fontWeight: 700,
                                            ml: 2,
                                            textAlign: "center",
                                            fontSize: mobile ? 16 : 22,
                                            color: "grey",
                                            textAlign: "justify",
                                            mt: 0,
                                        }}
                                    >
                                        Social Integration
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        mt: 2,
                                        alignItems: "center",
                                    }}
                                >
                                    <img src={Img6} width={mobile ? 25 : 45} />
                                    <Typography
                                        sx={{
                                            fontFamily: "Montserrat",
                                            fontWeight: 700,
                                            ml: 2,
                                            textAlign: "center",
                                            fontSize: mobile ? 16 : 22,
                                            color: "grey",
                                            textAlign: "justify",
                                            mt: 0,
                                        }}
                                    >
                                        Team Of Experts
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        mt: 2,
                                        alignItems: "center",
                                    }}
                                >
                                    <img src={Img7} width={mobile ? 25 : 45} />
                                    <Typography
                                        sx={{
                                            fontFamily: "Montserrat",
                                            fontWeight: 700,
                                            ml: 2,
                                            textAlign: "center",
                                            fontSize: mobile ? 16 : 22,
                                            color: "grey",
                                            textAlign: "justify",
                                            mt: 0,
                                        }}
                                    >
                                        Retina Graphics
                                    </Typography>
                                </Box>
                            </Box>
                            <img
                                src={Distinctive}
                                width={mobile ? 300 : 550}
                                height={mobile ? 300 : 550}
                                style={{
                                    margin: iPad ? "auto" : "auto",
                                    marginTop: iPad ? 50 : "10%",
                                    display: "block",
                                }}
                            />
                        </Grid>
                        <Grid
                            sx={{
                                display: "flex",
                                flexDirection: iPad ? "column" : "row",
                                margin: mobile ? "10p" : "15px 50px 10px",
                            }}
                            item
                            xs={12}
                        >
                            <img
                                src={Expert}
                                width={550}
                                height={550}
                                style={{
                                    margin: "80px 10px",
                                    display: iPad ? "none" : "block",
                                }}
                            />
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    mt: 10,
                                    ml: iPad ? 0 : 5,
                                    width: iPad ? "100%" : "70%",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontFamily: "Montserrat",
                                        fontWeight: 900,
                                        fontSize: mobile ? 24 : iPad ? 36 : 40,
                                        color: "#053F76",
                                        mb: 1,
                                    }}
                                >
                                    Expert Social Media Solutions
                                </Typography>
                                <Divider
                                    sx={{ width: "100%", backgroundColor: "#9cbccb", height: 2 }}
                                />
                                <br />
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        mt: 2,
                                        alignItems: "center",
                                    }}
                                >
                                    <img src={IMAGE1} width={mobile ? 25 : 45} />
                                    <Typography
                                        sx={{
                                            fontFamily: "Montserrat",
                                            fontWeight: 700,
                                            ml: 2,
                                            textAlign: "center",
                                            fontSize: mobile ? 16 : 22,
                                            color: "grey",
                                            textAlign: "justify",
                                            mt: 0,
                                        }}
                                    >
                                        Market Research Functions
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        mt: 2,
                                        alignItems: "center",
                                    }}
                                >
                                    <img src={IMAGE2} width={mobile ? 25 : 45} />
                                    <Typography
                                        sx={{
                                            fontFamily: "Montserrat",
                                            fontWeight: 700,
                                            ml: 2,
                                            textAlign: "center",
                                            fontSize: mobile ? 16 : 22,
                                            color: "grey",
                                            textAlign: "justify",
                                            mt: 0,
                                        }}
                                    >
                                        Panel Of Experts
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        mt: 2,
                                        alignItems: "center",
                                    }}
                                >
                                    <img src={IMAGE3} width={mobile ? 25 : 45} />
                                    <Typography
                                        sx={{
                                            fontFamily: "Montserrat",
                                            fontWeight: 700,
                                            ml: 2,
                                            textAlign: "center",
                                            fontSize: mobile ? 16 : 22,
                                            color: "grey",
                                            textAlign: "justify",
                                            mt: 0,
                                        }}
                                    >
                                        Constant Social Media Audit
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        mt: 2,
                                        alignItems: "center",
                                    }}
                                >
                                    <img src={IMAGE4} width={mobile ? 25 : 45} />
                                    <Typography
                                        sx={{
                                            fontFamily: "Montserrat",
                                            fontWeight: 700,
                                            ml: 2,
                                            textAlign: "center",
                                            fontSize: mobile ? 16 : 22,
                                            color: "grey",
                                            textAlign: "justify",
                                            mt: 0,
                                        }}
                                    >
                                        Fair Cost
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        mt: 2,
                                        alignItems: "center",
                                    }}
                                >
                                    <img src={IMAGE5} width={mobile ? 25 : 45} />
                                    <Typography
                                        sx={{
                                            fontFamily: "Montserrat",
                                            fontWeight: 700,
                                            ml: 2,
                                            textAlign: "center",
                                            fontSize: mobile ? 16 : 22,
                                            color: "grey",
                                            textAlign: "left",
                                            mt: 0,
                                        }}
                                    >
                                        24*7 Customer Service
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        mt: 2,
                                        alignItems: "center",
                                    }}
                                >
                                    <img src={IMAGE6} width={mobile ? 25 : 45} />
                                    <Typography
                                        sx={{
                                            fontFamily: "Montserrat",
                                            fontWeight: 700,
                                            ml: 2,
                                            textAlign: "center",
                                            fontSize: mobile ? 16 : 22,
                                            color: "grey",
                                            textAlign: "justify",
                                            mt: 0,
                                        }}
                                    >
                                        Better Strategies
                                    </Typography>
                                </Box>
                            </Box>
                            <img
                                src={Expert}
                                width={mobile ? 300 : 550}
                                height={mobile ? 300 : 550}
                                style={{
                                    margin: mobile ? "40px 15px" : iPad ? "auto" : "80px 10px",
                                    marginTop: iPad ? 50 : 0,
                                    display: iPad ? "block" : "none",
                                }}
                            />
                        </Grid>
                    </Grid>
                </Container>
                <br />
                <br />
                <br />
                <Footer />
            </Box>
        </div>
    );
}
