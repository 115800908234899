import { Box, Button, Grid } from '@mui/material'
import React from 'react';
import Accor from './Accor';

export default function Faq() {


    const accordionItems = [
        {
            title: '+ When is the workshop?',
            content: '- Workshop is on Saturday, 10th June at 7 PM.',
        },
        {
            title: '+ Will this be live or pre-recorded?',
            content: '- It is a completely LIVE Workshop .',
        },
        {
            title: '+ Is the Workshop Certified?',
            content: '- Yes! It is a Skill Nation Certified Workshop.',
        },
        {
            title: '+ Do you get notes & assignments to practice?',
            content: ' - It is a completely hands-on workshop where you will also get notes, reference material & assignments for your future reference.',
        },
        {
            title: '+ Will it be recorded?',
            content: '- No, it won’t be recorded.',
        },
        {
            title: '+ I made the payment but didn’t receive any email',
            content: '- Please write to us at hello@skillnation.in & our awesome support team will get back to you as soon as possible to help you out.',
        },
    ];


    return (
        <Box style={{ background: "#34495e", width: "100%", paddingLeft: "10%", paddingTop: '5%',paddingBottom:"5%" }}>
            <Box style={{ backgroundColor: '#fff', width: '90%', borderRadius: 10, marginTop: '10%'}}>

                <Grid container spacing={2}>
                    <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
                        <h1 style={{ color: '#34495e', display: 'flex', flexDirection: 'column', justifyContent: 'center', fontWeight: "bold", marginTop: 10 }}>Congrats!!!</h1>
                        <p style={{ color: '#34495e', display: 'flex', justifyContent: 'center', fontWeight: 25, fontSize: 14, textAlign: 'justify', marginInlineStart: 5, marginInlineEnd: 5 }}>You have successfully registered for – LIVE Google Ads Workshop. Join the exclusive WhatsApp group by clicking the button below.</p>
                        <Button style={{ backgroundColor: 'green', color: '#fff', width: "75%", height: 60, fontWeight: 'bold' }}>Join WhatsApp Group</Button>
                        {/* <hr style={{color:"#34495e"}}></hr> */}
                        <p style={{ color: "#34495e", fontWeight: 'bold' }}>----------------------------------------</p>
                        <h2 style={{ color: '#34495e', display: 'flex', flexDirection: 'column', justifyContent: 'center', fontWeight: 'bold' }}>FAQs</h2>


                        {/* <Box style={{ backgroundColor: "#34495e", width: "100%"}}> */}
                        <Accor items={accordionItems} />
                        {/* </Box> */}

                    </Grid>
                </Grid>

            </Box>
        </Box>
    )
}
