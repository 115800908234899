import React,{useState,useEffect} from "react";
import { Button,Grid,TextField } from "@mui/material";
import { getData } from "../ApiServices/ServerServices";
import MaterialTable from "@material-table/core";
import {useStyles} from "./DisplayAllCategoryCss"
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { postData } from "../ApiServices/ServerServices";
import Swal from "sweetalert2";
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme} from "@mui/material/styles";
import { io } from 'socket.io-client'
import { useContext } from 'react';
import { SessionContext } from "../ApiServices/SessionContext";

import addNotification from 'react-push-notification';
const Input = styled('input')({
  display: 'none',
});


export default function DisplayAllRegistrations(props){  

  var theme =useTheme()
  const classes=useStyles()   
  const matches=useMediaQuery(theme.breakpoints.down(700))  
  const[registrations,setRegistrations]=useState([])

  const [socket,setSocket]=React.useState()


React.useEffect(() => {
  const socket = io("https://api4.indiabuzz.co.in/");
  setSocket(socket);

  socket.on("connect", () => {
    console.log(socket.id);
    socket.emit("backend",'admin');
  });


}, []);
function buttonOnClick (message){
  addNotification({
    title: 'New Registration',
    native:true         
  })
};







function displayTable() {
    return (
      <MaterialTable
      title={"Sub Category List"}
        data={registrations}
        columns={[
            {
              title: "Registration Id",
              field: "_id",
             
            },
            {
              title: "Student Name",
              field: "name",
            },
            {
              title: "Student Phone Number",
              field: "number",
            },
            {
              title: "Student Email Id",
              field: "email",
            },
            
           
        ]}
        actions={[
          
        ]}
       
      />
    );
  }


 

    const fetchAllRegistrations=async()=>{
        const result=await getData('registration/displayallregistrations')
        
    setRegistrations(result)
    }


    useEffect(function(){
      if(socket){
        socket.on("new", (Enquiry) => {
          if(window.localStorage.getItem("Session"))
          {
            buttonOnClick(Enquiry)
          }
      });}
       fetchAllRegistrations()

    },[socket])

    return(
 
      <Grid container spacing={2} style={{  display:"flex",
      justifyContent:'center',
      alignItems:'center'}}>
        <Grid item xs={12} sm={8} style={{marginTop:20,fontSize:matches?10:20}}>
      {displayTable()}</Grid>
      </Grid>

    )
    
}