import { Box, Typography, Grid, Button, Paper } from "@mui/material";
import React from "react";
import video from "../../AComponents/Vault/DigMar_video.mp4";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import Tick from "./Images/WHEN CLIENT.png";
import Camera from "./Images/Camera.png";
import Clock from "./Images/Clock.png";
import Certificate from "./Images/certificate.png";
import Rating from "./Images/Ratings.png";
import GoogleAdsIcon from "./Images/googleads_icon.png";
import rocket from "./Images/Rocket.png";
import framework from "./Images/Framework.png";
import keyword from "./Images/Keywords.png";
import spy from "./Images/spy_keyword.png";
import Webpage from "./Images/webpage.png";
import CircleTickIcon from "./Images/circle_Tick.png";
import Img1 from "./Images/img_1.png";
import Img2 from "./Images/img_2.png";
import Img3 from "./Images/img_3.png";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import MoneyIcon from "@mui/icons-material/Money";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import CertificateImg from "./Images/certificateion_image.jpg";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { FormatQuoteSharp } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const data = [
        {
                name: "Master Google Ads - #1 Skill in Digital Marketing",
                description:
                        "If you’re kickstarting or even trying to grow your career in Digital Marketing, Google Ads is a skill you need to master & this workshop helps you do exactly that.",
        },
        {
                name: "Learn Google Ads from Visiting Faculty at NMIMS with over 8 years of exp running google ads.",
                description:
                        "Mihir has managed over 100cr in ad spends and worked with top brands like HP, Cineworld, Visa Cards, London business School, Wharton Business School, Smokescreen Carsome, and many more.",
        },
        {
                name: "Use Google Ads to scale businesses & drive exponential profits",
                description:
                        "Use Google Ads to take any business to the next level. Reach the right audience at the right time to 3x profits with high ROI driven ads.",
        },
        {
                name: "Land high paying clients as a Google Ads Consultant",
                description:
                        "Digital Marketing is the most in-demand skill in the post-pandemic era. Master it to earn up to Rs 80,000 / month as a consultant without leaving your day job.",
        },
];

const data1 = [
        {
                description:
                        "You want to learn the skill of using Google Ads to 3x YOUR business profit",
        },
        { description: "You want to build an amazing career in Digital Marketing" },
        { description: "You want to Master Google Ads from a NMIMS Faculty" },
        { description: "You want to earn money as a Google Ads Consultant" },
];

const cardData = [
        { url: Camera, name: "3 Hours Live", description: "Workshop" },
        { url: Clock, name: "3rd June, 2023", description: "Date" },
        { url: Certificate, name: "Get Mentored", description: "By NMIMS Faculty" },
        { url: Rating, name: "5 Star Rated", description: "by hundreds of learners" },
];

const cardData1 = [
        {
                url: GoogleAdsIcon,
                name: "The Logic behind the Algorithm of Google Ads",
                description1:
                        "Learn how google ads algorithm works across Search, Display and Video Formats",
                description2:
                        "Learn how to use the algorithm to run successful ads campaigns",
        },
        {
                url: rocket,
                name: "Launch Google ads for your business effortlessly",
                description1:
                        "Learn How to Structure a Campaign in the most seamless manner so as to ensure maximum return in minimum effort",
                description2:
                        "Understand the right sequence to Launch Google ads in an effective and time bound manner",
        },
        {
                url: framework,
                name: "Complete framework to run ads that give 3x return on Ad Spend",
                description1:
                        "Get the Secret Tricks to capture high quality converting leads",
                description2:
                        " Leverage easy & actionable optimization tips that will get you automatic leads",
        },
        {
                url: keyword,
                name: "How to Find high converting keywords",
                description1:
                        "Learn how to discover keywords that are most relevant to your business ",
                description2:
                        "Learn the different keyword match types and how they can be used to maximize revenue",
        },
        {
                url: spy,
                name: "How to Spy on competitor keywords",
                description1:
                        "Learn the Free and the Paid way to find out the keywords used by your competitors",
                description2:
                        "Lean how to leverage competitor keywords to benefit your business goals by ethically stealing their customers",
        },
        {
                url: Webpage,
                name: "How to ensure to Stay on Page 1 of Google ads",
                description1:
                        "Learn the strategies that ensure you stay on Page 1 of the search results",
                description2:
                        "Learn to attract high spending customers on your website to get more business",
        },
];

const cardData2 = [
        {
                url: Img1,
                name: "Working Professionals",
                description:
                        "If you are in the digital marketing field and want to master the in-demand skill of digital marketing then this google ads workshop is for you. This skill will help you get high-paying jobs in the top MNCs and Startups you can think of.",
        },
        {
                url: Img2,
                name: "Business Owners/Entrepreneurs",
                description:
                        "Learn the art of using Google Ads to scale your business beyond your local limits & 3x your current profit. Attend the workshop to know how to run profitable campaigns that get you high return on your investment (ROI).",
        },
        {
                url: Img3,
                name: "Freelancers/Home-Makers",
                description:
                        "If you’re a freelancer/ homemaker/ want to start your journey in premium freelancing and want to learn one of the highest paying & most in-demand skills of Digital Marketing then this workshop is for you. Learn how to run Google Ads and scale any business from the comfort of your home.",
        },
];

const cardData3 = [
        {
                id: 1,
                icon: (
                        <FileCopyIcon
                                sx={{
                                        color: "green",
                                        fontSize: 50,
                                        display: "block",
                                        margin: "auto",
                                        mt: 2,
                                        mb: 2,
                                }}
                        />
                ),
                name: "Free Google Ad Copy Generator",
                rs: "4,500",
        },
        {
                id: 2,
                icon: (
                        <TipsAndUpdatesIcon
                                sx={{
                                        color: "green",
                                        fontSize: 50,
                                        display: "block",
                                        margin: "auto",
                                        mt: 2,
                                        mb: 2,
                                }}
                        />
                ),
                name: "10 Foolproof Copywriting Tips for Google Ads",
                rs: "4,000",
        },
        {
                id: 3,
                icon: (
                        <LockOpenIcon
                                sx={{
                                        color: "green",
                                        fontSize: 50,
                                        display: "block",
                                        margin: "auto",
                                        mt: 2,
                                        mb: 2,
                                }}
                        />
                ),
                name: "10 Secret Hacks to 10X Your Google Ads ROI",
                rs: "5,000",
        },
        {
                id: 4,
                icon: (
                        <MoneyIcon
                                sx={{
                                        color: "green",
                                        fontSize: 50,
                                        display: "block",
                                        margin: "auto",
                                        mt: 2,
                                        mb: 2,
                                }}
                        />
                ),
                name: "Detailed Notes for everything learnt in the Workshop for reference",
                rs: "2,500",
        },
        {
                id: 5,
                icon: (
                        <FormatListNumberedIcon
                                sx={{
                                        color: "green",
                                        fontSize: 50,
                                        display: "block",
                                        margin: "auto",
                                        mt: 2,
                                        mb: 2,
                                }}
                        />
                ),
                name: "Step by Step Google Ads Launch Checklist",
                rs: "4,000",
        },
];

const accordionItems = [
        {
                title: " When is the workshop?",
                content: " Workshop is on Saturday, 10th June at 7 PM.",
        },
        {
                title: " Will this be live or pre-recorded?",
                content: " It is a completely LIVE Workshop .",
        },
        {
                title: " Is the Workshop Certified?",
                content: " Yes! It is a Skill Nation Certified Workshop.",
        },
        {
                title: " Do you get notes & assignments to practice?",
                content:
                        " It is a completely hands-on workshop where you will also get notes, reference material & assignments for your future reference.",
        },
        {
                title: " Will it be recorded?",
                content: " No, it won’t be recorded.",
        },
        {
                title: " I made the payment but didn’t receive any email",
                content:
                        " Please write to us at hello@skillnation.in & our awesome support team will get back to you as soon as possible to help you out.",
        },
];

export default function GoogleAdsWorkShop() {
        const navigate = useNavigate();

        const MainHeading = () => {
                return (
                        <Box sx={{ p: "20px 12px" }}>
                                <Typography
                                        sx={{
                                                lineHeight: "1.2em",
                                                letterSpacing: "-0.9px",
                                                fontSize: { xs: "24px" },
                                                color: "#EBEF00",
                                                fontWeight: 600,
                                                fontFamily: "Poppins",
                                                textAlign: "center",
                                        }}
                                >
                                        Do you want to Master Google <br /> Ads & build a Successful Career{" "}
                                        <br /> in Digital Marketing?
                                </Typography>
                        </Box>
                );
        };

        const Text = () => {
                return (
                        <Box sx={{ p: "20px 12px 30px" }}>
                                <Typography
                                        sx={{
                                                margin: "-17px 0px -13px 0px",
                                                lineHeight: "1.3em",
                                                letterSpacing: "0px",
                                                textAlign: "center",
                                                fontSize: "14px",
                                                color: "#ffffff",
                                                fontWeight: 400,
                                                fontFamily: "Poppins",
                                                textAlign: "center",
                                        }}
                                >
                                        The 3 Hour Google Ads Workshop is a LIVE Hands-on journey to help go
                                        from zero to hero in Google Ads.
                                </Typography>
                        </Box>
                );
        };

        const Video = () => {
                return (
                        <Box sx={{ p: "0px 0px 20px" }}>
                                <video
                                        src={video}
                                        controls
                                        width={400}
                                        height={250}
                                        style={{ borderRadius: 10 }}
                                />
                        </Box>
                );
        };

        const BookYourSpot = () => {
                return (
                        <Box
                                onClick={() => navigate("/registerpage")}
                                sx={{
                                        p: 3,
                                        ml: 1,
                                        mr: 1,
                                        mb: 2,
                                        borderRadius: 2,
                                        background: "#CF0808",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        flexDirection: "column",
                                }}
                        >
                                <Typography
                                        sx={{
                                                color: "#ffffff",
                                                fontSize: "21px",
                                                fontFamily: "Poppins",
                                                fontWeight: 600,
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                flexDirection: "row",
                                        }}
                                >
                                        <DoubleArrowIcon sx={{ color: "#ffffff" }} />
                                        &nbsp;Book Your Spot Now
                                </Typography>
                                <Typography sx={{ color: "#ffffff" }}>
                                        (Only Rs 99 <s>Rs 2000</s> )
                                </Typography>
                        </Box>
                );
        };

        const Link = () => {
                return (
                        <Box
                                sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                        >
                                <a style={{ color: "#ffffff" }} href="/">
                                        Workshop is on Saturday, 3rd June at 7 PM
                                </a>
                        </Box>
                );
        };

        const Topics = () => {
                return data.map((item) => {
                        return (
                                <Box
                                        sx={{
                                                pl: 1,
                                                pr: 2,
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "start",
                                                flexDirection: "row",
                                                mt: 2,
                                                mb: 2,
                                        }}
                                >
                                        <img src={Tick} width={30} />
                                        <Box
                                                sx={{
                                                        ml: 1.2,
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        flexDirection: "column",
                                                }}
                                        >
                                                <Typography
                                                        sx={{
                                                                color: "#EBEF00",
                                                                fontSize: "17px",
                                                                fontWeight: 600,
                                                                fontFamily: "Poppins",
                                                        }}
                                                >
                                                        {item.name}
                                                </Typography>
                                                <Typography
                                                        sx={{
                                                                textAlign: "left",
                                                                mt: 1,
                                                                color: "#ffffff",
                                                                fontSize: "14px",
                                                                fontWeight: 600,
                                                                fontFamily: "Poppins",
                                                        }}
                                                >
                                                        {item.description}
                                                </Typography>
                                        </Box>
                                </Box>
                        );
                });
        };
        const Card = () => {
                return cardData.map((item) => {
                        return (
                                <Grid
                                        item
                                        xs={5}
                                        sx={{
                                                borderRadius: 2,
                                                height: 190,
                                                background: "#f0f5f5",
                                                m: 1,
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                flexDirection: "column",
                                        }}
                                >
                                        <img src={item.url} width={90} />
                                        <Typography
                                                sx={{ color: "#009432", fontWeight: 600, fontSize: "14px" }}
                                        >
                                                {item.name}
                                        </Typography>
                                        <Typography sx={{ textAlign: "center" }}>
                                                {item.description}
                                        </Typography>
                                </Grid>
                        );
                });
        };

        const TextTwo = () => {
                return (
                        <Box sx={{ p: "20px 12px 30px" }}>
                                <Typography
                                        sx={{
                                                margin: "-17px 0px -13px 0px",
                                                lineHeight: "1.4em",
                                                letterSpacing: "-0.6px",
                                                textAlign: "center",
                                                fontSize: "15px",
                                                color: "#000000",
                                                fontWeight: 400,
                                                fontFamily: "Poppins",
                                                textAlign: "center",
                                        }}
                                >
                                        My only regret is that I didn't attend the Workshop sooner. I was able
                                        to use Google Ads to land a great job in Digital Marketing in just a
                                        few months after the workshop. The workshop has been a life changer
                                        &amp; I recommend everyone to learn from Mihir Sir.
                                </Typography>
                        </Box>
                );
        };

        const Admin = () => {
                return (
                        <Box
                                sx={{
                                        p: "10px 12px 20px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        flexDirection: "row",
                                }}
                        >
                                <img src={Tick} width={50} />
                                <Typography
                                        sx={{
                                                ml: 2,
                                                fontSize: "21px",
                                                fontWeight: 700,
                                                fontFamily: "Poppins",
                                        }}
                                >
                                        &nbsp;Harshit Jain
                                </Typography>
                        </Box>
                );
        };

        const TextThree = () => {
                return (
                        <Box
                                sx={{
                                        p: "5px 12px 30px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        flexDirection: "column",
                                }}
                        >
                                <Typography
                                        sx={{
                                                ml: 2,
                                                fontSize: "17px",
                                                fontWeight: 600,
                                                fontFamily: "Poppins",
                                        }}
                                >
                                        Register before{" "}
                                        <strong style={{ color: "darkorange" }}>May 20, 2023</strong>&nbsp;to
                                        unlock
                                </Typography>
                                <Typography
                                        sx={{
                                                ml: 2,
                                                fontSize: "17px",
                                                fontWeight: 600,
                                                fontFamily: "Poppins",
                                        }}
                                >
                                        bonuses worth{" "}
                                        <strong style={{ color: "darkorange" }}>Rs 20,000</strong>
                                </Typography>
                        </Box>
                );
        };
        const TextThree1 = () => {
                return (
                        <Box
                                sx={{
                                        p: "5px 12px 30px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        flexDirection: "column",
                                }}
                        >
                                <Typography
                                        sx={{
                                                color: "#ffffff",
                                                ml: 2,
                                                fontSize: "17px",
                                                fontWeight: 600,
                                                fontFamily: "Poppins",
                                        }}
                                >
                                        Register before{" "}
                                        <strong style={{ color: "darkorange" }}>May 20, 2023</strong>&nbsp;to
                                        unlock
                                </Typography>
                                <Typography
                                        sx={{
                                                color: "#ffffff",
                                                ml: 2,
                                                fontSize: "17px",
                                                fontWeight: 600,
                                                fontFamily: "Poppins",
                                        }}
                                >
                                        bonuses worth{" "}
                                        <strong style={{ color: "darkorange" }}>Rs 20,000</strong>
                                </Typography>
                        </Box>
                );
        };

        const TextFour = () => {
                return (
                        <Box sx={{ m: 2, mb: 5 }}>
                                <Typography
                                        sx={{
                                                margin: "-17px 0px -13px 0px",
                                                lineHeight: "1.4em",
                                                letterSpacing: "-0.6px",
                                                textAlign: "center",
                                                fontSize: "26px",
                                                color: "#000000",
                                                fontWeight: 700,
                                                fontFamily: "Poppins",
                                                textAlign: "center",
                                        }}
                                >
                                        What will you learn in this Masterclass?
                                </Typography>
                        </Box>
                );
        };

        const CardData1 = () => {
                return cardData1.map((item) => {
                        return (
                                <Box
                                        sx={{
                                                background: "#ffffcc",
                                                border: "1px solid #000000",
                                                p: "19px 15px",
                                                m: 1,
                                                mb: 1.7,
                                                borderRadius: 3,
                                        }}
                                >
                                        <img src={item.url} width={70} style={{ marginLeft: 18 }} />
                                        <Typography
                                                sx={{
                                                        mt: 1.2,
                                                        mb: 1,
                                                        ml: 1.9,
                                                        color: "#053F76",
                                                        fontSize: "20px",
                                                        fontWeight: 700,
                                                        fontFamily: "Poppins",
                                                }}
                                        >
                                                {item.name}
                                        </Typography>
                                        <Box
                                                sx={{
                                                        ml: 1.2,
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "start",
                                                        flexDirection: "row",
                                                }}
                                        >
                                                <img
                                                        src={CircleTickIcon}
                                                        width={30}
                                                        style={{ marginTop: 6, marginRight: 5 }}
                                                />
                                                <Typography
                                                        sx={{
                                                                textAlign: "left",
                                                                mt: 1,
                                                                color: "#000000",
                                                                fontSize: "14px",
                                                                fontWeight: 600,
                                                                fontFamily: "Poppins",
                                                        }}
                                                >
                                                        {item.description1}
                                                </Typography>
                                        </Box>
                                        <Box
                                                sx={{
                                                        ml: 1.2,
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "start",
                                                        flexDirection: "row",
                                                }}
                                        >
                                                <img
                                                        src={CircleTickIcon}
                                                        width={30}
                                                        style={{ marginTop: 6, marginRight: 5 }}
                                                />
                                                <Typography
                                                        sx={{
                                                                textAlign: "left",
                                                                mt: 1,
                                                                color: "#000000",
                                                                fontSize: "14px",
                                                                fontWeight: 600,
                                                                fontFamily: "Poppins",
                                                        }}
                                                >
                                                        {item.description2}
                                                </Typography>
                                        </Box>
                                </Box>
                        );
                });
        };

        const TextFive = () => {
                return (
                        <Box sx={{ m: 2, mb: 5 }}>
                                <Typography
                                        sx={{
                                                margin: "-17px 0px -13px 0px",
                                                lineHeight: "1.4em",
                                                letterSpacing: "-0.6px",
                                                textAlign: "center",
                                                fontSize: "26px",
                                                color: "#000000",
                                                fontWeight: 700,
                                                fontFamily: "Poppins",
                                                textAlign: "center",
                                        }}
                                >
                                        More than 15,000 students have called it the best way to learn Google
                                        Ads
                                </Typography>
                        </Box>
                );
        };

        const TextSix = () => {
                return (
                        <Box sx={{ mb: 5 }}>
                                <Typography
                                        sx={{
                                                margin: "-17px 0px -13px 0px",
                                                lineHeight: "1.4em",
                                                letterSpacing: "-0.6px",
                                                textAlign: "center",
                                                fontSize: "26px",
                                                color: "#000000",
                                                fontWeight: 700,
                                                fontFamily: "Poppins",
                                                textAlign: "center",
                                        }}
                                >
                                        Who is this workshop for?
                                </Typography>
                        </Box>
                );
        };

        const CardData2 = () => {
                return cardData2.map((item) => {
                        return (
                                <Paper
                                        elevation={3}
                                        sx={{
                                                background: "#ffffcc",
                                                p: "19px 20px",
                                                m: 1,
                                                mb: 1.7,
                                                borderRadius: 3,
                                        }}
                                >
                                        <img src={item.url} width={90} style={{ marginLeft: 18 }} />
                                        <Typography
                                                sx={{
                                                        borderRadius: 2,
                                                        textAlign: "left",
                                                        background: "#000000",
                                                        p: "10px 20px",
                                                        mt: 1.2,
                                                        mb: 1,
                                                        color: "#FFDE00",
                                                        fontSize: "22px",
                                                        fontWeight: 600,
                                                        fontFamily: "Poppins",
                                                }}
                                        >
                                                {item.name}
                                        </Typography>
                                        <Typography
                                                sx={{
                                                        textAlign: "justify",
                                                        mt: 1,
                                                        color: "#000000",
                                                        fontSize: "16px",
                                                        fontWeight: 400,
                                                        fontFamily: "Poppins",
                                                }}
                                        >
                                                {item.description}
                                        </Typography>
                                        <a
                                                href="/"
                                                style={{
                                                        marginTop: 6,
                                                        display: "flex",
                                                        alignItems: "center",
                                                        flexDirection: "row",
                                                        textAlign: "left",
                                                        mt: 1,
                                                        color: "#000000",
                                                        fontSize: "22px",
                                                        fontWeight: 500,
                                                        fontFamily: "Poppins",
                                                        textDecoration: "none",
                                                }}
                                        >
                                                <ArrowForwardIcon sx={{ color: "#000000", fontSize: 26 }} />
                                                &nbsp; Reserve Seat Now
                                        </a>
                                </Paper>
                        );
                });
        };

        const TextSeven = () => {
                return (
                        <Box sx={{ p: "20px 12px 30px" }}>
                                <Typography
                                        sx={{
                                                margin: "-17px 0px -13px 0px",
                                                lineHeight: "1.3em",
                                                letterSpacing: "0.5px",
                                                textAlign: "center",
                                                fontSize: "24px",
                                                color: "#ffffff",
                                                fontWeight: 600,
                                                fontFamily: "Poppins",
                                                textAlign: "center",
                                        }}
                                >
                                        Register before midnight of 21 May 2023
                                </Typography>
                        </Box>
                );
        };
        const TextEight = () => {
                return (
                        <Box sx={{ p: "20px 12px 30px" }}>
                                <Typography
                                        sx={{
                                                margin: "-17px 0px -13px 0px",
                                                lineHeight: "1.3em",
                                                letterSpacing: "0px",
                                                textAlign: "center",
                                                fontSize: "15px",
                                                color: "#FFDE00",
                                                fontWeight: 600,
                                                fontFamily: "Poppins",
                                        }}
                                >
                                        To Unlock All Bonuses worth Rs 20,000
                                </Typography>
                        </Box>
                );
        };

        const CardData3 = () => {
                return cardData3.map((item) => {
                        return (
                                <Paper
                                        elevation={3}
                                        sx={{
                                                background: "#e6e6e6",
                                                p: "19px 20px",
                                                m: 1,
                                                mb: 6,
                                                borderRadius: 3,
                                        }}
                                >
                                        <Box
                                                sx={{
                                                        mt: -5,
                                                        borderRadius: 2,
                                                        p: "25px 20px",
                                                        background: "#000000",
                                                        color: "#FFDE00",
                                                }}
                                        >
                                                <Typography
                                                        sx={{
                                                                margin: "-17px 0px -13px 0px",
                                                                lineHeight: "1.3em",
                                                                letterSpacing: "0px",
                                                                textAlign: "center",
                                                                fontSize: "24px",
                                                                color: "#FFDE00",
                                                                fontWeight: 600,
                                                                fontFamily: "Poppins",
                                                                textAlign: "center",
                                                        }}
                                                >
                                                        Bonus {item.id}
                                                </Typography>
                                        </Box>
                                        {item.icon}
                                        <Typography
                                                sx={{
                                                        borderRadius: 2,
                                                        textAlign: "center",
                                                        p: "10px 20px",
                                                        mt: 1.2,
                                                        mb: 1,
                                                        color: "#000000",
                                                        fontSize: "18px",
                                                        fontWeight: 600,
                                                        fontFamily: "Poppins",
                                                }}
                                        >
                                                {item.name}
                                        </Typography>
                                        <br />
                                        <Typography
                                                sx={{
                                                        borderRadius: 3,
                                                        p: "6px 20px",
                                                        background: "transparent",
                                                        color: "#000000",
                                                        border: "1px solid black",
                                                        margin: "-17px 0px -13px 0px",
                                                        lineHeight: "1.3em",
                                                        letterSpacing: "0px",
                                                        fontSize: "24px",
                                                        fontWeight: 600,
                                                        fontFamily: "Poppins",
                                                        textAlign: "center",
                                                }}
                                        >
                                                Worth Rs&nbsp; {item.rs}
                                        </Typography>
                                        <br />
                                </Paper>
                        );
                });
        };

        const TextNine = () => {
                return (
                        <Box sx={{ p: "20px 12px 30px" }}>
                                <Typography
                                        sx={{
                                                margin: "-17px 0px -13px 0px",
                                                lineHeight: "1.3em",
                                                letterSpacing: "0px",
                                                textAlign: "center",
                                                fontSize: "28px",
                                                color: "#FFDE00",
                                                fontWeight: 700,
                                                fontFamily: "Poppins",
                                                textAlign: "center",
                                        }}
                                >
                                        Get Certified
                                </Typography>
                        </Box>
                );
        };

        const TextTen = () => {
                return (
                        <Box sx={{ p: "20px 12px 30px" }}>
                                <Typography
                                        sx={{
                                                margin: "-17px 0px -13px 0px",
                                                lineHeight: "1.3em",
                                                letterSpacing: "0px",
                                                textAlign: "center",
                                                fontSize: "15px",
                                                color: "#ffffff",
                                                fontWeight: 400,
                                                fontFamily: "Poppins",
                                                textAlign: "center",
                                        }}
                                >
                                        Yes! You will be certified by{" "}
                                        <a href="/" style={{ color: "#FFDE00", fontWeight: "bold" }}>
                                                SKILL NATION
                                        </a>{" "}
                                        which brings a credibility to your certificate & your resume.
                                </Typography>
                        </Box>
                );
        };

        const CertificateImage = () => {
                return (
                        <Box>
                                <img src={CertificateImg} width={"100%"} />
                        </Box>
                );
        };

        const TextA = () => {
                return (
                        <Box sx={{ mb: 5 }}>
                                <Typography
                                        sx={{
                                                margin: "-17px 0px -13px 0px",
                                                lineHeight: "1.4em",
                                                letterSpacing: "0.5px",
                                                textAlign: "center",
                                                fontSize: "26px",
                                                color: "#000000",
                                                fontWeight: 700,
                                                fontFamily: "Poppins",
                                                textAlign: "center",
                                        }}
                                >
                                        You should join this workshop if
                                </Typography>
                        </Box>
                );
        };

        const Topics1 = () => {
                return data1.map((item) => {
                        return (
                                <Box
                                        sx={{
                                                pl: 1,
                                                pr: 2,
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "start",
                                                flexDirection: "row",
                                                mt: 2,
                                                mb: 2,
                                        }}
                                >
                                        <img src={Tick} width={24} style={{ marginTop: 4 }} />
                                        <Typography
                                                sx={{
                                                        textAlign: "left",
                                                        ml: 2,
                                                        mt: 0,
                                                        color: "#000000",
                                                        fontSize: "20px",
                                                        fontWeight: 600,
                                                        fontFamily: "Poppins",
                                                }}
                                        >
                                                {item.description}
                                        </Typography>
                                </Box>
                        );
                });
        };

        const [openAccordion, setOpenAccordion] = React.useState(null);

        const toggleAccordion = (index) => {
                if (openAccordion === index) {
                        setOpenAccordion(null);
                } else {
                        setOpenAccordion(index);
                }
        };

        const FaqHeading = () => {
                return (
                        <Box sx={{ p: "0px 12px 30px" }}>
                                <Typography
                                        sx={{
                                                margin: "-17px 0px -13px 0px",
                                                lineHeight: "1.3em",
                                                letterSpacing: "0px",
                                                textAlign: "center",
                                                fontSize: "28px",
                                                color: "#000000",
                                                fontWeight: 700,
                                                fontFamily: "Poppins",
                                                textAlign: "center",
                                        }}
                                >
                                        Frequently Asked Questions
                                </Typography>
                                <br />
                                <br />
                                <Typography
                                        sx={{
                                                margin: "-17px 0px -13px 0px",
                                                lineHeight: "1.3em",
                                                letterSpacing: "0px",
                                                textAlign: "center",
                                                fontSize: "15px",
                                                color: "#000000",
                                                fontWeight: 400,
                                                fontFamily: "Poppins",
                                                textAlign: "center",
                                        }}
                                >
                                        I have tried my best to answer all the frequently asked questions. If
                                        you still have more questions, please write to us at
                                        hello@skillnation.in. My awesome support team will get back to you.
                                </Typography>
                        </Box>
                );
        };

        const Faq = () => {
                return accordionItems.map((item, index) => {
                        return (
                                <Box
                                        sx={{ width: "80%", borderRadius: 1, m: 1, p: "2px 10px" }}
                                        key={index}
                                >
                                        <Box
                                                sx={{
                                                        boxShadow: "0px 9px 14px 3px lightgrey",
                                                        width: "100%",
                                                        background: "#f2f2f2",
                                                        padding: "15px 25px",
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        justifyContent: "space-between",
                                                        cursor: "pointer",
                                                }}
                                                onClick={() => toggleAccordion(index)}
                                        >
                                                <Typography
                                                        sx={{
                                                                fontFamily: "Montserrat",
                                                                fontSize: 12,
                                                                color: "#000000",
                                                                fontWeight: 600,
                                                                textAlign: "left",
                                                        }}
                                                >
                                                        {item.title}
                                                </Typography>
                                                {openAccordion === index ? (
                                                        <RemoveIcon
                                                                sx={{ color: "#000000", fontSize: 20, fontWeight: "bold" }}
                                                        />
                                                ) : (
                                                        <AddIcon
                                                                sx={{ color: "#000000", fontSize: 20, fontWeight: "bold" }}
                                                        />
                                                )}
                                        </Box>
                                        {openAccordion === index && (
                                                <Box
                                                        sx={{
                                                                background: "#ffffff",
                                                                width: "100%",
                                                                padding: "15px 25px",
                                                                border: "1px solid lightgrey",
                                                        }}
                                                >
                                                        <Typography
                                                                sx={{
                                                                        fontFamily: "Montserrat",
                                                                        color: "#000000",
                                                                        fontSize: 12,
                                                                        fontWeight: 600,
                                                                }}
                                                        >
                                                                {item.content}
                                                        </Typography>
                                                </Box>
                                        )}
                                </Box>
                        );
                });
        };

        const Footer = () => {
                return (
                        <Box
                                sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: "10px 20px",
                                }}
                        >
                                <Box>
                                        <Box
                                                sx={{ display: "flex", alignItems: "end", flexDirection: "row" }}
                                        >
                                                <Typography
                                                        sx={{ fontFamily: "Poppins", fontSize: "31px", color: "#ffffff" }}
                                                >
                                                        <strong>₹99</strong>
                                                </Typography>
                                                &nbsp;
                                                <Typography
                                                        sx={{
                                                                fontFamily: "Poppins",
                                                                fontSize: "18px",
                                                                color: "#EBEF00",
                                                                mb: 1,
                                                        }}
                                                >
                                                        <s>₹2000</s>
                                                </Typography>
                                        </Box>
                                        <Typography sx={{ color: "#ffffff" }}>15000+ Enrolled</Typography>
                                </Box>
                                <Button
                                onClick={()=>navigate("/registerpage")}
                                        sx={{
                                                fontSize: "20px",
                                                color: "#053F76",
                                                background: "#FFDE00",
                                                padding: "6px 15px",
                                                fontWeight: 700,
                                                fontFamily: "Poppins",
                                        }}
                                >
                                        Register Now
                                </Button>
                        </Box>
                );
        };

        return (
                <Box sx={{ overflowX: "hidden" }}>
                        <Box
                                sx={{
                                        fontFamily: "Poppins",
                                        background: "#053F76",
                                        m: 0,
                                        p: 0,
                                        boxSizing: "border-box",
                                }}
                        >
                                <MainHeading />
                                <Text />
                                <Video />
                                <BookYourSpot />
                                <Link />
                                <Topics />
                                <br />
                        </Box>
                        <Box
                                sx={{
                                        fontFamily: "Poppins",
                                        background: "#ffffff",
                                        m: 0,
                                        p: 0,
                                        boxSizing: "border-box",
                                }}
                        >
                                <Grid
                                        container
                                        spacing={2}
                                        sx={{
                                                display: "flex",
                                                pl: 1.5,
                                                pt: 1,
                                                justifyContent: "center",
                                                alignItems: "center",
                                        }}
                                >
                                        <Card />
                                </Grid>
                                <TextTwo />
                                <Admin />
                                <BookYourSpot />
                                <TextThree />
                                <TextFour />
                                <CardData1 />
                                <BookYourSpot />
                                <TextThree />
                                <TextFive />
                                <br />
                                <br />
                                <br />
                                <br />
                                <BookYourSpot />
                                <TextThree />
                                <TextSix />
                                <CardData2 />
                                <BookYourSpot />
                                <TextThree />
                        </Box>
                        <Box
                                sx={{
                                        fontFamily: "Poppins",
                                        background: "#053F76",
                                        m: 0,
                                        p: "10px 20px",
                                        boxSizing: "border-box",
                                }}
                        >
                                <TextSeven />
                                <TextEight />
                        </Box>
                        <br />
                        <br />
                        <br />
                        <Box
                                sx={{
                                        fontFamily: "Poppins",
                                        background: "#ffffff",
                                        m: 0,
                                        p: 0,
                                        boxSizing: "border-box",
                                }}
                        >
                                <CardData3 />
                                <BookYourSpot />
                                <TextThree />
                        </Box>
                        <Box
                                sx={{
                                        fontFamily: "Poppins",
                                        background: "#053F76",
                                        m: 0,
                                        boxSizing: "border-box",
                                }}
                        >
                                <br />
                                <TextNine />
                                <TextTen />
                                <br />
                                <CertificateImage />
                                <br />
                                <BookYourSpot />
                                <TextThree1 />
                        </Box>
                        <Box
                                sx={{
                                        fontFamily: "Poppins",
                                        background: "#ffffff",
                                        m: 0,
                                        p: 0,
                                        boxSizing: "border-box",
                                }}
                        >
                                <br />
                                <br />
                                <TextA />
                                <Topics1 />
                                <br />
                                <BookYourSpot />
                                <TextThree />
                                <br />
                                <FaqHeading />
                                <Faq />
                                <br />
                                <BookYourSpot />
                                <TextThree />
                        </Box>
                        <Box
                                sx={{
                                        fontFamily: "Poppins",
                                        background: "#053F76",
                                        m: 0,
                                        p: 0,
                                        boxSizing: "border-box",
                                }}
                        >
                                <Footer />
                        </Box>
                </Box>
        );
}
