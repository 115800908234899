import { Box, Typography, Grid } from '@mui/material';
import React from 'react';
import Image from "../Vault/Our_Services_img.png";
import WebDesignIcon from "../Vault/WD_icon.png";
import WebDevelopmentIcon from "../Vault/WDev_icon.png";
import MobileAppDevIcon from "../Vault/MobDev_icon.png";
import ECommerceSolutionsIcon  from "../Vault/ES_icon.png";
import DigitalMarketingIcon from "../Vault/DgMr_icon.png";
import MultimediaSolutionsIcon from "../Vault/MulMedia_icon.png";
import { useTheme,useMediaQuery } from '@mui/material';

export default function OurServices() {

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));
    const iPad = useMediaQuery(theme.breakpoints.down(1200));

    return (
        <Box sx={{
            padding: mobile?"50px 10px":10
        }}>
            <Grid container spacing={2} style={{marginBottom:20}}>
                <Grid item xs={12} lg={8}>
                    <Grid container spacing={2} >
                        <Grid item xs={12}>
                            <Typography sx={{ fontFamily:"Montserrat",marginBottom:3, color: "#053F76", fontSize: mobile?20:42, fontWeight: 900,textAlign:mobile?"center":"left" }}>Our Services Offerings</Typography>
                        </Grid>
                        <Grid item xs={12} sx={{display:"flex",flexDirection:"row",alignItems:"center",borderBottom:"1px solid lightgrey",paddingBottom:2}}>
                            <img src={WebDesignIcon}  style={{width:mobile?"10%":"6%"}} />
                            <Typography sx={{ fontFamily:"Montserrat",marginLeft:mobile?1:4,textAlign:"center",color: "#053F76", fontSize: mobile?16:28, fontWeight: 600 }}>UI/UX Design</Typography>
                        </Grid>
                        <Grid item xs={12} sx={{display:"flex",flexDirection:"row",alignItems:"center",borderBottom:"1px solid lightgrey",paddingBottom:2}}>
                            <img src={WebDevelopmentIcon}  style={{width:mobile?"10%":"6%"}} />
                            <Typography sx={{ fontFamily:"Montserrat",marginLeft:mobile?1:4,textAlign:"center",color: "#053F76", fontSize: mobile?16:28, fontWeight: 600 }}>Website Development</Typography>
                        </Grid>
                        {/* <Grid item xs={12} sx={{display:"flex",flexDirection:"row",alignItems:"center",borderBottom:"1px solid lightgrey",paddingBottom:2}}>
                            <img src={ECommerceSolutionsIcon}  style={{width:mobile?"10%":"6%"}} />
                            <Typography sx={{ fontFamily:"Montserrat",marginLeft:mobile?1:4,textAlign:"center",color: "#053F76", fontSize: mobile?16:28, fontWeight: 600 }}>E-Commerce Solutions</Typography>
                        </Grid> */}
                        <Grid item xs={12} sx={{display:"flex",flexDirection:"row",alignItems:"center",borderBottom:"1px solid lightgrey",paddingBottom:2}}>
                            <img src={MobileAppDevIcon}  style={{width:mobile?"10%":"6%"}} />
                            <Typography sx={{ fontFamily:"Montserrat",marginLeft:mobile?1:4,textAlign:"center",color: "#053F76", fontSize: mobile?16:28, fontWeight: 600 }}>Mobile Application</Typography>
                        </Grid>
                        <Grid item xs={12} sx={{display:"flex",flexDirection:"row",alignItems:"center",borderBottom:"1px solid lightgrey",paddingBottom:2}}>
                            <img src={DigitalMarketingIcon}  style={{width:mobile?"10%":"6%"}} />
                            <Typography sx={{ fontFamily:"Montserrat",marginLeft:mobile?1:4,textAlign:"center",color: "#053F76", fontSize: mobile?16:28, fontWeight: 600 }}>Digital Marketing</Typography>
                        </Grid>
                        <Grid item xs={12} sx={{display:"flex",flexDirection:"row",alignItems:"center",borderBottom:"1px solid lightgrey",paddingBottom:2}}>
                            <img src={DigitalMarketingIcon}  style={{width:mobile?"10%":"6%"}} />
                            <Typography sx={{ fontFamily:"Montserrat",marginLeft:mobile?1:4,textAlign:"center",color: "#053F76", fontSize: mobile?16:28, fontWeight: 600 }}>Branding</Typography>
                        </Grid>
                        <Grid item xs={12} sx={{display:"flex",flexDirection:"row",alignItems:"center",borderBottom:"1px solid lightgrey",paddingBottom:2}}>
                            <img src={MultimediaSolutionsIcon}  style={{width:mobile?"10%":"6%"}} />
                            <Typography sx={{ fontFamily:"Montserrat",marginLeft:mobile?1:4,textAlign:"center",color: "#053F76", fontSize: mobile?16:28, fontWeight: 600 }}>Multimedia Solutions</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} item xs={12} lg={4}>
                    <img src={Image} style={{ width: mobile?300:600 }} />
                </Grid>
            </Grid>
        </Box>
    )
}