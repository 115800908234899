import React from 'react';
import Navbar from '../Sections/Navbar';
import { Container } from '@mui/material';
import Hero from '../Sections/Hero';
import CSD_DMS from '../Sections/CSD_DMS';
import OurServices from '../Sections/OurServices';
import Consultation from "../Sections/Consultation";
import OurWorks from '../Sections/OurWorks';
import WhyChooseIBUZZ from '../Sections/WhyChooseIBUZZ';
import FAQ from '../Sections/FAQ';
import TellUsAbout from '../Sections/TellUsAbout';
import Footer from '../Sections/Footer';

export default function Home1() {
    const scrollToSection1 = (id) =>{
        const section = document.getElementById(id);
        section.scrollIntoView({ behavior: 'smooth' });
       
      }
    return (
        <div style={{ overflowX: "hidden" }}>
            <h1 hidden>India Buzz</h1>
            <Navbar />
            <br /><br /><br /><br /><br />
            <Container maxWidth="xl">
                <Hero onChange={(value)=>{scrollToSection1(value)}} />
            </Container>
            <CSD_DMS />
            <Container maxWidth="xl">
                <OurServices />
            </Container>
            <Consultation />
            <Container id='ourwork'  maxWidth="xl">
                <OurWorks />
            </Container>
            <WhyChooseIBUZZ />
            <Container maxWidth="xl">
                <FAQ />
            </Container>
            <br />
            <br /><br />
            <br />
            <hr />
            <br /><br />
            {/* <Container maxWidth="xl">
                <TellUsAbout />
            </Container> */}
            <Footer />
        </div >
    )
};
