import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import { useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function ServicesBar() {
    const navigate = useNavigate();
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));
    const ipad = useMediaQuery(theme.breakpoints.down(1200));

    const handleClick = (event) => {
        navigate(`/${event}`);
    };

    const options = [
        { option: 'SEO Services', url: 'seoservice' },
        { option: 'SMO Services', url: 'smoservice' },
        { option: 'Google Ads', url: 'googleads' },
        { option: 'Branding', url: 'branding' },
        { option: 'Content Marketing', url: 'contentmarketing' },
        { option: 'Web Development', url: 'webdevelopmentservices' },
        { option: 'App Development', url: 'appdevelopment' },
        { option: 'Custom Software Development', url: 'customsoftwaredevelopment' },
    ];

    const showOptions = () => {
        return options.map((item) => {
            return (
                <div key={item.url}>
                    <Button sx={{fontFamily:"Montserrat",color:"#ffffff",fontSize:14,margin:"0px 2px","&:hover":{color:"#9cbccb"}}} onClick={() => handleClick(item.url)} value={item.url}>
                        {item.option}
                    </Button>
                </div>
            );
        });
    };

    return (
        <Box sx={{ flexGrow: 1, display: mobile?"none":ipad ? 'none' : 'block' ,marginTop:1}}>
            <AppBar position="static" sx={{ backgroundColor: '#053F76',pt:1}}>
                <Toolbar>
                    <Box
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '100%',
                        }}
                    >
                        {mobile ? '' : ipad ? '' : showOptions()}
                    </Box>
                </Toolbar>
            </AppBar>
        </Box>
    );
};