import { Box, Button, Grid } from '@mui/material'
import React,{useState} from 'react';

export default function Accor({items}) {
    
        const [activeIndex, setActiveIndex] = useState(null);
      
        const onItemClick = (index) => {
          setActiveIndex(index);
        };
      
        const renderedItems = items.map((item, index) => {
          const isActive = index === activeIndex;
          const itemClassName = isActive ? 'accordion-item active' : 'accordion-item';


  return (
    
      <div key={index} className={itemClassName}>
        <div  onClick={() => onItemClick(index)}>
          <Box  style={{ backgroundColor: "#34495e", width: "80%",color:"#fff",marginInlineStart:35,margin:5,marginBottom:10,fontWeight:'bold'}}>{item.title}</Box>
          {/* <span className="accordion-icon"></span> */}
        </div>
        {isActive && (
          <div >
            <p style={{fontSize:'12'}}>{item.content}</p>
          </div>
        )}
      </div>
    );
  });
  return <div >{renderedItems}</div>;
    
  
}

