import { Box, Button, Container, Grid, Typography } from '@mui/material';
import React from 'react';
import Image from "../Vault/Discuss.png";
import { useTheme, useMediaQuery } from '@mui/material';

export default function Consultation() {

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));
    const iPad = useMediaQuery(theme.breakpoints.down(1200));

    return (
        <Box sx={{ background: "#FFDE00" }}>
            <Container sx={{
                display: "flex",
                flexDirection: mobile ? "column" : iPad ? "row" : "row",
                justifyContent: { xs: "center", md: "space-between" },
                alignItems: "center",
                padding: iPad ? 5 : 12,
                paddingBottom: { xs: 0, md: 0 }
            }} maxWidth={"xl"}>
                <Box sx={{ display: 'flex', justifyContent: "center",alignItems:mobile?"center":"start7", flexDirection: "column", marginTop: iPad ? 0 : -15, mr: iPad ? 2 : 0,marginLeft:mobile?0:10 }}>
                    <Typography sx={{fontFamily:"Montserrat", color: "#053F76", fontSize: mobile ? 20 : iPad ? 26 : 40, fontWeight: 900, textAlign: mobile ? "center" : "left" }}>
                        Let's Explore Your Project
                    </Typography>
                    {mobile ? <><br /></> :
                        <><br />
                            <br /></>}
                    <Typography sx={{ fontFamily:"Montserrat",color: "#053F76", fontSize: mobile ? 12 : iPad ? 16 : 16, fontWeight: iPad ? 500 : 600,textAlign:mobile?"center":iPad?"left":"left" }}>
                        {mobile?"Get free consultation and let":"Get free consultation and let us know"}
                    </Typography>
                    <Typography sx={{fontFamily:"Montserrat", color: "#053F76", fontSize: mobile ? 12 : iPad ? 16 : 16, fontWeight: iPad ? 500 : 600,textAlign:mobile?"center":iPad?"left":"left" }}>
                       {mobile?"us know your project idea to turn it":"your project idea to turn it into an"}
                    </Typography>
                    <Typography sx={{fontFamily:"Montserrat", color: "#053F76", fontSize: mobile ? 12 : iPad ? 16 : 16, fontWeight: iPad ? 500 : 600,textAlign:mobile?"center":iPad?"left":"left" }}>
                        {mobile?"into an amazing digital product.":"amazing digital product."}
                    </Typography>
                    {mobile ? <><br /></> :
                        <><br />
                            <br /></>}
                    <Button sx={{
                        backgroundColor: "#053F76",fontFamily:"Montserrat", width: mobile ? "50%" : iPad ? "60%" : "100%", padding: iPad ? "5px 5px" : "8px 10px", fontSize: mobile ? 10 : iPad ? 16 : 20, color: "#ffffff", fontWeight: 600, "&:hover": {
                            background: "#053F76",
                            color: "#9cbccb"
                        }
                    }} href="https://wa.link/clvo86" target='_blank'
                    >GET CONSULTATION</Button>
                </Box>
                {mobile ? <><br /><br /></> :
                        <></>}
                <img src={Image} style={{ width: mobile ? "80%" : "30%",marginRight:mobile?0:100 }} />
            </Container>
        </Box>
    )
}