import headerbg from '../../assets/headerbg (7).png'
import logo from  '../../assets/ratiram.webp'
import contactCs from '../../assets/RatiramGajakBhandar.vcf'
import QR from '../../assets/download.webp'
import bgImage from '../../assets/back.webp'
import image1 from '../../assets/ratiram1.jpeg'

export const RatiramContent = {
  
  hero: {
    headerImage:headerbg,
    logoImage:logo,
    name: "Ratiram Gajak Bhandar",
    phoneNumber:"+918770328575",
    phoneNumber1:'',
    phoneNumber2:'+919826575328',
    phoneNumber3:'',
    whatsappLink:"https://api.whatsapp.com/send/?phone=919826575328&text=Message+from+Your+Digital+Card&type=phone_number&app_absent=0",
    mailId:' labhanshrathore3@gmail.com',
    googleMapLink:'https://goo.gl/maps/vSySvqfHwSTtmEz18',
    pageHref:'https://asapdelivery.in/ratiramgajakbhandar',
    fbLink:'https://www.facebook.com/sharer/sharer.php?u=https://asapdelivery.in/ratiramgajakbhandar',
    twitterLink:'https://twitter.com/intent/tweet/?url=https://asapdelivery.in/ratiramgajakbhandar',
    linkdnLink:'https://www.linkedin.com/shareArticle/?mini=true&url=https://asapdelivery.in/ratiramgajakbhandar',
    instaLink:'https://www.instagram.com/share?url=https://asapdelivery.in/ratiramgajakbhandar',
    pinterestLink:'https://www.pinterest.com/pin/create/button/?url=https://asapdelivery.in/ratiramgajakbhandar',
    telegramLink:'https://t.me/share/url?url=https://asapdelivery.in/ratiramgajakbhandar',
    smsBody:'https://asapdelivery.in/ratiramgajakbhandar',
    title: "Ratiram Gajak Bhandar",
    titleLink: "https://asapdelivery.in/ratiramgajakbhandar",
    customWhatsappText:'https://asapdelivery.in/ratiramgajakbhandar',
    address1:' Ratiram Gajak Bhandar, Nai Sadak, near Delight Cinema,',
    address2:'   Lashkar, Gwalior,',
    address3:'  Madhya Pradesh 474001',
    contactFile:contactCs,
    textColor:'#000',
    
    Product1: [
      {
        id: 1,
       
        image:
          "https://image3.jdomni.in/banner/25092021/17/0F/E9/5DB2C09F256B0B2F3564B43B10_1632592281093.jpeg?output-format=webp",
      },
      {
        id: 5,
        image:image1
,
      },
      {
        id: 2,
        image:
          "https://image3.jdomni.in/banner/27052021/2F/53/80/D695237F3AE7CAB51C00733484_1622129113081.png?output-format=webp",
      },
      {
        id: 3,
        image:
          "https://image3.jdomni.in/banner/20102021/AA/9B/D6/BE7BE430712BC6EA8B76D625E0_1634669624485.png?output-format=webp",
      },
      {
        id: 4,
        image:
          "https://image3.jdomni.in/banner/26092021/D1/A1/7F/B7CDEF56BA3A5E439C84A7B095_1632660394768.png?output-format=webp",
      },
      
    
      
    ],
    buttonColor:'#EEE8AA',
    textColor:'#000',
    buttonContentColor:'#000',
  },
  about:{
    aboutContent:`At the end of the 19th century Gwalior Tashimia garden (currently Hari nirmal cinema) in Mrs. Kesharbai (Mother of Mr. Ratiram Ji  ) started to make the spleen and jaggery Gajak. Tilkuta Gajak were called to the slowly improving, many other experimental Gajaks began to be built, work on moving Kesharbai  sons began to collaborate in the work, in which his son-in-law Mr. Ratiram Gajak trigger construction work gained fame in the field.Inspired by the same time, the reputation of a British officer (which was Northern roadways company) happy to provide driver job in the company of Mr. Northern Sri Ratiram living alone now handle the business started and used by many many Gajak their cuisine has built a business of living increased after Mr. Ratiram.The only aim of this Ratiram Gajak stores Gajak Gwalior with more than 50 recipes of new dishes through innovation is to provide people All of which in keeping with the clean and pure taste, health and environment improving traditional methods to make the dishes Which are always remember our slogan, which is reflected in the taste That year Samvat 200 9 1 9 52 Gajak compiled Revdi license`,
    quality1:' Dry Fruit Chikki',
    quality2:'Till Papad',
    quality3:'Gud ki Khasta Gajak',
    quality4:'Dry Fruit Gud Gajak',
    mondayOpeningHours:'8:30 AM - 11:00 PM',
    tuesdayOpeningHours:'8:30 AM - 11:00 PM',
    wednesdayOpeningHours:'8:30 AM - 11:00 PM',
    thursdayOpeningHours:'8:30 AM - 11:00 PM',
    fridayOpeningHours:'8:30 AM - 11:00 PM',
    saturdayOpeningHours:'8:30 AM - 11:00 PM',
    sundayOpeningHours:'8:30 AM - 11:00 PM',
    buttonColor:'#EEE8AA',
    textColor:'#000',
    buttonContentColor:'#000',
   
   
  },
  product:{
    bgcolor:'',
    buttonColor:'#EEE8AA',
    buttonContentColor:'#000',textColor:'#000',
    category1:'Laddu',
    category2:'Gajak',
    category3:'Rewadi',
    category4:'Till Papad',
    category5:'Chikki',
    category6:'Dal Moth',
    category7:'',
    category8:'',
    category9:'',
    category10:'',
    category11:'',
    Product2 :[
      {
        id: 1,
        name: "Mawa Bati",
        status: "In Stock",
        image:
          "https://image1.jdomni.in/product/21092022/45/8C/CE/0D44DA968BA5D401EC613D60E8_1663759048589.jpg",
          whatsappLink:"https://api.whatsapp.com/send/?phone=919826575328&text=Query+about+Your+Mawa+Bati&type=phone_number&app_absent=0",
      },
      {
        id: 2,
        name: "Dryfruit Gajak Roll",
        status: "In Stock",
        image:
          "https://image1.jdomni.in/product/09102021/A6/7C/01/6E17FAE551CBD28BEE2CA69647_1633777591619.png",
          whatsappLink:"https://api.whatsapp.com/send/?phone=919826575328&text=Query+about+Your+Dryfruit Gajak Roll&type=phone_number&app_absent=0",
      },
      {
        id: 3,
        name: "Dryfruit Sugar Gajak",
        status: "In Stock",
        image:
          "https://image1.jdomni.in/product/09102021/30/86/AB/E523428EC05A4D9E0E2CC6AF2C_1633776636928.png",
          whatsappLink:"https://api.whatsapp.com/send/?phone=919826575328&text=Query+about+Your+Dryfruit Sugar Gajak&type=phone_number&app_absent=0",
          
      },
      {
        id: 4,
        name: "Dryfruit Gud Gajak",
        status: "In Stock",
        image:
          "https://image1.jdomni.in/product/09102021/F8/57/A3/8F1E89F819ED28EA5293B4945F_1633776388744.png",
          whatsappLink:"https://api.whatsapp.com/send/?phone=919826575328&text=Query+about+Your+Dryfruit Gud Gajak&type=phone_number&app_absent=0",
      },
      {
        id: 5,
        name: "Panchratan Till Barfi",
        status: "In Stock",
        image:
          "https://image1.jdomni.in/product/09102021/A7/FB/8F/6A8CDC6A287FA96AA0DB9B8D7F_1633775467491.png",
          whatsappLink:"https://api.whatsapp.com/send/?phone=919826575328&text=Query+about+Your+Panchratan Till Barfi&type=phone_number&app_absent=0",
      },

      {
        id: 6,
        name: "Gud Ki Khasta Gajak",
        status: "In Stock",
        image:
          "https://image1.jdomni.in/product/09102021/95/32/80/AE7C919478F4BB17EFAA676DAF_1633775323711.png",
          whatsappLink:"https://api.whatsapp.com/send/?phone=919826575328&text=Query+about+Your+Gud Ki Khasta Gajak&type=phone_number&app_absent=0",
      },
    ],
    Product3:[
      {
        id: 1,
        name: "Gud Chipps Rewadi",
        status: "In Stock",
        image:
          "https://image1.jdomni.in/product/09102021/34/E0/1F/ED217CF3A3D739E9E7CB3AAF06_1633777349121.png",
          whatsappLink:"https://api.whatsapp.com/send/?phone=919826575328&text=Query+about+Your+Gud Chipps Rewadi&type=phone_number&app_absent=0",
      },
      {
        id: 2,
        name: "Gud Ki Rewadi",
        status: "In Stock",
        image:
          "https://image1.jdomni.in/product/09102021/C3/C0/12/6CC03D95D991D5E49EAEF42365_1633777061691.png",
          whatsappLink:"https://api.whatsapp.com/send/?phone=919826575328&text=Query+about+Your+Gud Ki Rewadi&type=phone_number&app_absent=0",
      },
    ],
    Product4:[
      {
        id: 1,
        name: "Till Papad",
        status: "In Stock",
        image:
          "https://image1.jdomni.in/product/09102021/9C/9C/41/C7AA93953CDC12AE69B4F0CEE0_1633777811273.png",
          whatsappLink:"https://api.whatsapp.com/send/?phone=919826575328&text=Query+about+Your+Till Papad&type=phone_number&app_absent=0",
      },
    ],
    
     Product1: [
      {
        id: 1,
        name: "Gond Laddu",
        status: "In Stock",
        image:
          "https://image1.jdomni.in/product/21092022/7B/AE/27/73E9CD6FD4D1BC27D7974666FD_1663761237506.jpg",
          whatsappLink:"https://api.whatsapp.com/send/?phone=919826575328&text=Query+about+Your+Gond Laddu&type=phone_number&app_absent=0",
      },
      {
        id: 2,
        name: "Till Laddu",
        status: "In Stock",
        image:
          "https://image1.jdomni.in/product/21092022/AD/E6/16/28339734A2E6DE4B069AD2EE47_1663760693743.jpg",
          whatsappLink:"https://api.whatsapp.com/send/?phone=919826575328&text=Query+about+Your+Till Laddu&type=phone_number&app_absent=0",
      },
    
      
    ],
    Product5:[
      {
        id: 1,
        name: "Dryfruit Chikki",
        status: "In Stock",
        image:
          "https://image1.jdomni.in/product/21092022/90/B7/2A/07DC039722BCEC49F82300BE2B_1663761760196.jpg",
          whatsappLink:"https://api.whatsapp.com/send/?phone=919826575328&text=Query+about+Your+Dryfruit Chikki&type=phone_number&app_absent=0",
      },
      {
        id: 2,
        name: "Till Chikki",
        status: "In Stock",
        image:
          "https://image1.jdomni.in/product/09102021/0F/74/BF/0D61E9ABBFB2AB05FE5FC74928_1633778434707.png",
          whatsappLink:"https://api.whatsapp.com/send/?phone=919826575328&text=Query+about+Your+Till Chikki&type=phone_number&app_absent=0",

      },
      {
        id: 3,
        name: "Lashkari Chikki",
        status: "In Stock",
        image:
          "https://image1.jdomni.in/product/09102021/7E/B3/B6/0EE3C6E3BF0FA47FA75B7DAAC5_1633776197245.png",
          whatsappLink:"https://api.whatsapp.com/send/?phone=919826575328&text=Query+about+Your+Lashkari Chikki&type=phone_number&app_absent=0",

      },
      {
        id: 4,
        name: "Peanut Chikki",
        status: "In Stock",
        image:
          "https://image1.jdomni.in/product/09102021/A4/83/42/F32D024AD9505C1A77753A6328_1633775943368.png",
          whatsappLink:"https://api.whatsapp.com/send/?phone=919826575328&text=Query+about+Your+Peanut Chikki&type=phone_number&app_absent=0",

      },
    ],
    Product6:[
    
      {
        id: 1,
        name: "Lajawab Dalmoth",
        status: "In Stock",
        image:
          "https://image1.jdomni.in/product/09102021/1F/DE/F7/2C374E604584100260592F6E79_1633778059831.png",
          whatsappLink:"https://api.whatsapp.com/send/?phone=919826575328&text=Query+about+Your+Lajawab Dalmoth&type=phone_number&app_absent=0",

      },
      
    ],
    Product7:[
     
     
    ],
    Product8:[
    
    ],
    Product9:[
      
    ],
    Product10:[
    
    ],
    Product11 :[
      
    ],
    
    categories: [
     {
        id: 1,
        name: "All Types",
      },
      {
        id: 2,
        name: "Laddu",
      },
      {
        id: 3,
        name: " Gajak",
      },
      {
        id: 4,
        name: "Chikki",
      },
      {
        id: 5,
        name: "Namkeen",
      },
      {
        id: 6,
        name: "Rewadi",
      },
      {
        id: 7,
        name: "Papad",
      },
    ],
    whatsappLink:"https://api.whatsapp.com/send/?phone=917415164000&text=Message+from+Your+Digital+Card&type=phone_number&app_absent=0",
    bgImage:''
  },
  payment:{
    bankName:"PUNJAB NATIONAL BANK",
    name:'CHANDAN JEWELLERS',
    accountNumber:' 0291008700003505',
    ifscCode:'PUNB0029100',
    qrImage:QR
  },
  ourButton:{
    textColor:'#000',
  },
  review:{
    textColor:'#000',
  }
};
