import React,{useState,useEffect} from "react";
import { Button,Grid,TextField } from "@mui/material";
import { getData } from "../ApiServices/ServerServices";
import MaterialTable from "@material-table/core";
import {useStyles} from "./DisplayAllCategoryCss"
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { postData } from "../ApiServices/ServerServices";
import Swal from "sweetalert2";
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import { io } from 'socket.io-client'
import { useContext } from 'react';
import { SessionContext } from "../ApiServices/SessionContext";
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme} from "@mui/material/styles";
import addNotification from 'react-push-notification';

const Input = styled('input')({
  display: 'none',
});


export default function DisplayAllEnquries(props){  
  const { session,setSession } = useContext(SessionContext);
  var theme =useTheme()
  const classes=useStyles()   
  const matches=useMediaQuery(theme.breakpoints.down(700))
const[enquiries,setEnquiries]=useState([])
const[categoryId,setCategoryId]=useState('')
const[categoryName,setCategoryName]=useState('')
const[message,setMessage]=useState('')
    const[showBtn,setShowBtn]=useState(false)
const[open,setOpen]=useState(false)

const [socket,setSocket]=React.useState()


React.useEffect(() => {
  const socket = io("https://api4.buzz2web.in/");
  setSocket(socket);

  socket.on("connect", () => {
    console.log(socket.id);
    socket.emit("backend",'admin');
  });


}, []);
function buttonOnClick (message){
  addNotification({
    title: `${message}`,
    native:true         
  })
};

const handlNotification=()=>{
  Notification.requestPermission().then(perm=>{
    if(perm=== "granted")
    {
      new Notification("hello")
    }
  })
}

useEffect(function(){
  if(socket){
  socket.on("new", (Enquiry) => {
    if(window.localStorage.getItem("Session"))
    {
      handlNotification()
    }
});}
   fetchAllEnquiries()

},[socket])

function displayTable() {
    return (
      <MaterialTable
      title={"Category List"}
        data={enquiries}
        style={{}}
        columns={[
            {
              title: "Enquiry Id",
              field: "_id",
             
            },
            {
              title: " Name",
              field: "name",
            },
            {
              title: "Company Name",
              field: "companyName",
            },
            {
              title: "Phone Number",
              field: "number",
            },
            {
              title: " Email Id",
              field: "email",
            },
            {
              title: "Service Wanted",
              field: "service",
            },
            
           
        ]}
        actions={[
          
        
        ]}
       
      />
    );
  }



    const fetchAllEnquiries=async()=>{
        const result=await getData('enquiries/displayallenquiries')
        
        setEnquiries(result)
    }


    

    return(
      
        
        <Grid container spacing={2} style={{  display:"flex",
        justifyContent:'center',
        alignItems:'center'}}>
          <Grid item xs={12} sm={8} style={{marginTop:20,fontSize:matches?10:20}}>
        {displayTable()}</Grid>  </Grid>

    )
    
}