import { Box, Grid, Typography,TextField,Button } from "@mui/material";
import React from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";
import { postData } from "../../ApiServices/ServerServices";
import { useState } from "react";

export default function RegisterPage() {

        const navigate = useNavigate();

        const [fullName, setFullName] = useState("");
        const [mobileNumber, setMobileNumber] = useState("");
        const [emailId, setEmailId] = useState("");

        const handleSubmit = async()=>{
                let body = {fullname:fullName,mobilenumber:mobileNumber,emailid:emailId}
                let response = await postData("workshopregistration/addworkshopregistration",body,true);
                alert(response.status)
                navigate("/payment",{ state : { fullname : fullName, mobilenumber : mobileNumber, emailid : emailId } } ) 
        }

        const Heading = () => {
                return (
                        <Box sx={{ p: "30px 12px" }}>
                                <Typography
                                        sx={{
                                                textAlign: "left",
                                                fontSize: "22px",
                                                color: "#FFFFFF",
                                                fontWeight: 700,
                                                fontFamily: "Poppins",
                                        }}
                                >
                                        Congrats! You are just one step away from becoming a
                                </Typography>
                                <Typography
                                        sx={{
                                                textAlign: "left",
                                                fontSize: "22px",
                                                color: "#FFDE00",
                                                fontWeight: 700,
                                                fontFamily: "Poppins",
                                        }}
                                >
                                        Google Ads Expert
                                </Typography>
                        </Box>
                );
        };
        const Heading1 = () => {
                return (
                        <Box sx={{ p: "30px 12px" }}>
                                <Typography
                                        sx={{
                                                textAlign: "left",
                                                fontSize: "22px",
                                                color: "#000000",
                                                fontWeight: 700,
                                                fontFamily: "Poppins",
                                        }}
                                >
                                        Price For You : ₹99
                                </Typography>
                                <Typography
                                        sx={{
                                                textAlign: "left",
                                                fontSize: "16px",
                                                color: "#000000",
                                                fontWeight: 500,
                                                fontFamily: "Poppins",
                                        }}
                                >
                                        Normal Price <s>₹2000</s>
                                </Typography>
                        </Box>
                );
        };

        const Login =()=>{
                return(
                        <Box sx={{ p: "30px 12px" }}>
                               <Box sx={{background:"#ffffff",p:2}}>
                               <Typography
                                        sx={{
                                                textAlign: "left",
                                                fontSize: "22px",
                                                color: "#000000",
                                                fontWeight: 700,
                                                fontFamily: "Poppins",
                                        }}
                                >
                                        Register Form
                                </Typography>
                                <hr style={{backgroundColor:"#000000",color:"#000000"}} />
                                <br />
                                <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                                <TextField onChange={(event) => setFullName(event.target.value)} label={"Full Name"} fullWidth value={fullName} />
                                        </Grid>
                                        <Grid item xs={12}>
                                                <TextField onChange={(event) => setMobileNumber(event.target.value)} label={"Mobile Number"} fullWidth value={mobileNumber} />
                                        </Grid>
                                        <Grid item xs={12}>
                                                <TextField onChange={(event) => setEmailId(event.target.value)} label={"Email"} fullWidth value={emailId} />
                                        </Grid>
                                        <Grid item xs={12}>
                                        <Button
                                        fullWidth
                                        onClick={handleSubmit}
                                                sx={{
                                                        mt: 6,
                                                        display: "flex",
                                                        alignItems: "center",
                                                        flexDirection: "row",
                                                        textAlign: "left",
                                                        mt: 1,
                                                        p:"10px 20px",
                                                        background:"#ffde00",
                                                        color: "#053F76",
                                                        fontSize: "22px",
                                                        fontWeight: 700,
                                                        fontFamily: "Poppins",
                                                        textDecoration: "none",
                                                }}
                                        >
                                                 Next Step &nbsp;
                                                <ArrowForwardIcon sx={{ color: "#053F76", fontSize: 26 }} />
                                        </Button>
                                        </Grid>
                                </Grid>
                               </Box>
                        </Box>
                )
        }

        return (
                <Box sx={{ overflowX: "hidden",}}>
                        <Box
                                sx={{
                                        background: "#053F76",
                                        m: 0,
                                        p: 0,
                                        boxSizing: "border-box"
                                }}
                        >
                                <Heading />
                        </Box>
                        <Box
                                sx={{
                                        background: "#9cbccb",
                                        m: 0,
                                        p: 0,
                                        boxSizing: "border-box"
                                }}
                        >
                                <Heading1 />
                                <Login/>
                        </Box>
                </Box>

        );
}
