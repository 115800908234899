import headerbg from "../../assets/headerbg (7).png";
import logo from "./assets/gehnalogo.jpg";
import contactCs from "./assets/Darshit Traders.vcf";
import QR from "../../assets/download.webp";
import bgImage from "../../assets/back.webp";
import cpvcsdr1 from './assets/2.png'
import cpvcsdr2 from './assets/3.png'
import cpvcsdr3 from './assets/4.png'
import cpvcsdr4 from './assets/5.png'
import cpvcsdr5 from './assets/6.png'
import cpvcsdr6 from './assets/7.png'
import cpvcsdr7 from './assets/8.png'
import cpvcsdr8 from './assets/9.png'
import cpvcsdr9 from './assets/10.png'
import cpvcpipes1 from './assets/12.png'
import cpvcpipes2 from './assets/13.png'
import cpvcpipes3 from './assets/14.png'
import cpvcpipes4 from './assets/15.png'
import cpvcpipes5 from './assets/16.png'
import cpvcpipes6 from './assets/17.png'
import cpvcpipes7 from './assets/18.png'
import cpvcpipes8 from './assets/19.png'
import cpvcpipes9 from './assets/20.png'
import cpvcpipes10 from './assets/21.png'
import cpvcpipes11 from './assets/22.png'
import cpvcpipes12 from './assets/23.png'
import cpvcconcealed1 from './assets/25.png'
import cpvcconcealed2 from './assets/26.png'
import cpvcconcealed3 from './assets/27.png'
import cpvcconcealed4 from './assets/28.png'
import cpvcconcealed5 from './assets/29.png'
import cpvcconcealed6 from './assets/30.png'
import cpvcconcealed7 from './assets/31.png'
import cpvcconcealed8 from './assets/32.png'
import cpvcconcealed9 from './assets/33.png'
import cpvcmixer1 from './assets/35.png'
import cpvcmixer2 from './assets/36.png'
import cpvcmixer3 from './assets/37.png'
import cpvcmixer4 from './assets/38.png'
import cpvcmixer5 from './assets/39.png'
import cpvcmixer6 from './assets/40.png'
import cpvcmixer7 from './assets/41.png'
import cpvcmixer8 from './assets/42.png'
import cpvcmixer9 from './assets/43.png'
import cpvcmixer10 from './assets/44.png'
import bg1 from './assets/bg1.png'
import bg2 from './assets/bg2.png'
import bg3 from './assets/bg3.png'
import bg4 from './assets/bg4.png'




import Gehna from './assets/gehna.mp4'
import Gehna2 from './assets/gehna2.mp4'
export const RakshaPipesContent= {
    hero: {
        headerImage: headerbg,
        logoImage: logo,
        name: "Darshit Traders",
        phoneNumber: "+919713192207",
        phoneNumber1:"+918889944313",
        phoneNumber2: "",
        phoneNumber3: "",
        whatsappLink:
          "https://api.whatsapp.com/send/?phone=919425310099&text=Message+from+Your+Digital+Card&type=phone_number&app_absent=0",
        mailId: "jains.abhishek85@gmail.com",
        googleMapLink: "https://goo.gl/maps/YaqsarVxb6kMjNg97",
        pageHref: "http://www.darshittraders.com/",
        fbLink:
          "https://www.facebook.com/sharer/sharer.php?u=https://indiabuzz.co.in/darshittraders",
        twitterLink:
          "https://twitter.com/intent/tweet/?url=https://indiabuzz.co.in/darshittraders",
        linkdnLink:
          "https://www.linkedin.com/shareArticle/?mini=true&url=https://indiabuzz.co.in/darshittraders",
        instaLink:
          "https://www.instagram.com/share?url=https://indiabuzz.co.in/darshittraders",
        pinterestLink:
          "https://www.pinterest.com/pin/create/button/?url=https://indiabuzz.co.in/darshittraders",
        telegramLink:
          "https://t.me/share/url?url=https://indiabuzz.co.in/darshittraders",
        smsBody: "https://indiabuzz.co.in/darshittraders",
        title: "Gehna Jeweller",
        titleLink: "https://indiabuzz.co.in/darshittraders",
        customWhatsappText: "https://indiabuzz.co.in/darshittraders",
        address1: "Near Dargah",
        address2: "Parking No.6,Transport Nagar Gwalior 474010",
        address3: "  Madhya Pradesh",
        contactFile: contactCs,
        textColor:'white',
    
        Product1: [
          {
            id: 1,
           
            image:bg1,
          },
         
          {
            id: 2,
            image:bg2,
          },
          {
            id: 3,
            image:bg3,
          },
          {
            id: 4,
            image:bg4,
          },
        
          
        ],
        buttonColor: "#EEE8AA",
    
        buttonContentColor: "#000",
      },
      about: {
        aboutContent:
          "The assurance of quality that Darshit Traders provides is also important, as it helps to give customers peace of mind when making a purchase. Knowing that the PVC pipes are of high quality and can be trusted for their intended use can be a big factor in the decision to purchase from them.Ultimately, when looking for PVC pipes, it's important to consider factors such as quality, durability, price, and the reputation of the supplier. Darshit Traders may be a good place to start, but it's always a good idea to do some research and compare options before making a final decision.",
        quality1: "CPVC",
        quality2: "UPVC",
        quality3: "SWR",
        quality4: "PVC",
        mondayOpeningHours: "11:00 AM - 09:00 PM",
        tuesdayOpeningHours: "11:00 AM - 07:00 PM",
        wednesdayOpeningHours: "11:00 AM - 09:00 PM",
        thursdayOpeningHours: "11:00 AM - 09:00 PM",
        fridayOpeningHours: "11:00 AM - 09:00 PM",
        saturdayOpeningHours: "11:00 AM - 09:00 PM",
        sundayOpeningHours: "11:00 AM - 09:00 PM",
        buttonColor: "#EEE8AA",
        buttonContentColor: "#000",
        textColor:'white',
      },
      product: {
        buttonColor: "lightsky",
        buttonContentColor: "white",
        category1:'CPVC SDR 11 / 13.5 Pipes and Fittings',
        category2:'CPVC Pipes in Sch 40 and 80 ',
        category3:'CPVC Concealed Valve',
        category4:'CPVC Mixer Adaptor 6" & 7"',
        category5:'',
        category6:'',
        category7:'',
        category8:'',
        category9:'',
        category10:'',
        Product1: [ {
          id: 1,
          name: "SDR - 11 (3 Mtr. Length)",
          status: "In Stock",
          image:cpvcsdr1,
          whatsappLink:
          `https://api.whatsapp.com/send/?phone=918889944313&text=Query+about+SDR - 11 (3 Mtr. Length)&type=phone_number&app_absent=0`,
        },
        {
          id: 2,
          name: "Reducer Coupler",
          status: "In Stock",
          image:cpvcsdr2,
          whatsappLink:
          `https://api.whatsapp.com/send/?phone=918889944313&text=Query+about+Reducer Coupler&type=phone_number&app_absent=0`,
        },
        {
          id: 3,
          name: "Elbow 90 Degree",
          status: "In Stock",
          image:cpvcsdr3,
          whatsappLink:
          `https://api.whatsapp.com/send/?phone=918889944313&text=Query+about+Elbow 90 Degree&type=phone_number&app_absent=0`,
        },
        {
          id: 4,
          name: "Equal Tee",
          status: "In Stock",
          image:cpvcsdr4,
          whatsappLink:
          `https://api.whatsapp.com/send/?phone=918889944313&text=Query+about+Equal Tee&type=phone_number&app_absent=0`,
        },
        {
          id: 4,
          name: "Ball Valve SDR 11",
          status: "In Stock",
          image:cpvcsdr5,
          whatsappLink:
          `https://api.whatsapp.com/send/?phone=918889944313&text=Query+about+Ball Valve SDR 11&type=phone_number&app_absent=0`,
        },
        {
          id: 4,
          name: "Tank Nipple",
          status: "In Stock",
          image:cpvcsdr6,
          whatsappLink:
          `https://api.whatsapp.com/send/?phone=918889944313&text=Query+about+Tank Nipple&type=phone_number&app_absent=0`,
        },
        {
          id: 4,
          name: "Elbow 45 Degree",
          status: "In Stock",
          image:cpvcsdr7,
          whatsappLink:
          `https://api.whatsapp.com/send/?phone=918889944313&text=Query+about+Elbow 45 Degree&type=phone_number&app_absent=0`,
        },
        {
          id: 4,
          name: "Reducer Elbow 90 Degree",
          status: "In Stock",
          image:cpvcsdr8,
          whatsappLink:
          `https://api.whatsapp.com/send/?phone=918889944313&text=Query+about+Reducer Elbow 90 Degree&type=phone_number&app_absent=0`,
        },
        {
          id: 4,
          name: "Elbow 90 Degree(Brass)",
          status: "In Stock",
          image:cpvcsdr9,
          whatsappLink:
          `https://api.whatsapp.com/send/?phone=918889944313&text=Query+about+Elbow 90 Degree(Brass)&type=phone_number&app_absent=0`,
        },
    ],
        Product2: [
         
            {
            id: 1,
            name: "Coupler",
            status: "In Stock",
            image:cpvcpipes1,
            whatsappLink:
            `https://api.whatsapp.com/send/?phone=918889944313&text=Query+about+Coupler&type=phone_number&app_absent=0`,
          },
          {
            id: 1,
            name: "Pipe in Sch-40 (3 & 5 Mtr. Length)",
            status: "In Stock",
            image:cpvcsdr1,
            whatsappLink:
            `https://api.whatsapp.com/send/?phone=918889944313&text=Query+about+Pipe in Sch-40 (3 & 5 Mtr. Length)&type=phone_number&app_absent=0`,
          },
          {
            id: 1,
            name: "Pipe in Sch-80 (3 & 5 Mtr. Length)",
            status: "In Stock",
            image:cpvcsdr1,
            whatsappLink:
            `https://api.whatsapp.com/send/?phone=918889944313&text=Query+about+Pipe in Sch-80 (3 & 5 Mtr. Length)&type=phone_number&app_absent=0`,
          },
          {
            id: 2,
            name: "Equal Tee",
            status: "In Stock",
            image:cpvcpipes2,
            whatsappLink:
            `https://api.whatsapp.com/send/?phone=918889944313&text=Query+about+Equal Tee&type=phone_number&app_absent=0`,
          },
          {
            id: 3,
            name: "End Cap",
            status: "In Stock",
            image:cpvcpipes3,
            whatsappLink:
            `https://api.whatsapp.com/send/?phone=918889944313&text=Query+about+End Cap&type=phone_number&app_absent=0`,
          },
          {
            id: 4,
            name: "Female Adaptor Plastic Threaded (FAPT)",
            status: "In Stock",
            image:cpvcpipes4,
            whatsappLink:
            `https://api.whatsapp.com/send/?phone=918889944313&text=Query+about+Female Adaptor Plastic Threaded (FAPT)&type=phone_number&app_absent=0`,
          },
          {
            id: 5,
            name: "Elbow 45 Degree",
            status: "In Stock",
            image:cpvcpipes5,
            whatsappLink:
            `https://api.whatsapp.com/send/?phone=918889944313&text=Query+about+Elbow 45 Degree&type=phone_number&app_absent=0`,
          },
          {
            id: 6,
            name: "Elbow 90 Degree",
            status: "In Stock",
            image:cpvcpipes6,
            whatsappLink:
            `https://api.whatsapp.com/send/?phone=918889944313&text=Query+about+Elbow 90 Degree&type=phone_number&app_absent=0`,
          },
          {
            id: 7,
            name: "Reducing Bush",
            status: "In Stock",
            image:cpvcpipes7,
            whatsappLink:
            `https://api.whatsapp.com/send/?phone=918889944313&text=Query+about+Reducing Bush&type=phone_number&app_absent=0`,
            
          },
          {
            id: 7,
            name: "Male Adaptor Plastic Threaded (MAPT)",
            status: "In Stock",
            image:cpvcpipes8,
            whatsappLink:
            `https://api.whatsapp.com/send/?phone=918889944313&text=Query+about+Male Adaptor Plastic Threaded (MAPT)&type=phone_number&app_absent=0`,
            
          },
          {
            id: 7,
            name: "Flange Open",
            status: "In Stock",
            image:cpvcpipes9,
            whatsappLink:
            `https://api.whatsapp.com/send/?phone=918889944313&text=Query+about+Flange Open&type=phone_number&app_absent=0`,
            
          },
          {
            id: 7,
            name: "Flange Close",
            status: "In Stock",
            image:cpvcpipes10,
            whatsappLink:
            `https://api.whatsapp.com/send/?phone=918889944313&text=Query+about+Flange Close&type=phone_number&app_absent=0`,
            
          },
          {
            id: 7,
            name: "Rubber Washer",
            status: "In Stock",
            image:cpvcpipes11,
            whatsappLink:
            `https://api.whatsapp.com/send/?phone=918889944313&text=Query+about+Rubber Washer&type=phone_number&app_absent=0`,
            
          },
          {
            id: 7,
            name: "Rubber O Ring",
            status: "In Stock",
            image:cpvcpipes12,
            whatsappLink:
            `https://api.whatsapp.com/send/?phone=918889944313&text=Query+about+Rubber O Ring&type=phone_number&app_absent=0`,
            
          },
    
        ],
        Product3: [
        
          {
            id: 1,
            name: "Concealed Triangle Valve",
            status: "In Stock",
            image:cpvcconcealed1,
            whatsappLink:
            `https://api.whatsapp.com/send/?phone=918889944313&text=Query+about+Concealed Triangle Valve&type=phone_number&app_absent=0`,
          },
          {
            id: 2,
            name: "Concealed Square Valve",
            status: "In Stock",
            image:cpvcconcealed2,
            whatsappLink:
            `https://api.whatsapp.com/send/?phone=918889944313&text=Query+about+Concealed Square Valve&type=phone_number&app_absent=0`,
          },
          {
            id: 3,
            name: "Concealed Valve Brass Tee",
            status: "In Stock",
            image:cpvcconcealed3,
            whatsappLink:
            `https://api.whatsapp.com/send/?phone=918889944313&text=Query+about+Concealed Valve Brass Tee&type=phone_number&app_absent=0`,
            
          },
          {
            id: 4,
            name: "Brass Mechanism - Short Length",
            status: "In Stock",
            image:cpvcconcealed4,
            whatsappLink:
            `https://api.whatsapp.com/send/?phone=918889944313&text=Query+about+Brass Mechanism - Short Length&type=phone_number&app_absent=0`,
          },
          {
            id: 4,
            name: "Brass Mechanism - Long Length",
            status: "In Stock",
            image:cpvcconcealed5,
            whatsappLink:
            `https://api.whatsapp.com/send/?phone=918889944313&text=Query+about+Brass Mechanism - Long Length&type=phone_number&app_absent=0`,
          },
          {
            id: 4,
            name: "Extension Tube",
            status: "In Stock",
            image:cpvcconcealed6,
            whatsappLink:
            `https://api.whatsapp.com/send/?phone=918889944313&text=Query+about+Extension Tube&type=phone_number&app_absent=0`,
          },
          {
            id: 4,
            name: "Flange with Rubber Gasket",
            status: "In Stock",
            image:cpvcconcealed7,
            whatsappLink:
            `https://api.whatsapp.com/send/?phone=918889944313&text=Query+about+Flange with Rubber Gasket&type=phone_number&app_absent=0`,
          },
          {
            id: 4,
            name: "Screw with Washer for Knobs",
            status: "In Stock",
            image:cpvcconcealed8,
            whatsappLink:
            `https://api.whatsapp.com/send/?phone=918889944313&text=Query+about+Screw with Washer for Knobs&type=phone_number&app_absent=0`,
          },
          {
            id: 4,
            name: "Red and Blue Buttons",
            status: "In Stock",
            image:cpvcconcealed9,
            whatsappLink:
            `https://api.whatsapp.com/send/?phone=918889944313&text=Query+about+Red and Blue Buttons&type=phone_number&app_absent=0`,
          },
    
        ],
        Product4: [
          
          {
            id: 1,
            name: "3 in 1 Mixer Adaptor All Top (6') ",
            status: "In Stock",
            image:cpvcmixer1,
            whatsappLink:
            `https://api.whatsapp.com/send/?phone=918889944313&text=Query+about+3 in 1 Mixer Adaptor All Top (6')&type=phone_number&app_absent=0`,
          },
          {
            id: 2,
            name: "Top And Bottom (6')",
            status: "In Stock",
            image:cpvcmixer2,
            whatsappLink:
            `https://api.whatsapp.com/send/?phone=918889944313&text=Query+about+Top And Bottom (6') &type=phone_number&app_absent=0`,
          },
          {
            id: 3,
            name: "Top And Slide (6')",
            status: "In Stock",
            image:cpvcmixer3,
            whatsappLink:
            `https://api.whatsapp.com/send/?phone=918889944313&text=Query+about+Top And Slide (6') &type=phone_number&app_absent=0`,
          },
          {
            id: 4,
            name: "Hot Up and Cold Down (6')",
            status: "In Stock",
            image:cpvcmixer4,
            whatsappLink:
            `https://api.whatsapp.com/send/?phone=918889944313&text=Query+about+Hot Up and Cold Down (6') &type=phone_number&app_absent=0`,
          },
          {
            id: 4,
            name: "Hot Side and Cold Down (6')",
            status: "In Stock",
            image:cpvcmixer5,
            whatsappLink:
            `https://api.whatsapp.com/send/?phone=918889944313&text=Query+about+Hot Side and Cold Down (6') &type=phone_number&app_absent=0`,
          },
          {
            id: 4,
            name: "Kitchen Mixer Adaptor Down",
            status: "In Stock",
            image:cpvcmixer6,
            whatsappLink:
            `https://api.whatsapp.com/send/?phone=918889944313&text=Query+about+Kitchen Mixer Adaptor Down &type=phone_number&app_absent=0`,
          },
          {
            id: 4,
            name: "3 in 1 Mixer Adaptor All Top (7') ",
            status: "In Stock",
            image:cpvcmixer7,
            whatsappLink:
            `https://api.whatsapp.com/send/?phone=918889944313&text=Query+about+3 in 1 Mixer Adaptor All Top (7') &type=phone_number&app_absent=0`,
          },
          {
            id: 4,
            name: "Top And Bottom (7')",
            status: "In Stock",
            image:cpvcmixer8,
            whatsappLink:
            `https://api.whatsapp.com/send/?phone=918889944313&text=Query+about+Top And Bottom (7')&type=phone_number&app_absent=0`,
          },
          {
            id: 4,
            name: "Hot Up and Cold Down (7')",
            status: "In Stock",
            image:cpvcmixer9,
            whatsappLink:
            `https://api.whatsapp.com/send/?phone=918889944313&text=Query+about+Hot Up and Cold Down (7')&type=phone_number&app_absent=0`,
          },
          {
            id: 4,
            name: "Hot Side and Cold Down (7')",
            status: "In Stock",
            image:cpvcmixer10,
            whatsappLink:
            `https://api.whatsapp.com/send/?phone=918889944313&text=Query+about+Hot Side and Cold Down (7') &type=phone_number&app_absent=0`,
          },
        ],
    
       
        Product5: [
          
       
        ],
        Product6: [
          
        ],
        Product7: [
         
        ],
        Product8: [
          
        ],
        Product9: [
          
        ],
        Product10: [
          
        ],
        Product11: [
         
        ],
    
        categories: [
          {
            id: 1,
            name: "All Types",
          },
          {
            id: 2,
            name: "Gems & Stone",
          },
          {
            id: 3,
            name: "Diamond Necklace",
          },
          {
            id: 4,
            name: "Diamond Rings",
          },
          {
            id: 5,
            name: "Diamond Ear-Rings",
          },
          {
            id: 6,
            name: "Gold Necklace",
          },
          {
            id: 7,
            name: "Gold Bangles",
          },
          {
            id: 8,
            name: "Gold Ear-Rings",
          },
          {
            id: 9,
            name: "Gold Rings",
          },
          {
            id: 10,
            name: "Silver Bichiye",
          },
          {
            id: 11,
            name: "Silver Payal",
          },
          {
            id: 12,
            name: "Silver Rings",
          },
        ],
        whatsappLink:
          "https://api.whatsapp.com/send/?phone=918889944313&text=Message+from+Your+Digital+Card&type=phone_number&app_absent=0",
        bgImage: "",
        textColor:'white',
      },
      payment: {
       
      },
      ourButton:{
        textColor:'white',
      },
      review:{
        textColor:'white',
      }
};
