import React from 'react';
import { Box, Grid, TextField, Button, Typography, useTheme, useMediaQuery } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { postData } from '../../ApiServices/ServerServices';
import Swal from 'sweetalert2';
export default function TellUsAbout() {

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));
    const ipad = useMediaQuery(theme.breakpoints.down(1200));
    const [service, setService] = React.useState("");
    const [name, setName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [number, setNumber] = React.useState("");
    const [companyName, setCompanyName] = React.useState("");


    const handleClick = async () => {
        var formData = new FormData()


        formData.append('service', service)
        formData.append('name', name)
        formData.append('email', email)
        formData.append('number', number)
        formData.append('companyName', companyName)




        var result = await postData('enquiries/addenquiry', formData, true)
        console.log(result)
        if (result.status) {
                Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Enquiry Has Been Send.We will contact You Soon',
                        showConfirmButton: false,
                        timer: 1500
                })
        }
        else {
                Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'Fail to Send the Enquiry',
                        showConfirmButton: false,
                        timer: 1500
                })

        }



}


    return (
        <Box sx={{padding:mobile?"10px 20px":"30px 50px"}}>
            <Typography sx={{ fontFamily:"Montserrat",color: "#053F76", fontWeight: 900, fontSize: mobile?24:ipad?38:54, textAlign: "center" }}>Tell Us About Your Project</Typography>
            <Grid sx={{padding:mobile?"30px 10px 80px ":ipad?"30px 100px 40px":"30px 300px"}} container spacing={2}>
                <Grid item xs={12}>
                    <TextField fullWidth value={name} onChange={(e) => setName(e.target.value)} label="Name"sx={{fontFamily:"Montserrat"}} />
                </Grid>
                <Grid item xs={12}>
                    <TextField fullWidth label="Email Address" value={email} onChange={(e) => setEmail(e.target.value)} sx={{fontFamily:"Montserrat"}} />
                </Grid>
                <Grid item xs={12}>
                    <TextField fullWidth label="Phone Number" sx={{fontFamily:"Montserrat"}} value={number} onChange={(e) => setNumber(e.target.value)} />
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth sx={{fontFamily:"Montserrat"}}>
                        <InputLabel id="demo-simple-select-label">What Service do you want ?</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="What Service do you want ?"
                            value={service}
                            onChange={(e) => setService(e.target.value)}
                        >
                            <MenuItem value={"MVP Development"}>MVP Development</MenuItem>
                            <MenuItem value={"Static Website Development"}>Static Website Development</MenuItem>
                            <MenuItem value={"Dynamic Website Development"}>Dynamic Website Development</MenuItem>
                            <MenuItem value={"Website Design"}>Website Design</MenuItem>
                            <MenuItem value={"Digital Marketing"}>Digital Marketing</MenuItem>
                            <MenuItem value={"App Development"}>App Development</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <TextField sx={{fontFamily:"Montserrat"}} value={companyName} onChange={(e) => setCompanyName(e.target.value)} fullWidth label="Enter the Company Name" />
                </Grid>
                <Grid item xs={12} sx={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                    <Button sx={{fontFamily:"Montserrat",color:"#000000",background:"#FFDE00",padding:"10px 50px",fontSize:mobile?16:18,boxShadow:"0px 9px 14px 3px lightgrey","&:hover":{
                        background:"#053F76",
                        color:"#FFDE00"
                    }}} onClick={()=>handleClick()}>SUBMIT</Button>
                </Grid>
            </Grid>
        </Box>
    )
}
