import { makeStyles } from '@mui/styles';

 const  useStyles = makeStyles({

    hover:{
        "&:hover": {
            transform: 'scale(1.1)'
          },
    },
  
  });
  

 export {useStyles}