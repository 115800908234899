import { Box, Typography,Container, useTheme, useMediaQuery } from "@mui/material";
import React from "react";
import Image from "../Vault/SD-DMA.png";

export default function CSD_DMS() {

    // MEDIA QUERY VARIABLE
    const theme = useTheme();
    const small = useMediaQuery(theme.breakpoints.down("sm"));
    const medium = useMediaQuery(theme.breakpoints.down(1200));

    return (
        <Box
            sx={{
                background: "#053F76",
                padding:small?"40px 0px":"0px"
            }}
        >
            <Container sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                justifyContent: { xs: "center", md: "space-between" },
                alignItems: "center",
                paddingTop: { xs: 0, md: 0 },
                paddingLeft: { xs: 0, md: 10 },
                paddingRight: { xs: 0, md: 10 },
                paddingBottom:{xs:0,md:0}
            }} maxWidth="xl">
                <img src={Image} style={{display:small?"none":medium?"none":"block", width: "40%" }} />
                <Box sx={{ml:{xs:0,md:15},mt:medium?10:small?5:0,marginBottom:0}}>
                    {small?
                        <><Typography sx={{ fontFamily:"Montserrat",display:"block",fontSize:20,color:"#ffffff",fontWeight:900,textAlign:{xs:"center",md:"left"}}}>Full Stack Solutions</Typography>
                        <Typography sx={{fontFamily:"Montserrat",display:"block",fontSize:20,color:"#ffffff",fontWeight:900,textAlign:{xs:"center",md:"left"}}}>Web Development To Digital Marketing</Typography></>
                    :
                    medium?
                    <><Typography sx={{fontFamily:"Montserrat", display:"block",fontSize:30,color:"#ffffff",fontWeight:900,textAlign:{xs:"center",md:"left"}}}>Full Stack Solutions</Typography>
                    <Typography sx={{fontFamily:"Montserrat",display:"block",fontSize:30,color:"#ffffff",fontWeight:900,textAlign:{xs:"center",md:"left"}}}>Web Development To Digital Marketing</Typography></>
                    :
                    <><Typography sx={{fontFamily:"Montserrat",display:"block",fontSize:40,color:"#ffffff",fontWeight:900,textAlign:"left"}}>Full Stack Solutions</Typography>
                    <Typography sx={{fontFamily:"Montserrat",display:"block",fontSize:40,color:"#ffffff",fontWeight:900,textAlign:"left"}}>Web Development To </Typography>
                    <Typography sx={{fontFamily:"Montserrat",display:"block",fontSize:40,color:"#ffffff",fontWeight:900,textAlign:"left"}}>Digital Marketing</Typography></>
                    }
                    
                    
                    <Typography sx={{fontFamily:"Montserrat",fontSize:small?10:medium?12:15,color:"#ffffff",textAlign:{xs:"center",md:"justify"},mt:{xs:2,md:5},mx:{xs:3,md:0}}}>
                        We provide customized software and digital marketing solutions for
                        your business, starting from brainstorming and design to
                        implementation. Our services include designing e-commerce websites,
                        developing mobile applications, digital marketing strategies implementation and execution. We are a single destination to fulfill all your
                        digital marketing and IT requirements.We have a specialize Team in various
                        areas such as web designing, mobile application development, web
                        development, graphic designing, and content writing.
                    </Typography>
                </Box>
                <img src={Image} style={{display:small?"block":medium?"block":"none", width: medium?"60%":"80%" ,marginTop:10}} />
            </Container>
        </Box>
    );
}
