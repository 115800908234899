import React from 'react';
import { Grid, Typography, TextField, Button, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Paper from "@mui/material/Paper";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const useStyles = makeStyles({
    a: {
        color: "#bd147c",
        fontWeight: 700,
    },
    loginText: {
        letterSpacing: 1,
        wordSpacing: 2,
    },
    boxRow: {
        display: "flex",
        flexDirection: "row",
    },
});

export default function DeliverDetails() {
    const classes = useStyles();

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("md"));
    const matches1 = useMediaQuery(theme.breakpoints.down(480));

    const item = {
        id: "43287ghy32te7234",
        productname: "Silver Diamond Necklace",
        price: "84000",
        offerprice: "72000",
        picture: "diamondnecklace1.png",
        quantity: 1
    }

    const handleOrderSummary = () => {
        return (
            <Box style={{ marginLeft: 20 }}>
                <Paper
                    elevation={1}
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        flexWrap: "wrap",
                        width: matches?"80%":matches1 ? "100%" : "90%",
                        padding: matches1 ? 1 : 5,
                        borderRadius: matches1 ? 1 : 2,
                        backgroundColor: "#f2f2f2",
                    }}
                >
                    <Typography
                        style={{
                            color: "red",
                            fontSize: matches1 ? 16 : 24,
                        }}
                    >
                        ORDER SUMMARY
                    </Typography>
                    <Box
                        style={{
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                        className={classes.boxRow}
                    >
                        <Typography sx={{ fontSize: matches1 ? 10 : 20 }}>
                            Sub total
                        </Typography>
                        <Typography sx={{ fontSize: matches1 ? 10 : 20 }}>
                            {item.price}
                        </Typography>
                    </Box>
                    <Box
                        style={{
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                        className={classes.boxRow}
                    >
                        <Typography sx={{ fontSize: matches1 ? 10 : 20 }}>
                            Coupon Discount
                        </Typography>
                        <Typography sx={{ fontSize: matches1 ? 10 : 20 }}>
                            ₹&nbsp;3463
                        </Typography>
                    </Box>
                    <Box
                        style={{
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                        className={classes.boxRow}
                    >
                        <Typography sx={{ fontSize: matches1 ? 10 : 20 }}>
                            Delivery Charges
                        </Typography>
                        <Typography sx={{ fontSize: matches1 ? 10 : 20 }}>FREE</Typography>
                    </Box>
                    <Box
                        style={{
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginTop: 10,
                        }}
                        className={classes.boxRow}
                    >
                        <Typography
                            style={{
                                color: "red",
                                fontSize: matches1 ? 14 : 20,
                                fontWeight: 600,
                            }}
                        >
                            Total (incl of all taxes)
                        </Typography>
                        <Typography
                            style={{
                                color: "red",
                                fontSize: matches1 ? 14 : 20,
                                fontWeight: 600,
                            }}
                        >
                            {item.offerprice}{" "}
                        </Typography>
                    </Box>
                </Paper>
            </Box>
        );
    };

    return (
        <div style={{marginTop:15}}>
            <Typography sx={{
                color: "hotpink",
                fontSize: matches1 ? 26 : 30,
                textAlign: "center",
                marginBottom: matches ? 2 : matches1 ? 2 : 8,
                marginLeft: matches ? 0 : matches1 ? 1 : 0
            }}>
                Delivery Details
            </Typography>
            <Grid sx={{ my: matches1?1:5 }} container spacing={2}>
                <Grid item xs={11} md={11} lg={6}>
                    <Grid sx={{ marginLeft: matches1 ? 0 : 1 }} container spacing={2}>
                        <Grid item xs={12} lg={6}>
                            <TextField label={"Enter Name"} fullWidth />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <TextField label={"NickName"} fullWidth />
                        </Grid>
                        <Grid item xs={6} lg={4}>
                            <TextField label={"Pincode"} fullWidth />
                        </Grid>
                        <Grid item xs={6} lg={4}>
                            <TextField label={"City"} fullWidth />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <TextField label={"District"} fullWidth />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <TextField label={"State"} fullWidth />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <TextField label={"Address"} fullWidth />
                        </Grid>
                        <Grid item xs={3} lg={2}>
                            <TextField label={"Code"} fullWidth />
                        </Grid>
                        <Grid item xs={9} lg={4}>
                            <TextField label={"Recipient's Number"} fullWidth />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Button
                                fullWidth
                                style={{
                                    backgroundColor: "hotpink",
                                    color: "#ffffff",
                                    fontSize: matches1 ? 18 : 20,
                                    padding: "10px 0px"
                                }}
                            >
                                SUBMIT
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid sx={{ marginLeft: matches?0:matches1 ? -0.5 : 5 }} item xs={11} md={11} lg={5}>
                    {handleOrderSummary()}
                </Grid>
            </Grid>
        </div>
    )
}
