import {
  Box,
  Container,
  Grid,
  TextField,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
  Divider,
} from "@mui/material";
import React from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ServicesBar from "./ServicesBar";

// Laptop View Image
import bgImg from "../../Vault/WebDev_bg.jpg";

// Tablet View Image
import TabletBG from "../../Vault/WebDev_tablet_bg.jpg";

// MOBILE View Image
import MobileBG from "../../Vault/WebDev_mobile_bg.jpg";

import ExpertBrand from "../../Vault/Expert Branding Solutions.gif";
import BrandingService from "../../Vault/our_branding_service.gif";
import Expert from "../../Vault/Expert Heading.gif";
// import TopRank from "../../Vault/Top Rankings SEO.gif";

//  ACHIVE SECTION IMAGES
import Img1 from "../../Vault/Brand Identity.png";
import Img2 from "../../Vault/Brand strategy.png";
import Img3 from "../../Vault/Logo design.png";
import Img4 from "../../Vault/Product design.png";
import Img5 from "../../Vault/Brand stationary design.png";
import Img6 from "../../Vault/Business card design.gif";
import { postData } from "../../../ApiServices/ServerServices";
import Swal from "sweetalert2";
//  TOP RANKING SECTION IMAGES
import IMAGE6 from "../../Vault/Tick.png";
//socket
import { io } from 'socket.io-client'
export default function WebDev() {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const iPad = useMediaQuery(theme.breakpoints.down(1200));
  const [service, setService] = React.useState("");
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [number, setNumber] = React.useState("");
  const [companyName, setCompanyName] = React.useState("");
  const [socket,setSocket]=React.useState()

        React.useEffect(() => {
                const socket = io("https://api4.buzz2web.in/");
                setSocket(socket);
              
                socket.on("connect", () => {
                  console.log(socket.id);
                  socket.emit("backend",'admin');
                });
              
        
              }, []);
   
        const handleClick = async () => {
                var formData = new FormData()


                formData.append('service', service)
                formData.append('name', name)
                formData.append('email', email)
                formData.append('number', number)
                formData.append('companyName', companyName)




                var result = await postData('enquiries/addenquiry', formData, true)
                console.log(result)
                if (result.status) {
                        socket.emit("newEntry",{Enquiry:'New Enquiry',room:'admin'})
         
        Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Enquiry Has Been Send.We will contact You Soon',
        showConfirmButton: false,
        timer: 1500
      })}
    else
    {
        Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Fail to Send the Enquiry',
            showConfirmButton: false,
            timer: 1500
          })

    }



  }
  return (
    <div>
      <Box sx={{ overflowX: "hidden", background: "#F8F9FA" }}>
        <Navbar />
        <br />
        <br />
        <br />
        <br />
        <ServicesBar />
        {mobile ? (
          <></>
        ) : (
          <>
            <br />
            <br />
          </>
        )}
        <Container maxWidth="xl">
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Grid
                sx={{
                  width: mobile ? "109%" : "102%",
                  padding: mobile ? 0 : "50px 30px",
                  background: mobile
                    ? `url('${MobileBG}')`
                    : iPad
                    ? `url('${TabletBG}')`
                    : `url('${bgImg}')`,
                  backgroundSize: "100% 100%",
                  backgroundRepeat: "no-repeat",
                  height: mobile ? 700 : iPad ? 1000 : "auto",
                }}
                container
                spacing={2}
              >
                <Grid
                  sx={{
                    ml: mobile ? 0 : 4.8,
                    display: "flex",
                    flexDirection: "column",
                  }}
                  item
                  xs={12}
                  lg={6}
                >
                  {mobile ? (
                    <></>
                  ) : (
                    <>
                      <br />
                      <br />
                    </>
                  )}
                  <Typography
                    sx={{
                      fontSize: mobile ? "24px" : iPad ? "36px" : "40px",
                      fontWeight: 900,
                      fontFamily: "Montserrat",
                      color: "#053F76",
                    }}
                  >
                    Website Development and Design
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: mobile ? "12px" : iPad ? "16px" : "19px",
                      fontFamily: "Montserrat",
                      fontWeight: 500,
                      marginTop: "15px",
                      color: "#053F76",
                    }}
                  >
                    {mobile
                      ? "IBuzz tech solutions,we understand the importance of having a website that accurately represents your brand and provides a seamless user experience. "
                      : "IBuzz tech solutions,we understand the importance of having a website that accurately represents your brand and provides a seamless user experience. Our team of experts specializes in designing and developing both static and dynamic websites that are not only visually appealing but also easy to navigate. Our focus is to create interactive interfaces with minimal bugs to ensure a high conversion rate for your business."}
                  </Typography>
                  <Button
                    sx={{
                      mt: 3,
                      backgroundColor: "#053F76",
                      fontFamily: "Montserrat",
                      width: mobile ? "50%" : iPad ? "60%" : "40%",
                      padding: iPad ? "5px 5px" : "8px 10px",
                      fontSize: mobile ? 10 : iPad ? 16 : 18,
                      color: "#ffffff",
                      fontWeight: 600,
                      "&:hover": {
                        background: "#053F76",
                        color: "#9cbccb",
                      },
                    }}
                    href="https://wa.link/clvo86"
                    target="_blank"
                  >
                    GET CONSULTATION
                  </Button>
                </Grid>
                <Grid item xs={12} md={8} lg={4}>
                  <Grid
                    sx={{
                      width: mobile ? "96%" : iPad ? "80%" : "100%",
                      background: "#ffffff",
                      borderRadius: 4,
                      ml: mobile ? 0 : iPad ? "32px" : 10,
                      padding: "3px 10px 3px 7px",
                      paddingRight: 2,
                    }}
                    container
                    spacing={1}
                  >
                    <Grid
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      item
                      xs={12}
                    >
                      <Typography
                        sx={{
                          fontSize: iPad ? "20px" : "30px",
                          fontWeight: 600,
                          fontFamily: "Montserrat",
                          color: "#053F76",
                        }}
                      >
                        Enquiry Form
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField value={name} onChange={(e)=>setName(e.target.value)} fullWidth label="Enter Your Full Name" />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField fullWidth value={companyName} onChange={(e)=>setCompanyName(e.target.value)} label="Enter Your Company Name" />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField fullWidth value={email} onChange={(e)=>setEmail(e.target.value)}  label="Email" />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField fullWidth value={number} onChange={(e)=>setNumber(e.target.value)}  label="Phone Number" />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth sx={{ fontFamily: "Montserrat" }}>
                        <InputLabel id="demo-simple-select-label">
                          What Service do you want ?
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="What Service do you want ?"
                          value={service}
                          onChange={(e)=>setService(e.target.value)}
                        >
                          <MenuItem value={"MVP Development"}>
                            MVP Development
                          </MenuItem>
                          <MenuItem value={"Static Website Development"}>
                            Static Website Development
                          </MenuItem>
                          <MenuItem value={"Dynamic Website Development"}>
                            Dynamic Website Development
                          </MenuItem>
                          <MenuItem value={"Website Design"}>
                            Website Design
                          </MenuItem>
                          <MenuItem value={"Digital Marketing"}>
                            Digital Marketing
                          </MenuItem>
                          <MenuItem value={"App Development"}>
                            App Development
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      item
                      xs={12}
                    >
                      <Button
                        sx={{
                          marginTop: "16px",
                          marginBottom: "22px",
                          backgroundColor: "#9cbccb",
                          fontFamily: "Montserrat",
                          padding: mobile ? "8px 40px" : "8px 40px",
                          fontSize: mobile ? 14 : 18,
                          mr: 2,
                          color: "#053F76",
                          fontWeight: "bold",
                          "&:hover": {
                            background: "#053F76",
                            color: "#9cbccb",
                          },
                        }}
                        onClick={()=>handleClick()}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              sx={{
                display: "flex",
                flexDirection: iPad ? "column" : "row",
                margin: mobile ? "0px" : "15px 50px 5px",
                mt: mobile ? 0 : 8,
              }}
              item
              xs={12}
            >
              <img
                src={ExpertBrand}
                width={450}
                style={{
                  margin: "0px 10px",
                  display: mobile ? "none" : iPad ? "none" : "block",
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  mt: 10,
                  ml: iPad ? 0 : 5,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: 900,
                    fontSize: mobile ? 24 : iPad ? 36 : 40,
                    color: "#053F76",
                    mb: 1,
                  }}
                >
                  Expert web design and development services
                </Typography>
                <Divider
                  sx={{ width: "100%", backgroundColor: "#9cbccb", height: 2 }}
                />
                <br />
                <img
                  src={ExpertBrand}
                  width={mobile ? 250 : 450}
                  style={{
                    margin: "auto",
                    display: mobile ? "block" : iPad ? "block" : "none",
                  }}
                />
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: 500,
                    fontSize: mobile ? 12 : 17,
                    color: "grey",
                    textAlign: "justify",
                    mt: 2,
                  }}
                >
                  We offer a range of services that include professional web
                  design and development to take your business to new heights of
                  success. Our team of experts works hard to ensure that your
                  website is not only aesthetically pleasing but also functional
                  and easy to navigate. In addition to web design and
                  development, we can also help you with other elements of your
                  digital strategy such as creating engaging blog content,
                  exciting videos, quality articles, promoting your social media
                  platforms, and sending periodic emails to keep your customers
                  engaged. We are experienced in converting visitors into
                  revenue-generating leads by providing easily discoverable,
                  SEO-friendly content.
                </Typography>
              </Box>
            </Grid>
            <Grid
              sx={{
                display: "flex",
                flexDirection: iPad ? "column" : "row",
                margin: mobile ? "0px" : "15px 50px 10px",
              }}
              item
              xs={12}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  mt: 10,
                  ml: iPad ? 0 : 5,
                  width: iPad ? "100%" : "70%",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: 900,
                    fontSize: mobile ? 24 : iPad ? 36 : 40,
                    color: "#053F76",
                    mb: 1,
                  }}
                >
                  Achievable Web Design & Development
                </Typography>
                <Divider
                  sx={{ width: "100%", backgroundColor: "#9cbccb", height: 2 }}
                />
                <br />
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: 500,
                    fontSize: mobile ? 12 : 17,
                    color: "grey",
                    textAlign: "justify",
                    mt: 2,
                  }}
                >
                  IBuzz is a premier web development company specializing in
                  professional web design and development services. With a team
                  of industry experts and professionals, we cater to your needs
                  with our extensive capabilities.
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    mt: 4,
                    alignItems: "center",
                  }}
                >
                  <img src={Img1} width={mobile ? 25 : 45} />
                  <Typography
                    sx={{
                      fontFamily: "Montserrat",
                      fontWeight: 700,
                      ml: 2,
                      textAlign: "center",
                      fontSize: mobile ? 16 : 22,
                      color: "grey",
                      textAlign: "justify",
                      mt: 0,
                    }}
                  >
                    100% Responsive
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    mt: 2,
                    alignItems: "center",
                  }}
                >
                  <img src={Img2} width={mobile ? 25 : 45} />
                  <Typography
                    sx={{
                      fontFamily: "Montserrat",
                      fontWeight: 700,
                      ml: 2,
                      textAlign: "center",
                      fontSize: mobile ? 16 : 22,
                      color: "grey",
                      textAlign: "justify",
                      mt: 0,
                    }}
                  >
                    Impressive Web Design
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    mt: 2,
                    alignItems: "center",
                  }}
                >
                  <img src={Img3} width={mobile ? 25 : 45} />
                  <Typography
                    sx={{
                      fontFamily: "Montserrat",
                      fontWeight: 700,
                      ml: 2,
                      textAlign: "center",
                      fontSize: mobile ? 16 : 22,
                      color: "grey",
                      textAlign: "justify",
                      mt: 0,
                    }}
                  >
                    SEO optimised
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    mt: 2,
                    alignItems: "center",
                  }}
                >
                  <img src={Img4} width={mobile ? 25 : 45} />
                  <Typography
                    sx={{
                      fontFamily: "Montserrat",
                      fontWeight: 700,
                      ml: 2,
                      textAlign: "center",
                      fontSize: mobile ? 16 : 22,
                      color: "grey",
                      textAlign: "justify",
                      mt: 0,
                    }}
                  >
                    Substantial Solutions
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    mt: 2,
                    alignItems: "center",
                  }}
                >
                  <img src={Img5} width={mobile ? 25 : 45} />
                  <Typography
                    sx={{
                      fontFamily: "Montserrat",
                      fontWeight: 700,
                      ml: 2,
                      textAlign: "center",
                      fontSize: mobile ? 16 : 22,
                      color: "grey",
                      textAlign: "justify",
                      mt: 0,
                    }}
                  >
                    E-commerce Portal
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    mt: 2,
                    alignItems: "center",
                  }}
                >
                  <img src={Img6} width={mobile ? 25 : 45} />
                  <Typography
                    sx={{
                      fontFamily: "Montserrat",
                      fontWeight: 700,
                      ml: 2,
                      textAlign: "center",
                      fontSize: mobile ? 16 : 22,
                      color: "grey",
                      textAlign: "justify",
                      mt: 0,
                    }}
                  >
                    Development Per Requirement
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    mt: 2,
                    alignItems: "center",
                  }}
                >
                  <img src={Img6} width={mobile ? 25 : 45} />
                  <Typography
                    sx={{
                      fontFamily: "Montserrat",
                      fontWeight: 700,
                      ml: 2,
                      textAlign: "center",
                      fontSize: mobile ? 16 : 22,
                      color: "grey",
                      textAlign: "justify",
                      mt: 0,
                    }}
                  >
                    Mobile-friendly interface
                  </Typography>
                </Box>
              </Box>
              <img
                src={BrandingService}
                width={mobile ? 300 : 550}
                height={mobile ? 300 : 550}
                style={{
                  margin: iPad ? "auto" : "auto",
                  marginTop: iPad ? 50 : "10%",
                  display: "block",
                }}
              />
            </Grid>
            <Grid
              sx={{
                display: "flex",
                flexDirection: iPad ? "column" : "row",
                margin: mobile ? "10p" : "15px 50px 10px",
              }}
              item
              xs={12}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  mt: 10,
                  ml: iPad ? 0 : 5,
                  width: "100%",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: 900,
                    fontSize: mobile ? 24 : iPad ? 36 : 40,
                    color: "#053F76",
                    mb: 1,
                  }}
                >
                  Comprehensive Web Development Services
                </Typography>
                <Divider
                  sx={{ width: "100%", backgroundColor: "#9cbccb", height: 2 }}
                />
                <br />
                <Grid
                  container
                  spacing={2}
                  sx={{ display: "flex", flexDirection: "row", mt: 2 }}
                >
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      mt: 2,
                      alignItems: "center",
                    }}
                    item
                    xs={6}
                  >
                    <img
                      src={IMAGE6}
                      width={mobile ? 25 : 35}
                      height={mobile ? 25 : 35}
                    />
                    <Typography
                      sx={{
                        fontFamily: "Montserrat",
                        fontWeight: 700,
                        fontSize: mobile ? 16 : 22,
                        color: "grey",
                        textAlign: "left",
                        ml: 2,
                      }}
                    >
                      Custom Web Development
                    </Typography>
                  </Grid>
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      mt: 2,
                      alignItems: "center",
                    }}
                    item
                    xs={6}
                  >
                    <img
                      src={IMAGE6}
                      width={mobile ? 25 : 35}
                      height={mobile ? 25 : 35}
                    />
                    <Typography
                      sx={{
                        fontFamily: "Montserrat",
                        fontWeight: 700,
                        fontSize: mobile ? 16 : 22,
                        color: "grey",
                        textAlign: "left",
                        ml: 2,
                      }}
                    >
                      Application Integration Service
                    </Typography>
                  </Grid>
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      mt: 2,
                      alignItems: "center",
                    }}
                    item
                    xs={6}
                  >
                    <img
                      src={IMAGE6}
                      width={mobile ? 25 : 35}
                      height={mobile ? 25 : 35}
                    />
                    <Typography
                      sx={{
                        fontFamily: "Montserrat",
                        fontWeight: 700,
                        fontSize: mobile ? 16 : 22,
                        color: "grey",
                        textAlign: "left",
                        ml: 2,
                      }}
                    >
                      Custom Business Application
                    </Typography>
                  </Grid>
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      mt: 2,
                      alignItems: "center",
                    }}
                    item
                    xs={6}
                  >
                    <img
                      src={IMAGE6}
                      width={mobile ? 25 : 35}
                      height={mobile ? 25 : 35}
                    />
                    <Typography
                      sx={{
                        fontFamily: "Montserrat",
                        fontWeight: 700,
                        fontSize: mobile ? 16 : 22,
                        color: "grey",
                        textAlign: "left",
                        ml: 2,
                      }}
                    >
                      Work-Flow & Project Management
                    </Typography>
                  </Grid>
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      mt: 2,
                      alignItems: "center",
                    }}
                    item
                    xs={6}
                  >
                    <img
                      src={IMAGE6}
                      width={mobile ? 25 : 35}
                      height={mobile ? 25 : 35}
                    />
                    <Typography
                      sx={{
                        fontFamily: "Montserrat",
                        fontWeight: 700,
                        fontSize: mobile ? 16 : 22,
                        color: "grey",
                        textAlign: "left",
                        ml: 2,
                      }}
                    >
                      Real Estate Portal Development
                    </Typography>
                  </Grid>
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      mt: 2,
                      alignItems: "center",
                    }}
                    item
                    xs={6}
                  >
                    <img
                      src={IMAGE6}
                      width={mobile ? 25 : 35}
                      height={mobile ? 25 : 35}
                    />
                    <Typography
                      sx={{
                        fontFamily: "Montserrat",
                        fontWeight: 700,
                        fontSize: mobile ? 16 : 22,
                        color: "grey",
                        textAlign: "left",
                        ml: 2,
                      }}
                    >
                      Job Portal Development
                    </Typography>
                  </Grid>
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      mt: 2,
                      alignItems: "center",
                    }}
                    item
                    xs={6}
                  >
                    <img
                      src={IMAGE6}
                      width={mobile ? 25 : 35}
                      height={mobile ? 25 : 35}
                    />
                    <Typography
                      sx={{
                        fontFamily: "Montserrat",
                        fontWeight: 700,
                        fontSize: mobile ? 16 : 22,
                        color: "grey",
                        textAlign: "left",
                        ml: 2,
                      }}
                    >
                      Online Travel Portal Development
                    </Typography>
                  </Grid>
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      mt: 2,
                      alignItems: "center",
                    }}
                    item
                    xs={6}
                  >
                    <img
                      src={IMAGE6}
                      width={mobile ? 25 : 35}
                      height={mobile ? 25 : 35}
                    />
                    <Typography
                      sx={{
                        fontFamily: "Montserrat",
                        fontWeight: 700,
                        fontSize: mobile ? 16 : 22,
                        color: "grey",
                        textAlign: "left",
                        ml: 2,
                      }}
                    >
                      Enterprise Portal Development
                    </Typography>
                  </Grid>
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      mt: 2,
                      alignItems: "center",
                    }}
                    item
                    xs={6}
                  >
                    <img
                      src={IMAGE6}
                      width={mobile ? 25 : 35}
                      height={mobile ? 25 : 35}
                    />
                    <Typography
                      sx={{
                        fontFamily: "Montserrat",
                        fontWeight: 700,
                        fontSize: mobile ? 16 : 22,
                        color: "grey",
                        textAlign: "left",
                        ml: 2,
                      }}
                    >
                      Ecommerce Platform Enhancements.
                    </Typography>
                  </Grid>
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      mt: 2,
                      alignItems: "center",
                    }}
                    item
                    xs={6}
                  >
                    <img
                      src={IMAGE6}
                      width={mobile ? 25 : 35}
                      height={mobile ? 25 : 35}
                    />
                    <Typography
                      sx={{
                        fontFamily: "Montserrat",
                        fontWeight: 700,
                        fontSize: mobile ? 16 : 22,
                        color: "grey",
                        textAlign: "left",
                        ml: 2,
                      }}
                    >
                      Popular Ecommerce Solutions.
                    </Typography>
                  </Grid>
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      mt: 2,
                      alignItems: "center",
                    }}
                    item
                    xs={6}
                  >
                    <img
                      src={IMAGE6}
                      width={mobile ? 25 : 35}
                      height={mobile ? 25 : 35}
                    />
                    <Typography
                      sx={{
                        fontFamily: "Montserrat",
                        fontWeight: 700,
                        fontSize: mobile ? 16 : 22,
                        color: "grey",
                        textAlign: "left",
                        ml: 2,
                      }}
                    >
                      Custom Modules & Plugins Development
                    </Typography>
                  </Grid>
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      mt: 2,
                      alignItems: "center",
                    }}
                    item
                    xs={6}
                  >
                    <img
                      src={IMAGE6}
                      width={mobile ? 25 : 35}
                      height={mobile ? 25 : 35}
                    />
                    <Typography
                      sx={{
                        fontFamily: "Montserrat",
                        fontWeight: 700,
                        fontSize: mobile ? 16 : 22,
                        color: "grey",
                        textAlign: "left",
                        ml: 2,
                      }}
                    >
                      Joomla, Drupal, WordPress CMS Development
                    </Typography>
                  </Grid>
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      mt: 2,
                      alignItems: "center",
                    }}
                    item
                    xs={6}
                  >
                    <img
                      src={IMAGE6}
                      width={mobile ? 25 : 35}
                      height={mobile ? 25 : 35}
                    />
                    <Typography
                      sx={{
                        fontFamily: "Montserrat",
                        fontWeight: 700,
                        fontSize: mobile ? 16 : 22,
                        color: "grey",
                        textAlign: "left",
                        ml: 2,
                      }}
                    >
                      E-Commerce Platform Development
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <img
                src={Expert}
                width={mobile ? 300 : 550}
                height={mobile ? 300 : 550}
                style={{
                  margin: mobile ? "40px 15px" : iPad ? "auto" : "80px 10px",
                  marginTop: iPad ? 50 : 0,
                  display: iPad ? "block" : "none",
                }}
              />
            </Grid>
          </Grid>
        </Container>
        <br />
        <br />
        <br />
        <Footer />
      </Box>
    </div>
  );
}
